import { useLazyQuery, useMutation, useQuery } from 'react-apollo';
import { MUTATE_USER_BY_TOKEN, MUTATE_USER_PROFILE_HOME, MUTATE_USER_PROFILE_NAVBAR, mutateUserProfile } from 'queries/profile';
import { newClient } from 'helpers';
import React, { useEffect, useState } from 'react';
import { contentfulClient, country } from 'App';
import { countries, countryMap } from 'helpers/countryMapping';
import { MUTATE_SEARCH_TUTORS, QUERY_LIFELONG_CATEGORIES } from 'queries/user';
import { QUERY_PROGRAMME_COACHES, QUERY_SEARCH_COACHES } from 'queries/coaches';
import { queryFetchCities, queryFetchLevels, queryFetchSubjects } from 'queries/data';
import { Trans } from '@lingui/macro';
import { useLocation } from 'react-router';
import { TOTAL_USER_SHOW } from '../constants';
import { onError } from 'apollo-link-error';

const coachesClient = newClient('coaches');
const programmeClient = newClient('programme');

/**
 * 🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️ **useProgrammeCoaches**
 * @description Hooks for getting the list of tutors in a specific country
 * @property {options: omitNoAvatar, sortByCompletion}
 * @pages {Home, Marketplace}
 */

export function useProgrammeCoaches() {
	const { data: { getProgrammeSubjectCounselling: data = [] } = {}, loading } = useQuery(QUERY_PROGRAMME_COACHES, {
		client: coachesClient,
		variables: { country }
	});
	return { data, loading };
}

export function useProgrammeCoachesCities() {
	const { data: { getProgrammeCityList: data = [] } = {}, loading } = useQuery(queryFetchCities, {
		client: programmeClient,
		variables: { country }
	});
	return { data, loading };
}

/**
 * 🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️ **useCoaches**
 * @description Hooks for getting the list of tutors in a specific country
 * @property {options: omitNoAvatar, sortByCompletion}
 * @pages {Home, Marketplace}
 */

export function useCoaches({ omitNoAvatar = true, sortByCompletion = true, limit = false } = {}) {
	const [coaches, setCoaches] = useState({ coaches: [] });

	const currentCountry = countryMap[country];
	const priceRangeByCountry = currentCountry && currentCountry.pricePerHourRange.range;

	const optionNoLimit = {
		keywords: '',
		coachesSubjectPvid: 0,
		recently: 0,
		alphabet: 0,
		location: '',
		page: 1,
		cities: 'All',
		limit: TOTAL_USER_SHOW,
		price_range: {
			startPrice: priceRangeByCountry[0],
			endPrice: priceRangeByCountry[1]
		}
	};

	const options = limit ? { ...optionNoLimit, limit } : optionNoLimit;
	const [hasMore, sethasMore] = useState(false)
	const [searchCoaches, { loading, refetch }] = useLazyQuery(QUERY_SEARCH_COACHES, {
		client: coachesClient,
		variables: { country, ...options },
		onCompleted: data => {
			data = data.findCoaches || { coaches: [] };
			if (omitNoAvatar) {
				data.coaches = data.coaches.filter(person => Boolean(person.resource.path));
			}

			if (sortByCompletion) {
				data.coaches = data.coaches.sort((a, b) => {
					const aPercent = a.completionUser.completionPercent || 0;
					const bPercent = b.completionUser.completionPercent || 0;
					return bPercent - aPercent;
				});
			}
			setCoaches(data);
			if(data.coaches.length != 0){
				sethasMore(true)
			}else{
				sethasMore(false)
			}
		}
	});
	// searchCoaches()
	return [searchCoaches, { data: coaches, loading , hasMore , sethasMore  }];
}

/**
 * 🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️ **useTutors**
 * @description Hooks for getting the list of tutors in a specific country
 * @property {options: omitNoAvatar, sortByCompletion}
 * @pages {Home, Marketplace}
 */

const profileClient = newClient();

export function useTutors({ omitNoAvatar = true, sortByCompletion = true, limit = false } = {}) {
	const [tutors, setTutors] = useState({ tutors: [] });
	const [hasMore, sethasMore] = useState(false);
	const currentCountry = countryMap[country];
	const priceRangeByCountry = currentCountry && currentCountry.pricePerHourRange.range;

	const optionNoLimit = {
		keywords: '',
		subjects: 0,
		curriculums: 0,
		recently: 0,
		alphabet: 0,
		location: '',
		nonAcademicPvid: '0',
		page: 1,
		limit: TOTAL_USER_SHOW,
		price_range: {
			startPrice: priceRangeByCountry[0],
			endPrice: priceRangeByCountry[1]
		},
		fromWhere: 'HomePage'
	};

	const options = limit ? { ...optionNoLimit, limit } : optionNoLimit;

	const [searchTutors, { loading, error }] = useMutation(MUTATE_SEARCH_TUTORS, {
		client: newClient(),
		variables: { country, ...options },
		onCompleted: data => {
			data = data.findTutors || { tutors: [] };
			if (omitNoAvatar) {
				data.tutors = data.tutors.filter(person => Boolean(person.resource.path));
			}

			if (sortByCompletion) {
				data.tutors = data.tutors.sort((a, b) => {
					const aPercent = a.completionUser.completionPercent || 0;
					const bPercent = b.completionUser.completionPercent || 0;
					return bPercent - aPercent;
				});
			}
			if(data.tutors.length != 0){
				sethasMore(true)
			}else{
				sethasMore(false)
			}
			setTutors(data);
		},
		onError: er => {
			console.log(er);
		}
	});

	return [searchTutors, { data: tutors, loading, error , hasMore}];
}

/**
 * 🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️ **useProgrammeList**
 * @description Hooks for getting the list of programme in i3 (subjects, levels, etc)
 * @property {subjects: boolean, levels: true, isNonAcademic: true}
 * @pages {Tutor Profile, Onboarding tutor}
 */

const programme = newClient('programme');

export function useProgrammeList({ subjects = true, levels = true, type = 'academic', useLabelForValue = false } = {}) {
	const [fetchSubjects, { data: subjectsData = {} }] = useLazyQuery(queryFetchSubjects, { client: programme });
	const [fetchLevels, { data: levelsData = {} }] = useLazyQuery(queryFetchLevels, { client: programme });
	const [fetchNonAcademicSubjects, { data: nonAcademicData = {} }] = useLazyQuery(QUERY_LIFELONG_CATEGORIES, { client: profileClient });
	const [
		fetchCounsellingSubjects,
		{ data: { getProgrammeSubjectCounselling: counsellingData = [] } = {} }
	] = useLazyQuery(QUERY_PROGRAMME_COACHES, { client: coachesClient });
	useEffect(() => {
		if (subjects) fetchNonAcademicSubjects({ variables: { country, parentCategory: 0 } });
		if (subjects) fetchCounsellingSubjects({ variables: { country } });
		if (subjects) fetchSubjects({ variables: { country } });
		if (levels) fetchLevels({ variables: { country } });
	}, [fetchLevels, fetchNonAcademicSubjects, fetchSubjects, fetchCounsellingSubjects, type, levels, subjects]);
	const data = {
		subjects: subjectsData.getProgrammeSubjectList || [],
		nonAcademic: nonAcademicData.listProfileNonAcademic || [],
		counselling: counsellingData,
		levels: levelsData.getProgrammeLevelList || []
	};

	const options = {
		counselling: data.counselling.map(item => ({
			value: useLabelForValue ? item.name : item.pvid,
			label: item.name
		})),
		nonAcademic: data.nonAcademic.map(item => {
			return {
				value: useLabelForValue ? item.name : item.pvid,
				label: item.name,
				children: item.subNonAcademic?.map(sub => ({
					value: useLabelForValue ? sub.name : sub.pvid,
					label: sub.name
				}))
			};
		}),
		subjects: data.subjects.map(item => {
			return { value: useLabelForValue ? item.name : item.pvid, label: item.name };
		}),
		levels: levelsData.getProgrammeLevelList?.map(item => ({
			value: useLabelForValue ? item.name : item.pvid,
			label: item.name
		}))
	};

	return { data, options };
}

/**
 * 🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️ **useUserDetails**
 * @description Hooks for getting the details of currently logged in user
 * @pages almost everywhere
 * @property {type: 'home' | 'navbar'}
 * @queries
 * - MUTATE_USER_PROFILE_HOME ==> 'home'
 * - MUTATE_USER_PROFILE_NAVBAR ==> 'navbar'
 * - mutateUserProfile ==> default
 */

export function useUserDetails(type = '', config = {}) {
	const [userDetails, setUserDetails] = useState([]);
	const { keywords, code, subjects, curriculums } = config;

	const query = (type === 'home' && MUTATE_USER_PROFILE_HOME) || (type === 'navbar' && MUTATE_USER_PROFILE_NAVBAR) || mutateUserProfile;

	const [fetchUser, { loading }] = useMutation(query, {
		client: newClient(),
		onCompleted: data => {
			data = data.getProfile || {};
			setUserDetails(data);
		}
	});

	const [fetchUserByToken, { loading: loadingByToken }] = useMutation(MUTATE_USER_BY_TOKEN, {
		client: newClient(),
		variables: { keywords, code, subjects, curriculums },
		onCompleted: data => {
			data = data.getProfile || {};
			setUserDetails(data);
		}
	});

	useEffect(() => {
		if (!localStorage.getItem('token') && code) fetchUserByToken();
		else fetchUser();
	}, [code, fetchUser, fetchUserByToken]);

	return { data: userDetails, loading: loading || loadingByToken };
}

/**
 * 🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️
 * Hooks for getting consultants data based on selected Country
 * Pages: Home
 */

export function useConsultants() {
	const [consultants, setConsultants] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			let { items } = await contentfulClient.getEntries({ content_type: 'consultants' });
			setConsultants(items.map(item => item.fields));
		};

		fetchData();
	}, []);

	const consultantsByCountry = (consultants || [])
		.filter(item => {
			const theCountry = countries.find(item => item.key === country) || {};
			return theCountry.name === item.country;
		})
		.map(item => ({ ...item, whatsapp: item.whatsapp.startsWith('+') ? item.whatsapp.slice(1) : item.whatsapp }));

	return consultantsByCountry;
}

/**
 * 🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️
 * Hooks for getting products data from Contentful
 * @pages Home
 * @returns number[]
 */

export function useEcommerceProducts() {
	const [products, setProducts] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			let { items } = await contentfulClient.getEntries({ content_type: 'product' });
			setProducts(items.map(item => item.fields));
		};

		fetchData();
	}, []);

	const returnedProducts = products.map(item => {
		const { sys, fields } = item.siteListings;
		const images = item.images.map(image => image.fields && image.fields.file.url);
		return { ...item, siteListings: { ...fields, id: sys.id }, images };
	});

	return returnedProducts;
}

/**
 * 🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️
 * @description Hooks for getting Cudy Lifelong categories
 * @pages Lifelong
 * @returns {object}
 */

export function useLifelongCategories(parentCategory = 0, useLabelForValue = false) {
	const [categories, setCategories] = useState([]);
	const [catOptions, setCatOptions] = useState([]);

	const { loading } = useQuery(QUERY_LIFELONG_CATEGORIES, {
		client: profileClient,
		variables: { country, parentCategory },
		onCompleted: data => {
			data = data.listProfileNonAcademic || [];
			const options = data.map(item => ({
				value: useLabelForValue ? item.name : item.pvid,
				label: item.name,
				children: (item.subNonAcademic || []).map(sub => ({
					value: useLabelForValue ? sub.name : sub.pvid,
					label: sub.name
				}))
			}));
			setCategories(data);
			setCatOptions(options);
		}
	});

	return { data: categories, options: catOptions, loading };
}

/**
 * 🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️🧜🏼‍♀️
 * @description Hooks for getting bottom bar links found at Navbar
 * @pages Navbar
 * @returns {Object}
 */

export function useBottomBarLinks() {
	const { pathname } = useLocation();
	const isPageLifelong = pathname.includes('lifelong');
	const isCounselling = pathname.includes('counselling');

	let bottomBarLinks = [
		{
			key: 'browse-tutors',
			label: isPageLifelong ? <Trans>Browse coaches</Trans> : <Trans>Browse tutors</Trans>,
			pathname: isPageLifelong ? '/lifelong/browse' : '/browse/tutors',
			icon: 'icon-male-orange',
			hideInLifelong: false
		},
		{
			key: 'public-assignments',
			label: <Trans>Assignments</Trans>,
			pathname: `/assignments/public`,
			icon: 'icon-icon-test14',
			hideInLifelong: true
		},
		{
			key: 'feeds',
			label: <Trans>Feeds</Trans>,
			pathname: `/feeds`,
			icon: 'icon-icon-test18',
			hideInLifelong: false
		},
		{
			key: 'study-places',
			label: <Trans>Study places</Trans>,
			pathname: `/study_places`,
			icon: 'icon-icon-test12',
			hideInLifelong: false
		},
		{
			key: 'study-center',
			label: <Trans>Study center</Trans>,
			pathname: `/study_places/tuition`,
			icon: 'icon-icon-test17',
			hideInLifelong: true
		},
		{
			key: 'study-shop',
			label: <Trans>Study shop</Trans>,
			pathname: `/shop`,
			icon: 'icon-icon-test72',
			hideInLifelong: false
		},
		{
			key: 'cudy-transcribe',
			label: <Trans>Cudy Scribe</Trans>,
			pathname: `/cudytranscribe`,
			icon: 'icon-transcribe',
			hideInLifelong: false
		},
		//
		// 	key: "browse-listings",
		// 	label: <Trans>Browse Listing</Trans>,
		// 	pathname: `/browse-listing`,
		// 	icon: "icon-Shopmanagement_Home",
		// 	hideInLifelong: false
		// },
		{
			key: 'lesson',
			label: 'Study Lessons',
			pathname: `/lesson`,
			icon: 'icon-icon-test8',
			hideInLifelong: true
		},
		{
			key: 'reads',
			label: 'Cudy Reads',
			pathname: `/reads/public`,
			icon: 'icon-icon-test3',
			hideInLifelong: true
		},
		{
			key: 'cudy-room',
			label: 'Cudy Room',
			pathname: `/cudy-rooms`,
			icon: 'icon-icon-test68',
			hideInLifelong: true
		},
		{
			key: 'cudy-content',
			label: 'Cudy Content',
			pathname: `/content`,
			icon: 'icon-CONTENTMARKETING',
			hideInLifelong: true
		}

		//Cudy Shop - icon-test72
		// Study Places - icon-test12
		// Study Centre - icon-test17
		// Cudy Assignments - icon-test14
		// Cudy Lessons - icon-test8
		// Cudy Read - icon-test3
		// Cudy Feed - icon-test18
		// Cudy Lifelong - icon-test20
		// Cudy Counselling - icon-test19
		// Upcoming Services
		// Cudy Content - icon-test5
		// Cudy Travel - icon-test69
		// Cudy Transcribe - icon-test38
		// Cudy Wish - icon-test27
		// Cudy Jobs - icon-test46
		// Cudy Portfolio - icon-test23
		// Cudy Slides - icon-test31
		// Cudy Books - icon-test55
		// Cudy College - icon-test53
		// Cudy Tale - icon-test65
		//	Cudy Sponsorship - icon-test57
		// Cudy Coordinators - icon-test24
		// Cudy Software - icon-test15
		// Cudy Nutrition - icon-test59
		// Cudy Ride - icon-test61
		// Study Planner - icon-test62
		//	Cudy Room - icon-test68
	];

	const linksAtLifelong = bottomBarLinks.filter(({ hideInLifelong }) => hideInLifelong === false);

	bottomBarLinks = isPageLifelong ? linksAtLifelong : bottomBarLinks;

	return { bottomBarLinks, isPageLifelong, isCounselling };
}
