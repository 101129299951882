import React from 'react';
import { Tag } from 'antd';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import Button from '../../../Button';
import { LimitDescription } from '../functions/resultsFunctions';
import { ResultsContainer, Heading } from '../components/resultsComponents';

const Contents = ({ data }) => {
	const {
		id,
		FirstName,
		LastName,
		Title,
		Subject,
		Description,
		Price,
		createdAt,
		Tags,
		NumberOfViews,
		NumberOfSales,
		Currency,
		Rating,
		NumberOfReviews,
		avatarData,
		RolePvid
	} = data;

	const newTitle = _.upperFirst(Title);
	const des = LimitDescription(Description);

	let history = useHistory();

	const VisitContent = () => {
		const country = localStorage.getItem('country') || 'sg';
		const genPath = `/${country}/content/${id}--${Title}`;
		const newPath = encodeURI(genPath);
		history.push(newPath);
	};

	return (
		<ResultsContainer>
			<TitleContainer>
				<Trans>{newTitle}</Trans>
			</TitleContainer>

			<DescriptionContainer>
				<Heading>
					<Trans>Description</Trans>
				</Heading>
				<Trans>{des}</Trans>
			</DescriptionContainer>

			<FlexContainer>
				<CreatedByContainer>
					<Heading>
						<Trans>Created By</Trans>
					</Heading>
					{FirstName} {LastName}
				</CreatedByContainer>

				<CreatedAtContainer>
					<Heading>
						<Trans>Created At</Trans>
					</Heading>
					{moment(createdAt).format('dddd, MMMM Do YYYY, h:mm:ss a')}
				</CreatedAtContainer>
			</FlexContainer>

			<SubjectContainer>
				<Heading>
					<Trans>Subject</Trans>
				</Heading>
				<Trans>{Subject}</Trans>
			</SubjectContainer>

			<PriceContainer>
				<Heading>
					<Trans>Price</Trans>
				</Heading>
				{Currency} {Price}
			</PriceContainer>

			<ViewsContainer>
				<Heading>
					<Trans>Views</Trans>
				</Heading>
				{NumberOfViews}
			</ViewsContainer>

			<SalesContainer>
				<Heading>
					<Trans>Sales</Trans>
				</Heading>
				{NumberOfSales}
			</SalesContainer>

			<RatingContainer>
				<Heading>
					<Trans>Rating</Trans>
				</Heading>
				{Rating}
			</RatingContainer>

			<ReviewsContainer>
				<Heading>
					<Trans>Reviews</Trans>
				</Heading>
				{NumberOfReviews}
			</ReviewsContainer>

			<TagsContainer>
				<Heading>
					<Trans>Tags</Trans>
				</Heading>
				<Tag color="gold" key={Tags} style={{ marginBottom: '1rem' }}>
					<Trans>{Tags}</Trans>
				</Tag>
			</TagsContainer>

			<VisitContentsContainer>
				<Button type="primary" size="medium" onClick={VisitContent} style={{ position: 'absolute', bottom: '1rem', width: '15%' }}>
					<Trans>
						<p style={{ fontSize: '0.9rem' }}>View Content</p>
					</Trans>
				</Button>
			</VisitContentsContainer>
		</ResultsContainer>
	);
};

export default Contents;

// ███████ ████████ ██    ██ ██      ███████ ███████
// ██         ██     ██  ██  ██      ██      ██
// ███████    ██      ████   ██      █████   ███████
//      ██    ██       ██    ██      ██           ██
// ███████    ██       ██    ███████ ███████ ███████

const TitleContainer = styled.div`
	font-size: 1.1rem;
	font-weight: 500;
	padding-top: 1rem;
	display: flex;
	justify-content: center;
`;

const DescriptionContainer = styled.div`
	padding: 1rem 0rem;
`;

const FlexContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-start;
	gap: 2rem;
`;

const CreatedByContainer = styled.div`
	padding-bottom: 0.5rem;
`;

const SubjectContainer = styled.div`
	padding-bottom: 0.5rem;
`;

const PriceContainer = styled.div`
	padding-bottom: 0.5rem;
`;

const CreatedAtContainer = styled.div`
	padding-bottom: 0.5rem;
`;

const ViewsContainer = styled.div`
	padding-bottom: 0.5rem;
`;

const SalesContainer = styled.div`
	padding-bottom: 0.5rem;
`;

const RatingContainer = styled.div`
	padding-bottom: 0.5rem;
`;

const ReviewsContainer = styled.div`
	padding-bottom: 0.5rem;
`;

const TagsContainer = styled.div`
	padding-bottom: 0.5rem;
`;

const VisitContentsContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;
