import React from "react"
import { ClockCircleOutlined } from '@ant-design/icons';
import { Timeline as Time, Typography } from "antd";
import styled from "styled-components"

const Text = styled(Typography.Text)`
    && {
        &.ant-typography-secondary {
            color: inherit;
        }
    }
`

const StyledTime = styled(Time)`
    && {
        .ant-timeline-item-content {
            margin-left: 28px;
        }
    }
`

function Timeline(props) {
    const { data = [] } = props

    return (
        <StyledTime {...props}>
            {data &&
                data.map(item => (
                    <Time.Item color={item.color} key={item.date}>
                        <Typography>
                            <Typography.Text strong>{item.title}</Typography.Text>
                            <Typography.Paragraph>{item.description}</Typography.Paragraph>
                            <Text type="secondary">
                                <ClockCircleOutlined /> &nbsp; {item.date}
                            </Text>
                        </Typography>
                    </Time.Item>
                ))}
        </StyledTime>
    );
}

export default Timeline
