import React from 'react';
import Tutors from './Results/tutors';
import Contents from './Results/contents';
import { ResultsContainer } from './components/resultsComponents';

const MoreInfo = ({ data }) => {
	const { fromCollection } = data;

	if (fromCollection === 'tutors') {
		return <Tutors data={data} />;
	} else if (fromCollection === 'contents') {
		return <Contents data={data} />;
	} else {
		return <ResultsContainer />;
	}
};

export default MoreInfo;
