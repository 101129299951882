import gql from "graphql-tag"

export const queryFetchCurriculums = gql`
	query queryFetchCurriculums {
		getProgrammeCurriculumList {
			pvid
			name
		}
	}
`

export const queryFetchLevels = gql`
	query queryFetchLevels($country: String!) {
		getProgrammeLevelList(country: $country) {
			pvid
			name
		}
	}
`

export const queryFetchSubjects = gql`
	query queryFetchSubjects($country: String!) {
		getProgrammeSubjectList(country: $country) {
			pvid
			name
		}
	}
`
export const queryFetchCities = gql`
	query queryFetchCities($country: String!) {
		getProgrammeCityList(country: $country) {
			pvid
			name
		}
	}
`
export const queryOtherPlatforms = gql`
	query queryOtherPlatforms {
		getOtherPlatformMasterList {
			pvid
			name
			iconLink
		}
	}
`

export const QUERY_LIST_PROVINCE = gql`
	query QUERY_LIST_PROVINCE($countryInitial: String!) {
		listProvinces(countryInitial: $countryInitial) {
			pvid
			countryPvid
			name
		}
	}
`
