const src_slides = 'https://slides.cudy.co/iframe.html';

const src_planner = process.env.REACT_APP_MODE === "development" ? "http://localhost:3001" : "https://planner.cudy.co";

export const loggedIn = (token, formQuery, user, iframe) => {
	if (formQuery) {
		return new Promise((resolve, reject) => {
			try {
				console.log("here")
				
				resolve();
			} catch (error) {
				resolve();
			}
		});
	}
};

export const loggedOut = () => {
	return new Promise((resolve, reject) => {
		try {
			let iframe = document.createElement('iframe');
			iframe.style.display = 'none';
			document.body.appendChild(iframe);
			iframe.onload = () => {
				iframe.src = src_slides;
				iframe.contentWindow.postMessage('', src_slides);

				iframe.src = src_planner + '/iframe.html';
				iframe.contentWindow.postMessage('', src_planner + '/iframe.html');
				resolve();
			};
		} catch (error) {
			resolve();
		}
	});
};
