import React from "react"
import styled from "styled-components"
import { CloseOutlined, PlayCircleFilled } from '@ant-design/icons';
import { Popconfirm } from "antd";
// import Heading from "./Heading"
import { baseStyles, boxShadow } from "styles/base"
import moment from "moment"
import { Trans } from "@lingui/macro"
import { media } from "helpers"
import ReactPlayer from "react-player/file"
import { SERVER_BASE_URL } from "helpers/constants"

const StyledCard = styled.div`
	position: relative;
	height: 200px;
	width: 100%;
	margin-bottom: 1em;
	padding: 0;
	border-radius: 8px;
	background: ${({ src }) => `url(${src}) center no-repeat`};
	background-size: cover;
	background-color: rgba(0, 0, 0, 0.4);
	background-blend-mode: multiply;
	box-shadow: ${boxShadow[1]};
	cursor: pointer;
	transition: all 0.15s ease;
	&:hover {
		/* transform: translateY(-3px); */
		background-color: rgba(0, 0, 0, 0.2);
		.delete {
			display: flex;
			visibility: visible;
		}
		.play {
			color: #ff9d00;
		}
	}
	.play {
		margin-bottom: 1em;
		color: #eee;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		.anticon {
			font-size: 2em;
		}
	}
	.ant-typography {
		color: #eee;
		h4 {
			line-height: 1.3;
			font-size: 1.2em;
			> .ant-typography {
				margin-bottom: 0.5em;
			}
		}
	}
`

const DeleteButton = styled.span`
	justify-content: center;
	align-items: center;
	position: absolute;
	top: -10px;
	right: -5px;
	width: 25px;
	height: 25px;
	background-color: ${baseStyles.primaryColor};
	border-radius: 50px;
	color: #fff;
	display: none;
	visibility: hidden;
	z-index: 10;
	cursor: pointer;

	${media.mobile`
		display: flex;
		visibility: visible;
	`}
`

const TheSection = styled.div`
	position: relative;
	&:hover {
		${DeleteButton} {
			display: flex;
			visibility: visible;
		}
	}
`

const StyledPlayer = styled(ReactPlayer)`
	&& {
		position: relative;
		height: 100%;
		width: 100%;
		filter: brightness(0.5);
		video {
			object-fit: cover;
		}
	}
`

export default function VideoCard({ fileName = "", description = "", durationSecs, isUnauthorized, ...props }) {
	const { setModal, onSelectItem, onDeleteVideo } = props
	fileName = fileName.includes(".") ? fileName.slice(0, -4) : fileName
	durationSecs = moment(durationSecs, "mm").format("HH:mm")

	return (
		<TheSection>
			<Popconfirm title={<Trans>Delete this introduction video?</Trans>} onConfirm={() => onDeleteVideo(props)}>
				{!isUnauthorized && (
					<DeleteButton className="delete" onClick={() => setModal(false)}>
						<CloseOutlined />
					</DeleteButton>
				)}
			</Popconfirm>
			<StyledCard {...props} onClick={() => onSelectItem(props)}>
				<StyledPlayer url={SERVER_BASE_URL + props.path} width="100%" height="100%" controls />
				<div className="play">
					<PlayCircleFilled />
				</div>
			</StyledCard>
			{/* <Paragraph ellipsis={{ rows: 2 }}>{fileName}</Paragraph> */}
		</TheSection>
	);
}
