import React from "react"
import Card from "./Card"
import { Statistic as Stat, Row, Col } from "antd"
import { Link } from "react-router-dom"

function Statistic(props) {
    return (
        <Card autoheight title="">
            <Row type="flex" justify="space-between">
                <Col>
                    <Stat {...props} />
                </Col>
                <Col>
                    <Link to={props.to}>{props.link}</Link>
                </Col>
            </Row>
        </Card>
    )
}

export default Statistic
