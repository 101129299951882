import React from "react"
import styled from "styled-components"
import { media } from "helpers"

const StyledDonut = styled.img.attrs(({ src, alt, width }) => ({
    src,
    alt,
    width: width || "40%"
}))`
    position: absolute;
    top: ${({ top }) => top && top};
    bottom: ${({ bottom }) => bottom && bottom};
    left: ${({ left }) => left && left};
    right: ${({ right }) => right && right};
    ${media.mobile`
        display: none;
    `}
`

export default function Donut(props) {
    return <StyledDonut {...props} />
}
