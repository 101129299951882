import React from "react"
import Button from "antd/lib/button"
import styled from "styled-components"

const StyledButton = styled(Button)`
	&& {
		transition: 0ms;
		margin-right: 8px;
		&:hover {
			background: ${({ nohover }) => !nohover && "#eee"};
			border-radius: ${({ nohover }) => !nohover && "5px"};
			color: ${({ nohover }) => !nohover && "#777"};
			a,
			span {
				color: rgba(0, 0, 0, 0.35);
			}
		}
	}
`

function ButtonLink({ children, ...props }) {
	return (
		<StyledButton {...props} type="link">
			{children}
		</StyledButton>
	)
}

export default ButtonLink
