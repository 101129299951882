/* eslint-disable no-undef */
import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom"
import { Button, Calendar, Section } from 'components';
import { Badge, Dropdown, Typography } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { baseStyles } from 'styles/base';
import { mutateUpdateProfile, CHECK_GOOGLE_TOKEN, CREATE_GOOGLE_TOKEN } from 'queries/profile';
import { mobile, newClient, renderError } from 'helpers';
import { useLazyQuery, useMutation } from 'react-apollo';
import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import ApiCalendar from 'react-google-calendar-api';
import { gapi } from 'gapi-script';
import { GET_TUTOR_HIS_BOOKINGS_FOR_CALENDAR, GET_USER_TUTOR_BOOKINGS_FOR_CALENDAR } from 'queries/booking';
import {
	GoogleLogin
} from "react-google-login";
import { GoogleOutlined } from '@ant-design/icons';

import {
	AlignLeftOutlined,
	UserOutlined,
	CarryOutOutlined,
	DollarCircleOutlined,
	EnvironmentOutlined
} from "@ant-design/icons";
import {
	country
} from "App"

const DateModal = React.lazy(() => import('./DateModal'));

const StyledBadge = styled(Badge)`
	.ant-badge-status-text {
		font-size: 12px;
		margin-left: 3px;
		> p {
			p {
				font-size: 0.9em;
				color: ${baseStyles.greyColor};
				line-height: 1;
			}
		}
		> .ant-typography {
			width: 60px;
		}
	}
`;
const bookingClient = newClient('booking');
function TutorCalendar({ teachingDate = [], unauthorizedUser, onEditMode, i18n, userData, refetchData, code }) {
	const history = useHistory();
	const userDetails = JSON.parse(localStorage.getItem('userDetails')) || {};
	const [selectedData, setSelectedData] = useState('');
	const [isSignedToGoogle, setIsSignedToGoogle] = useState(ApiCalendar.sign);
	const [showDetail, setShowDetail] = useState(false);
	const [bookings, setBookings] = useState([]);
	const [googleCalendarShow, setGoogleCalendarShow] = useState(false);

	const [getTutorHisBookingsForCalendar] = useMutation(GET_TUTOR_HIS_BOOKINGS_FOR_CALENDAR, {
		client: bookingClient,
	})

	const [getUserTutorBookingForCalendar] = useMutation(GET_USER_TUTOR_BOOKINGS_FOR_CALENDAR, {
		client: bookingClient,
	})

	const [checkGoogleToken] = useMutation(CHECK_GOOGLE_TOKEN, {
	})

	const [createToken] = useMutation(CREATE_GOOGLE_TOKEN, {
	})

	const handleSelectDate = date => {
		if (userData.userPvid === userDetails.pvid) return
		const dateString = date.startOf('day').toISOString();
		const selectedData = teachingDate.filter(
			t =>
				dateString >=
				moment(t.startTime)
					.startOf('day')
					.toISOString() &&
				dateString <=
				moment(t.endTime)
					.startOf('day')
					.toISOString()
		);
		setShowDetail(true);
		setSelectedData({ date, schedule: selectedData });
	};

	const normalizeEvent = (items = []) => {
		return JSON.stringify(
			items.map(r => ({
				id: r.id || r.pvid,
				title: r.summary,
				startTime: r?.start?.dateTime || r.startTime,
				endTime: r?.end?.dateTime || r.endTime,
				notes: r.description,
				location: r.location,
				status: r.status
			}))
		);
	};




	useEffect(() => {
		(async () => {
			if (userData.userPvid === userDetails.pvid) {
				const { data } = await getTutorHisBookingsForCalendar()
				setBookings(data.getTutorHisBookingsForCalendar)
			} else {
				const { data } = await getUserTutorBookingForCalendar({ variables: { tutorId: userData.userPvid } })
				setBookings(data.getUserTutorBookingsForCalendar)
			}
		})()
	}, [userData])



	const renderDateCell = value => {
		const valString = value.startOf('day').toISOString();
		return bookings.map(item => {
			if (
				valString >=
				moment(item.startTime)
					.startOf('day')
					.toISOString() &&
				valString <=
				moment(item.endTime)
					.startOf('day')
					.toISOString()
			) {
				return (
					<Dropdown placement="left" overlay={(
						<StyledEventDetails>

							<Typography.Title level={4}>
								{item.title}
							</Typography.Title >
							<Typography.Paragraph
								style={{
									fontSize: "14px",
									padding: "0 7px",
									color: "orange"
								}}
							>
								{moment(item.startTime).format("dddd, MMMM Do YYYY")} . {moment(item.startTime).format("h:mm a")} - {moment(item.endTime).format("h:mm a")}
							</Typography.Paragraph>
							<div className="details">

								<Typography.Paragraph>
									<AlignLeftOutlined /> {item.description}
								</Typography.Paragraph>

								{userData.userPvid === userDetails.pvid && (
									<Fragment>
										<Typography.Paragraph>
											<UserOutlined /> {item.studentName}
										</Typography.Paragraph>

										<Typography.Paragraph>
											<EnvironmentOutlined /> {item.location}
										</Typography.Paragraph>

										<Typography.Paragraph>
											<CarryOutOutlined /> {item.status}
										</Typography.Paragraph>

										<Typography.Paragraph>
											<DollarCircleOutlined /> {item.price}
										</Typography.Paragraph>
									</Fragment>)}
							</div>

							{userData.userPvid === userDetails.pvid && <div className="action">
								<Button type="primary" onClick={() => { history.push(`/${country}/booking-list`) }}>
									Go To Booking Page
								</Button>
							</div>}
						</StyledEventDetails>
					)} >
						<StyledEvent >
							<Typography.Text ellipsis>{moment(item.startTime).format("hh:mma")} {" "} {item.title}</Typography.Text>
						</StyledEvent>
					</Dropdown>
				);
			}
			return null;
		});
	};



	useEffect(() => {
		const getData = async () => {
			try {
				await checkGoogleToken()
				setGoogleCalendarShow(false)
			} catch (err) {
				setGoogleCalendarShow(true);
			}
		}

		getData();
	}, [])


	const responseGoogle = async (res) => {
		try {
			await createToken({ variables: { code: res.code } });
			setGoogleCalendarShow(false)
		} catch (err) {

		}
	}
	const responseGoogleError = (err) => {
		console.log(err)
	}



	const renderEvents = () => {
		return (
			<>
				{googleCalendarShow && userData.userPvid === userDetails.pvid && <GoogleCalWrapper>
					<Heading style={{ marginLeft: "15px" }}> Want to sync your calander with Google ?</Heading>
					{/* <SyncCalendarBtn
            onClick={async () => {
              await apiCal.handleAuthClick();
              console.log("is login", apiCal.sign)

            }}
            type="primary"
          >
            Login
          </SyncCalendarBtn> */}
					<GoogleLoginStyled
						clientId="444400706472-vtf8e5c4and9gcf9udngu307jjbutdd7.apps.googleusercontent.com"
						onSuccess={responseGoogle}
						buttonText="Sign in with Google."
						onFailure={responseGoogleError}
						responseType="code"
						accessType="offline"
						scope="openid email profile https://www.googleapis.com/auth/calendar"

					/>

				</GoogleCalWrapper>}
				<Calendar onSelect={handleSelectDate} dateCellRender={renderDateCell} />
			</>
		);
	};

	return (
		<Section paddingHorizontal={0} noPadding={mobile} /* style={{ pointerEvents: "none" }} */>
			{/* <ResultSection
				type="comingsoon"
				title={<Trans>We've been working hard for this schedule feature. Please stay tuned.</Trans>}
			/> */}

			<Suspense fallback="Loading...">
				<DateModal
					visible={showDetail}
					unauthorizedUser={unauthorizedUser}
					userDetails={userDetails}
					data={selectedData}
					userData={userData}
					onCancel={() => setShowDetail(false)}
					onAdded={refetchData}
					code={code}
				/>
			</Suspense>

			{renderEvents()}
		</Section>
	);
}

export default withI18n()(TutorCalendar);



const GoogleCalWrapper = styled.div`
  border-top: 1px solid #eee;
  padding: 20px 5px;
  text-align: center;
`;


const GoogleLoginStyled = styled(GoogleLogin)`

`

const Heading = styled.h3`
  margin-bottom: 20px;
  font-size: 0.9rem;
  width: 100%;
  text-align: left;
`;


const StyledEvent = styled.div`
	background: #fff6f6;
	padding: 3px;
	border: 1px solid #fec865;
	border-radius: 7px;
	margin: 3px 0;
	font-size: 10px;
	.ant-typography-ellipsis-single-line{
		color: orange;
	}
	&:hover {
		color: red
	}

`

const StyledEventDetails = styled.div`
background: white;
padding: 1rem;
border-radius: 1rem;
width: 24rem;
min-height: 10rem;
border: 1px solid #ccc;
margin-right: 1rem;
margin-top: 4rem;

.details{
	padding: 2px;
    margin-top: 1rem;
}

.action{
	padding: 1rem;
    display: flex;
    justify-content: center;
}
`