import gql from "graphql-tag"


export const GET_USER_CHAT_ROOMS = gql`
	mutation GET_USER_CHAT_ROOMS{
		getUserChatRooms{
			_id
			closed
			unReadMsgs
			users{
				firstName
				lastName
				userId
				userAvatar
				country
			}
			updatedAt
		}
	}
`;

export const GET_CHAT_MESSAGES = gql`
	mutation GET_CHAT_MESSAGES($roomId: String!) {
		getRoomMessages(roomId: $roomId){
			owner{
				userId
			}
			msgType
			_id
			content
			createdAt
			seen
			privateFor{
				firstName
				lastName
				userId
				userAvatar
				country
			}
			replyId
		}
	}
`

export const SEND_MESSAGE_TO_CHAT_ROOM = gql`
	mutation SEND_MESSAGE_TO_CHAT_ROOM($content: String!, $roomId: String!){
		sendMessageToChatRoom(content: $content, roomId: $roomId)
	}
`

export const MAKE_MESSAGES_AS_READ = gql`
	mutation MAKE_MESSAGES_AS_READ($roomId: String!, $messages: [String!]!){
		readMessages(roomId: $roomId, messages:$messages)
	}
`

export const CREATE_NEW_CHAT = gql`
	mutation CREATE_NEW_CHAT($userId: String!){
		createNewChatRoom(userId: $userId)
	}
`;

export const GET_UNREAD_MSGS_COUNT = gql`
	mutation GET_UNREAD_MSGS_COUNT{
		getUnReadMsgsCount
	}
`;


export const GET_CHAT_TEMPLATES = gql`
	mutation GET_CHAT_TEMPLATES{
		getAutoMessages{
			_id
			userId
			question
			createdAt
		}
	}
`

export const DELETE_CHAT_TEMPLTES = gql`
	mutation DELETE_CHAT_TEMPLTES($msgId: String!){
		deleteAutoMessage(msgId:$msgId)
	}
`

export const ADD_CHAT_TEMPLATE = gql`
	mutation ADD_CHAT_TEMPLATE($message: String!){
		addAutoMessage(message: $message)
	}
`