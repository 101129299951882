import React, { useState, useEffect } from 'react';
import { Configure, connectHits, Index, connectStateResults } from 'react-instantsearch-dom';
import { UserOutlined, SolutionOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { searchResult } from '../../../store/actions/searchOverlayActions';
import MoreInfo from './MoreInfo';

const TypesenseResponse = ({ dropdown, setDropdown }) => {
	let history = useHistory();
	const location = useLocation();
	const [hitData, setHitData] = useState({});
	const [firstHit, setFirstHit] = useState({ fromCollection: '' });
	const dispatch = useDispatch();

	const searchVal = useSelector(state => state.searchValue);
	const searchRes = useSelector(state => state.searchResult);

	useEffect(() => {
		setHitData({ fromCollection: '' });
	}, [dropdown, searchRes]);

	useEffect(() => {
		JSON.stringify(hitData) !== JSON.stringify(firstHit) && setHitData(firstHit);
	}, [firstHit]);

	useEffect(() => {
		setDropdown(false);
	}, [location]);
	const country = localStorage.getItem('country') || 'sg';

	const Hits = ({ hits }) => {
		return (
			<div>
				{hits.map(hit => {
					const VisitProfile = () => {
						const genPath = `/${country}/${hit.Token}--${hit.id}--${hit.FirstName}--${hit.LastName}`;
						const newPath = encodeURI(genPath);
						history.push(newPath);
					};
					return (
						<ResultBox
							key={hit.id}
							title="Open Profile"
							onMouseEnter={() => setHitData({ ...hit, fromCollection: 'tutors' })}
							onClick={VisitProfile}>
							<UserOutlined style={{ fontSize: '1.2rem', color: 'gray' }} />
							<div>
								{hit.FirstName} {hit.LastName}
							</div>
						</ResultBox>
					);
				})}
			</div>
		);
	};

	const Hits1 = ({ hits }) => {
		return (
			<div>
				{hits.map(hit => {
					const VisitContent = () => {
						const genPath = `/${country}/content/${hit.id}--${hit.Title}`;
						const newPath = encodeURI(genPath);
						history.push(newPath);
					};
					return (
						<ResultBox
							key={hit.id}
							title="Open Content"
							onMouseEnter={() => setHitData({ ...hit, fromCollection: 'contents' })}
							onClick={VisitContent}>
							<SolutionOutlined style={{ fontSize: '1.2rem', color: 'gray' }} />
							<div>{hit.Title}</div>
						</ResultBox>
					);
				})}
			</div>
		);
	};

	const stateResult = ({ searchState, searchResults, children }) => {
		const hasResults = searchResults && searchResults.nbHits !== 0;
		return hasResults && children;
	};

	const Results = ({ allSearchResults, children }) => {
		const hasResults = allSearchResults && Object.values(allSearchResults).some(results => results.nbHits > 0);

		hasResults !== searchRes && dispatch(searchResult(hasResults));

		let tutorsHit = allSearchResults?.tutors?.nbHits;
		let contentsHit = allSearchResults?.contents?.nbHits;

		if (tutorsHit >= 1) {
			let res = { ...allSearchResults?.tutors?.hits[0], fromCollection: 'tutors' };
			if (JSON.stringify(firstHit) !== JSON.stringify(res)) {
				setFirstHit(res);
			}
			// console.log('tutors', res);
		} else if (contentsHit >= 1) {
			let res = { ...allSearchResults?.contents?.hits[0], fromCollection: 'contents' };
			if (JSON.stringify(firstHit) !== JSON.stringify(res)) {
				setFirstHit(res);
			}
			// console.log('contents', res);
		} else {
			let res = { fromCollection: '' };
			if (JSON.stringify(firstHit) !== JSON.stringify(res)) {
				setFirstHit(res);
			}
			// console.log(res);
		}

		return !hasResults ? (
			<div>
				<NotFound>
					<div>
						Nothing found for "<NotFoundQuery>{searchVal}</NotFoundQuery>"
					</div>
				</NotFound>
				<Index indexName="tutors" />
				<Index indexName="contents" />
			</div>
		) : (
			children
		);
	};

	const CustomHits = connectHits(Hits);
	const CustomHits1 = connectHits(Hits1);

	const IndexResults = connectStateResults(stateResult);
	const AllResults = connectStateResults(Results);

	if (searchVal.length === 0) {
		return <div></div>;
	}

	return (
		<MainContainer>
			<ResultList>
				<AllResults>
					<div>
						<Index indexName="tutors">
							<IndexResults>
								<ResultHeading>
									<Trans>tutors</Trans>
								</ResultHeading>
								<Configure hitsPerPage={10} />
								<CustomHits />
							</IndexResults>
						</Index>

						<Index indexName="contents">
							<IndexResults>
								<ResultHeading>
									<Trans>contents</Trans>
								</ResultHeading>
								<Configure hitsPerPage={10} />
								<CustomHits1 />
							</IndexResults>
						</Index>
					</div>
				</AllResults>
			</ResultList>
			<MoreInfo data={hitData} />
		</MainContainer>
	);
};

export default TypesenseResponse;

// ███████ ████████ ██    ██ ██      ███████ ███████
// ██         ██     ██  ██  ██      ██      ██
// ███████    ██      ████   ██      █████   ███████
//      ██    ██       ██    ██      ██           ██
// ███████    ██       ██    ███████ ███████ ███████

const MainContainer = styled.div`
	display: flex;
	/* padding-top: 5px; */
	border-top: 1px solid #e6e6e6;
	border-bottom-left-radius: 1rem;
	border-bottom-right-radius: 1rem;
	box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
		0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
`;

const ResultList = styled.div`
	width: 30.78vw;
	height: 70vh;
	overflow-x: auto;
	background-color: white;
	border-bottom-left-radius: 1rem;
`;

const NotFound = styled.div`
	width: 61.5vw;
	height: 70vh;
	background-color: white;
	border-bottom-left-radius: 1rem;
	border-bottom-right-radius: 1rem;
	position: fixed;
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	color: #646464;
	font-size: 1.5rem;
	font-weight: 500;
`;

const NotFoundQuery = styled.span`
	color: orange;
`;

const ResultBox = styled.div`
	cursor: pointer;
	display: flex;
	flex-wrap: nowrap;
	justify-content: start;
	align-items: center;
	text-align: center;
	padding: 10px;
	gap: 0.3rem;
	font-weight: 400;
	color: gray;
	:hover {
		background-color: #fda500;
		color: white;
	}
`;

const ResultHeading = styled.div`
	font-size: 0.9rem;
	font-weight: 600;
	padding: 5px;
	text-transform: uppercase;
	color: #5f5f5f;
	background-color: #ededed;
`;
