import * as Types from "../../constants"

const resultReducer = (state = true, action) => {
    switch (action.type) {
        case Types.HAS_RESULTS:
            return action.payload
        default:
            return state
    }
};

export default resultReducer;