import {
	LOADING_FAVORITE_ITEMS,
	FETCH_FAVORITE_ITEMS,
	ADD_TO_FAVORITE,
	CLAER_FAVORITE_LIST,
	REMOVE_FROM_FAVORITE,
	UPDATE_FAVORITE_ITEMS,
	REMOVE_FROM_FAVORITE_ITEMS
} from '../constants/index';

const initialState = {
	loading: false,
	items: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOADING_FAVORITE_ITEMS:
			return { ...state, loading: true };
		case FETCH_FAVORITE_ITEMS:
			return { ...state, items: action.payload, loading: false };
		case ADD_TO_FAVORITE:
			const exist = state.items?.find(o => o.pvid === action.payload.pvid);

			if (!exist) {
				const items = state.items ? state.items : [];
				return {
					...state,
					items: [...items, action.payload],
					loading: false
				};
			}
			return {
				...state,
				loading: false
			};
		case CLAER_FAVORITE_LIST:
			return { ...initialState };

		case REMOVE_FROM_FAVORITE:
			return { ...state, items: state.items?.filter(o => o.pvid !== action.payload.pvid), loading: false };

		case UPDATE_FAVORITE_ITEMS:
			const items = state.items?.map(o => {
				if (o.uuid !== action.payload.uuid) {
					return {
						...o
					};
				}

				const selectedItems = o.selectedItems.map(sl => {
					if (sl.profileSubjectId !== action.payload.obj.pvid) {
						return {
							...sl
						};
					}
					return {
						...sl,
						units: action.payload.value
					};
				});

				return {
					...o,
					selectedItems
				};
			});

			return {
				...state,
				items: items,
				loading: false
			};

		case REMOVE_FROM_FAVORITE_ITEMS:
			const newItems = state.items.map(o => {
				if (o.pvid !== action.payload.pvid) {
					return {
						...o
					};
				}

				const selectedItems = o.selectedItems.filter(sl => sl.profileSubjectId !== action.payload.obj.pvid);

				return {
					...o,
					selectedItems
				};
			});
			return {
				...state,
				items: newItems,
				loading: false
			};

		default:
			return state;
	}
};
