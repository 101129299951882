import * as Types from "../constants"

const initialState = {
	user: null,
	error: null,
	loading: false,
	users: {
		tutors: []
	}
}

function userReducer(state = initialState, action) {
	switch (action.type) {
		case Types.FETCH_USER:
			return { ...state, loading: true }
		case Types.FETCH_USER_SUCCESS:
			return { ...state, user: action.payload, loading: false }
		case Types.FETCH_USER_ERROR:
			return { ...state, error: action.error, loading: false }
		case Types.FETCH_ALL_USERS:
			return { ...state, users: action.payload }
		default:
			return state
	}
}

export default userReducer
