import React from "react"
import { Result } from "antd"
import Button from "./Button"
import Section from "./Section"
import styled from "styled-components"
import spaceman from "assets/images/spaceman.png"
import mountain from "assets/images/mountain.png"
import { Card } from "components"
import { media } from "helpers"

const StyledResult = styled(Result)`
	margin-bottom: 2em;
	.ant-result-title {
		line-height: 1.2;
	}
`

const ComingSoonCard = styled(props => <Card {...props} />)`
	&& {
		pointer-events: none;
		position: absolute;
		z-index: 2;
		width: 70%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		${media.mobile`
            && {
                width: 90%;
            }
        `}
	}
`

export default function ResultSection({ title = "", type = "", extra, ...props }) {
	if (type === "search")
		return (
			<Section>
				<StyledResult {...props} title={title} icon={<img src={spaceman} alt="Search empty result" width="150" />} />
			</Section>
		)
	else if (type === "comingsoon") {
		return (
			<ComingSoonCard nohover autoheight>
				<Section style={{ borderRadius: 8 }} marginBottom="very">
					<StyledResult
						{...props}
						icon={<img src={mountain} alt="Working in progress" width="150" />}
						title={title}
						extra={extra ? <Button type="primary">Next</Button> : null}
					/>
				</Section>
			</ComingSoonCard>
		)
	}

	return (
		<Section style={{ borderRadius: 8 }} marginBottom="very">
			<StyledResult
				{...props}
				icon={<img src={mountain} alt="Working in progress" width="150" />}
				title={title}
				extra={extra ? <Button type="primary">Next</Button> : null}
			/>
		</Section>
	)
}
