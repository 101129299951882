import * as types from "../constants"

const initials = {
	loading: false,
	articles: [],
	articleCategories: [],
	articleCategoriesError: null,
	articlesError: null
}

function reducer(state = initials, action) {
	switch (action.type) {
		case types.LOADING_ARTICLE:
			return { ...state, loading: true }
		case types.FETCH_ARTICLE_CATEGORIES:
			return { ...state, articleCategories: action.payload, loading: false }
		case types.FETCH_ARTICLES:
			return { ...state, articles: action.payload, loading: false }

		//=================================================BOUNDARY==================================================//

		case types.FETCH_ARTICLE_CATEGORIES_ERROR:
			return { ...state, articleCategoriesError: action.payload, loading: false }
		case types.FETCH_ARTICLES_ERROR:
			return { ...state, articlesError: action.payload, loading: false }
		default:
			return state
	}
}

export default reducer
