import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import DynamicIcon from 'components/common/DynamicIcon';
import { media, mobile } from 'helpers';
import { baseStyles } from 'styles/base';
import { country } from 'App';

export default function LearnMenu() {
	return (
		<ServicesMenu>
			<ServicesCategoryWrapper>
				<ServicesWrapper>
					<CategoryHeading>Post Assignment</CategoryHeading>
					<Link to={`/${country}/assignments`}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-icon-test54'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Assignments</span>
						</Service>
					</Link>
					<CategoryHeading>Learning Locations</CategoryHeading>
					<Link to={'/' + country + '/study_places'}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-icon-test17'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}>Study Places</span>
						</Service>
					</Link>
					<Link to={'/' + country + '/launching-soon'}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-icon-test31'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}>Study Centers</span>
							<ComingSoon>Coming Soon </ComingSoon>
						</Service>
					</Link>
				</ServicesWrapper>
				<ServicesWrapper>
					<CategoryHeading>Learning Community</CategoryHeading>
					<Link to={'/' + country + '/feeds'}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-icon-test18'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Feeds</span>
						</Service>
					</Link>
					<Link to={'/' + country + '/cudy-rooms'}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-icon-test10'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Rooms</span>
						</Service>
					</Link>
					<a href="https://community.cudy.co">
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-SustainableCommunity'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Community</span>
						</Service>
					</a>
				</ServicesWrapper>
				<ServicesWrapper>
					<CategoryHeading>Learning Resources</CategoryHeading>
					<Link to={'/' + country + '/lesson'}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon--lesson'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Lessons</span>
						</Service>
					</Link>
					<Link to={'/' + country + '/reads/public'}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-icon-test3'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Reads</span>
						</Service>
					</Link>
					<a href="https://blog.cudy.co">
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-icon-test5'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Blog</span>
						</Service>
					</a>
					<Link to={'/' + country + '/content'}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-icon-test27'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Content</span>
						</Service>
					</Link>
				</ServicesWrapper>
				<ServicesWrapper style={{ paddingTop: 43 }}>
					<Link to={'/' + country + '/launching-soon'}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-book'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Books</span>
							<ComingSoon>Coming Soon </ComingSoon>
						</Service>
					</Link>
					<Link to={'/' + country + '/shop'}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-Shopmanagement_Home'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Shop</span>
						</Service>
					</Link>
					<a href={'https://events.cudy.co'}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-icon-test63'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Events</span>
						</Service>
					</a>
				</ServicesWrapper>
			</ServicesCategoryWrapper>
		</ServicesMenu>
	);
}

//styles

const Service = styled.span`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	text-align: center;
	color: #777;
	background-color: #fff;
	padding: 10px 20px;
	margin: 10px auto;
	border-radius: 4px;
	border: 1px solid ${baseStyles.lightGrey.one};
	width: 200px;
	box-shadow: ${baseStyles.boxShadow.hover};
	border-radius: 10px;
	position: relative;
`;
const ServicesMenu = styled.div`
	background-color: ${baseStyles.lightGrey.two};
	width: 100vw;
	padding: 2rem 1.2rem;
	user-select: none;

	${media.mobile`
	height:100vh;
	overflow-y:scroll
	`}
`;

const ServicesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-start;

	${media.mobile`
	&& {
		padding:0px 3rem;
		margin:0px;
		flex-wrap:wrap;
	}
	`}
`;

const ServicesCategoryWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: flex-start;
	${media.mobile`
	&& {
		flex-direction:column;
		padding-bottom:200px;
	align-items: center;


	}
	`}
`;
const CategoryHeading = styled.h3`
	margin-top: 10px;
`;

const ComingSoon = styled.span`
	position: absolute;
	right: 5px;
	top: 3px;
	color: ${baseStyles.primaryColor};
	font-size: 0.5rem;
`;
