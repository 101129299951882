import { client, renderError } from 'helpers';
import { message } from 'antd';
import { FETCH_ALL_USERS, FETCH_USER, FETCH_USER_ERROR, FETCH_USER_SUCCESS } from '../constants';
import { mutateUserProfile } from 'queries/profile';

export const fetchUserOptions = () => ({
	client: client.profileClient,
	onError: err => {
		const error = err.graphQLErrors[0] || {};
		if (error.statusCode === 401) {
			localStorage.removeItem('i18n');
			localStorage.removeItem('country');

			localStorage.removeItem('token');

			localStorage.removeItem('userDetails');
			localStorage.removeItem('firstTime');

			window.location.reload(true);
		}
	}
});

export const educationOptions = (setEditMode, fetchUser, successMessage = '') => ({
	client: client.profileClient,
	onCompleted: data => {
		setEditMode('');
		fetchUser();
		message.success(successMessage);
	},
	onError: err => renderError(err)
});

export const fetchAllUser = users => async dispatch => {
	await dispatch({
		type: FETCH_ALL_USERS,
		payload: users
	});
};

export const fetchUser = () => async (dispatch) => {
	dispatch({ type: FETCH_USER })
	client.profileClient.mutate({
		mutation: mutateUserProfile
	}).then(({ data }) => {
		data = data.getProfile || {}
		dispatch({
			type: FETCH_USER_SUCCESS,
			payload: data
		})
	}).catch((err) => {
		const error = (err.graphQLErrors || [])[0] || {}
		if (error.statusCode === 401 && localStorage.getItem("token")) {
			localStorage.clear()
			window.location.reload(true)
		}
		dispatch({
			type: FETCH_USER_ERROR,
			error
		})
	})
}