import React from "react"
import { Carousel as Slideshow } from "antd"
import styled from "styled-components"

const Slider = styled(Slideshow)`
    margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : "inherit")};
    .slick-slide {
        text-align: center;
        height: ${({ height }) => (height ? height : "260px")};
        line-height: 260px;
        background: ${({ background }) => (background ? background : "#364d79")};
        overflow: hidden;
    }
    && {
        .slick-dots {
            // bottom: 0;
            li {
                &.slick-active {
                    button {
                        background: #ff9d00;
                    }
                }
                button {
                    background: #ddd;
                    height: 8px;
                    border-radius: 5px;
                    opacity: 0.8;
                }
            }
        }
    }
`

function Carousel({ children, ...props }) {
    return (
        <Slider {...props} swipeToSlide draggable>
            {children}
        </Slider>
    )
}

export default Carousel
