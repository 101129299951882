import React from "react"
import { Steps as Steppy } from "antd"

export default function Steps({ steps = [], direction = "vertical", ...props }) {
    return (
        <Steppy {...props} direction={direction}>
            {steps.map(item => (
                <Steppy.Step key={item} title={item} />
            ))}
        </Steppy>
    )
}
