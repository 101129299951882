import { gql } from 'apollo-boost';

export const CREATE_NEW_VOUCHERS = gql`
	mutation createNewVoucher(
		$userId: String!
		$voucherName: String!
		$numberOfDaysId: String!
		$usedFeeId: String!
		$typeOfDiscount: String!
		$discountAmount: Float
		$discountCurrency: String
		$percentageOfPrice: Float
		$totalNumberOfUsed: Int!
		$serviceId: String!
		$payUsingHisBalance: Boolean!
	) {
		createNewVoucher(
			userId: $userId
			voucherName: $voucherName
			numberOfDaysId: $numberOfDaysId
			usedFeeId: $usedFeeId
			typeOfDiscount: $typeOfDiscount
			discountAmount: $discountAmount
			discountCurrency: $discountCurrency
			percentageOfPrice: $percentageOfPrice
			totalNumberOfUsed: $totalNumberOfUsed
			serviceId: $serviceId
			payUsingHisBalance: $payUsingHisBalance
		) {
			success
			voucher
		}
	}
`;

export const CLAIM_VOUCHER = gql`
	mutation claimVoucher($voucherId: String!) {
		claimVoucher(voucherId: $voucherId)
	}
`;

export const DELETE_VOUCHER = gql`
	mutation userDeleteHisVoucher($voucherId: String!) {
		userDeleteHisVoucher(voucherId: $voucherId)
	}
`;

export const GET_ONE_VOUCHER_DETAILS = gql`
	mutation getOneVoucherDetails($voucherCode: String!, $userId: String) {
		getOneVoucherDetails(voucherCode: $voucherCode, userId: $userId) {
			id
			userId
			code
			sellerCreditsFee
			buyerCreditsPrice
			service
			typeOfDiscount
			discountOfPercentage
			discountAmount
			discountCurrency
			totalNumberOfUsed
			numberOfUsage
			startedAt
			endAt
			expired
			isClaimed
			ableToUse
			active
			isUsedBefore
		}
	}
`;

export const GET_VOUCHER_FOR_PUBLIC = gql`
	mutation getVoucherForPublic($userId: String, $service: String){
		getVoucherForPublic (userId: $userId, service: $service) {
			id
			userId
			code
			sellerCreditsFee
			buyerCreditsPrice
			service
			typeOfDiscount
			discountOfPercentage
			discountAmount
			discountCurrency
			totalNumberOfUsed
			numberOfUsage
			startedAt
			endAt
			expired
			isClaimed
			ableToUse
			active
			isUsedBefore
		}
	}
`;

export const GET_VOUCHER_CLAIMS = gql`
	mutation {
		getUserVoucherClaims {
			id
			userId
			code
			sellerCreditsFee
			buyerCreditsPrice
			service
			typeOfDiscount
			discountOfPercentage
			discountAmount
			discountCurrency
			totalNumberOfUsed
			numberOfUsage
			startedAt
			endAt
			expired
			isClaimed
			ableToUse
			active
			isUsedBefore
		}
	}
`;

export const GET_USER_VOUCHERS = gql`
	mutation {
		getUserVouchers {
			id
			userId
			code
			sellerCreditsFee
			buyerCreditsPrice
			service
			typeOfDiscount
			discountOfPercentage
			discountAmount
			discountCurrency
			totalNumberOfUsed
			numberOfUsage
			startedAt
			endAt
			expired
			isClaimed
			ableToUse
			active
			isUsedBefore
		}
	}
`;
