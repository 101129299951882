import React from 'react';
import styled from 'styled-components';


const HorizAdd = styled.ins`
display: block;
	width : 200px;
	height : 500px;
	margin: 0 auto;
	background-color: #fff;


	@media (max-width : 1100px){
		display : none;
	}
		margin-top : 300px;

	
`

const initAd = () => {
	(window.adsbygoogle = window.adsbygoogle || []).push({});
  };
  
  class SideAdd extends React.Component {
    shouldComponentUpdate(nextProps) {
        return this.props.currentPath !== nextProps.currentPath
    }
  
	componentDidUpdate() {
	  initAd();
	}
  
	render() {
	  const {path } = this.props;
	  return (
		<div key={Math.random()}>
		  <HorizAdd
			className="adsbygoogle" 
			data-ad-client="ca-pub-9691916143882648"
			data-ad-slot="7457575276"
			data-full-width-responsive= "true"
		  ></HorizAdd>
		</div>
	  );
	}
  }

  

export default SideAdd;