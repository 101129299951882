import React from 'react';
import styled from 'styled-components';

const HorizAdd = styled.ins`

	@media (min-width : 800px){
		display : none;
	}
	display : block;
	width : 600px;
	height : 100px;
	margin-bottom : 20px;
	background-color : #fff;
	
	@media (max-width : 720px){
		width : 450px;
		height : 100px;
	}

	@media (max-width : 450px){
		width : 300px;
		height : 80px;
	}

	@media (max-width : 300px){
		width : 200px;
		height : 70px;
	}

	
`

const initAd = () => {
	(window.adsbygoogle = window.adsbygoogle || []).push({});
  };
  
  class BottomAdd extends React.Component {
	shouldComponentUpdate(nextProps) {
		return this.props.currentPath !== nextProps.currentPath
	}
  
	componentDidUpdate() {
	  initAd();
	}
  
	render() {
	  const {path } = this.props;

	  return (
		<div key={Math.random()} >
		  <HorizAdd
			className="adsbygoogle"
			data-ad-client="ca-pub-9691916143882648"
			data-ad-slot="7457575276"
			data-full-width-responsive= "true"
		  ></HorizAdd>
		</div>
	  );
	}
  }
  
 
   

export default BottomAdd;