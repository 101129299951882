import React from 'react';
import { Input } from 'antd';
import styled from 'styled-components';

const StyledSearch = styled(Input.Search)`
	&& {
		width: ${({ width }) => width || '200px'};
		height: 45px;
		border-radius: 6px;
		display: flex;
		align-items: center;
		.ant-input {
			box-shadow: none;
			border: none;
			&:focus {
				width: 100%;
				box-shadow: none;
				background: initial;
			}
		}
		.ant-input-group-addon {
			button {
				border: 0;
			}
		}
		&&:focus-within {
			box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.2);
		}
	}
`;

function Search(props) {
	return <StyledSearch {...props} />;
}

export default Search;
