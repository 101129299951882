import * as Types from "../constants"

const initialState = {
    user: {}
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.LOADING_AUTH:
            return { ...state, loading: true }
        case Types.AUTH_USER:
            return {
                ...state,
                token: action.payload,
                user: { name: "Adrian Wibi", role: "Tutor" },
                loading: false
            }
        case Types.UNAUTH_USER:
            return { ...state, user: null, token: null, loading: false }
        default:
            return state
    }
}

export default authReducer
