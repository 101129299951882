import React from "react"
import styled from "styled-components"

export default function NameAndLogoCard(props) {
	return (
		<CompanyDetails>
			<CompanyLogo src={props.logo}></CompanyLogo>
			<CompanyName>{props.name}</CompanyName>
		</CompanyDetails>
	)
}

const CompanyDetails = styled.div`
	width: 50%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`
const CompanyName = styled.span`
	white-space: nowrap;
`
const CompanyLogo = styled.img`
	height: 30px;
	margin-right: 5px;
	border-radius: 50%;
`
