import gql from 'graphql-tag';

export const mutateLogin = gql`
	mutation mutateLogin($email: String!, $password: String!) {
		signIn(email: $email, password: $password) {
			firstTime
			token
			user {
				pvid
				email
				password
				dateJoined
				token
				verified
				isActive
				country
				role {
					pvid
					code
					name
					isPrivate
				}
			}
			companydata {
				pvid
				userPvid
				email
				name
				designation
			}

			# profile {
			# 		pvid
			# 		phone
			# 		address
			# 		firstName
			# 		lastName
			# }
		}
	}
`;


export const mutateLoginToCommunity = gql`
	mutation MutateLoginToCommunity($email: String!, $password: String!) {
		singInToCommunity(email: $email, password: $password) {
			firstTime
			token
			user {
				pvid
				email
				password
				dateJoined
				token
				verified
				isActive
				country
				role {
					pvid
					code
					name
					isPrivate
				}
			}
			companydata {
				pvid
				userPvid
				email
				name
				designation
			}
			communityToken
			# profile {
			# 		pvid
			# 		phone
			# 		address
			# 		firstName
			# 		lastName
			# }
		}
	}
`;


export const mutateRegistration = gql`
	mutation mutateRegistration(
		$rolePvid: Int!
		$email: String!
		$password: String!
		$firstName: String
		$lastName: String
		$description: String
		$dob: Date
		$country: String!
		$address: String
		$referralId: Int
		$referralCode: String
		$uplineId: Int # $colleagues: [ColleagueUsers]!
	) {
		register(
			rolePvid: $rolePvid
			email: $email
			password: $password
			firstName: $firstName
			lastName: $lastName
			description: $description
			dob: $dob
			country: $country
			address: $address
			referralId: $referralId
			referralCode: $referralCode
			uplineId: $uplineId # colleagues: $colleagues
		) {
			userCode
			userPvid
			country
		}
	}
`;

export const mutateCompanyRegistration = gql`
	mutation mutateCompanyRegistration(
		$rolePvid: Int!
		$email: String!
		$password: String!
		$firstName: String
		$lastName: String
		$country: String!
		$referralId: Int
		$referralCode: String
		$designation: String
		$companyName: String!
		$uplineId: Int
		$companyCity: String!
		$companyState: String
		$companyCountry: String!
		$companyStreet: String!
		$companyEmail: String!
		$companyFacebook: String
		$companyIndustry: String!
		$companyInstagram: String
		$companyLinkedin: String
		$companyPhone: String!
		$companyPostalCode: String!
		$companySummary: String!
		$companyTwitter: String
		$companyUnit: String!
		$companyWebsite: String!

	) {
		registerCompany(
		rolePvid: $rolePvid
		email: $email
		password: $password
		firstName: $firstName
		lastName: $lastName
		country: $country
		referralId: $referralId
		referralCode: $referralCode
		designation: $designation
		companyName: $companyName
		uplineId: $uplineId
		companyCity: $companyCity
		companyState: $companyState
		companyCountry: $companyCountry
		companyStreet: $companyStreet
		companyEmail: $companyEmail
		companyFacebook: $companyFacebook
		companyIndustry: $companyIndustry
		companyInstagram: $companyInstagram
		companyLinkedin: $companyLinkedin
		companyPhone: $companyPhone
		companyPostalCode: $companyPostalCode
		companySummary: $companySummary
		companyTwitter: $companyTwitter
		companyUnit: $companyUnit
		companyWebsite: $companyWebsite
		) {
			userCode
			userPvid
			country
		}
	}
`;

export const queryLogout = gql`
	query queryLogout {
		signOut
	}
`;

export const mutateForgotPassword = gql`
	mutation mutateForgotPassword($email: String!) {
		requestResetPassword(email: $email) {
			success
			text
		}
	}
`;

export const mutateSendVerificationEmail = gql`
	mutation mutateSendVerificationEmail($email: String!) {
		sendActivationEmail(email: $email) {
			success
			text
		}
	}
`;

export const mutateNewPassword = gql`
	mutation mutateNewPassword($newPassword1: String!, $newPassword2: String!, $resetCode: String!) {
		resetPassword(newPassword1: $newPassword1, newPassword2: $newPassword2, resetCode: $resetCode) {
			success
			text
		}
	}
`;

export const mutateUpdatePassword = gql`
	mutation mutateUpdatePassword($newPassword1: String!, $newPassword2: String!, $oldPassword: String!) {
		updatePassword(newPassword1: $newPassword1, newPassword2: $newPassword2, oldPassword: $oldPassword) {
			token
			user {
				pvid
				email
				password
				dateJoined
				verified
				token
			}
		}
	}
`;

export const MUTATE_DEACTIVATE_ACCOUNT = gql`
	mutation($reason: String!) {
		deactivateUserAccount(reason: $reason) {
			text
			success
		}
	}
`;

