import React from "react"
import { Calendar as Cal } from "antd"
import styled from "styled-components"

const StyledCal = styled(Cal)`
	&& {
		.ant-fullcalendar-header,
		.ant-fullcalendar {
			background: ${({ bg }) => (bg ? bg : "inherit")};
			color: ${({ color }) => (color ? color : "inherit")};
			.ant-fullcalendar-date {
				.ant-fullcalendar-value {
					border-radius: 50px;
				}
				.ant-fullcalendar-content {
					color: ${({ color }) => (color ? color : "inherit")};
					bottom: -2px;
					.ant-badge-status-text {
						// display: none;
						// visibility: hidden;
					}
				}
			}
			.ant-fullcalendar-calendar-body {
				padding: 8px 0;
			}
		}
	}
`

function Calendar(props) {
	return <StyledCal {...props} />
}

export default Calendar
