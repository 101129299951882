import React from "react"
import styled from "styled-components"
import { Row } from "antd"
import { media } from "helpers"

const StyledRow = styled(Row).attrs(() => ({
	type: "flex",
	gutter: 16,
}))`
	&& {
		position: relative;
		width: auto;
		height: ${({ height }) => height};
		margin: ${({ margin = {} }) => {
		if (typeof margin !== "object") return margin
		return `${margin.mt || 0} ${margin.mr || 0} ${margin.mb || "1.5em"} ${margin.ml || 0}`
	}};
		padding: ${({ padding = {} }) => {
		if (typeof padding !== "object") return padding
		return `${padding.pt || 0} ${padding.pr || 0} ${padding.pb || 0} ${padding.pl || 0}`
	}};
		flex-wrap: nowrap;
		overflow-x: scroll;
		scroll-snap-type: ${({ snapping }) => snapping && "x mandatory"};
		-webkit-overflow-scrolling: touch;

		.ant-col {
			scroll-snap-align: ${({ snapping }) => snapping && "center"};
		}

		&::-webkit-scrollbar {
			display: none;
		}
	}

	${media.mobile`
		&& {
			flex-wrap: nowrap;
		}
	`}
`

/**
 * @description A Row component with horizontal scrolling ability
 * Good for mobile display
 * @param snapping: boolean
 * @param height: string | number
 */

export default function HorizontalRow({ children, snapping = false, height = "250px", ...props }) {
	return (
		<StyledRow {...props} snapping={snapping} height={height}>
			{children}
		</StyledRow>
	)
}
