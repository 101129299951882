import React,{ createContext, useState, useEffect } from 'react';
import { GET_USER_CART_ITEMS } from 'queries/globalCart';
import { useMutation } from 'react-apollo';
import { newClient, renderError } from 'helpers';


//create a context, with createContext api
export const itemCountContext = createContext();

const GlobalcartItemCount = (props) => {
    const cartClient = newClient('globalCart');
    var getJson = localStorage.getItem('code')
    const [itemCount, setItemCount] = useState(total);
    const [globalCartVoucherCode, setGlobalCartVoucherCode]=useState(getJson);
    const [total, setTotal] = useState(0);


    const [getUserCartsItems] = useMutation(GET_USER_CART_ITEMS, {
		client: cartClient,
		onCompleted: data => {
			if (data) {
				
				setTotal((data?.getUserCartsItems.contentCart.contentItems).length + (data?.getUserCartsItems.bookingCart.bookingItems).length);
			}
		},
		onError: error =>console.log(error)
	});
        // this state will be shared with all components 
    
    useEffect(()=>{
        getUserCartsItems();
        setItemCount(total);
    },[])    
    

    return (
                // this is the provider providing state
        <itemCountContext.Provider value={{itemCount, setItemCount, globalCartVoucherCode, setGlobalCartVoucherCode}}>
            {props.children}
        </itemCountContext.Provider>
    );
};

export default GlobalcartItemCount;