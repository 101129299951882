import React, { useState, Fragment, useContext, useEffect } from 'react';
import axios from 'axios';
import { Typography, Row, Col, Result, Modal } from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe } from '@stripe/react-stripe-js';
import { Trans } from '@lingui/macro';
import moment from 'moment';
import { Button, Section, Loading } from 'components';
import { formatCurrency, newClient } from 'helpers';
import { GET_BALANCE_INFO, mutateUpdateProfile } from 'queries/profile';
import { UPDATE, PAY_PART_OF_BOOKING_USING_USER_BALANCE } from 'queries/booking';
import { ADD_ITEMS_TO_CART } from 'queries/globalCart';
import { GET_ONE_VOUCHER_DETAILS } from 'queries/vouchers';
import { CreditCardOutlined, BankOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import GeneratePaymentURL from 'components/GeneratePaymentShareURL';
import styled from 'styled-components';
import { RightOutlined, LoadingOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { client, renderError } from 'helpers';
import { itemCountContext } from '../GlobalcartItemCount';

const Payments = ({
	bookingData: {
		booking: bookingData,
		bookingPrice,
		currency: bookingCurrency,
		isThisFirstBooking,
		firstBookingMsgData,
		hasEnoughBalance,
		amountWillPaidUseUserBalance,
		amountWillPaidUsePaymentsMethods
	},
	code,
	...props
}) => {
	const history = useHistory();
	const currencies = {
		lk: 'LKR',
		ph: 'PHP',
		sg: 'SGD',
		id: 'IDR',
		in: 'INR',
		my: 'MYR',
		us: 'USD'
	};

	const local_country = localStorage.getItem('country') || 'sg';
	const cartClient = newClient('globalCart');
	const userCreditClient = newClient('credits');
	const country = bookingData.student.country;
	const currency = currencies[country];
	const [has_paid, set_has_paid] = useState(false);
	const [loading_update, set_loading_update] = useState(false);
	const [sharedPaymentCode, setSharedPaymentCode] = useState('');
	let userDataLocalStorage = localStorage.getItem('userDetails');
	userDataLocalStorage = JSON.parse(userDataLocalStorage);
	const [bookingSuccess, setBookingSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [voucherDetail, setVoucherDetail] = useState([]);
	const [payPartLoadig, setPayPartLoading] = useState(false);
	const [payUseBalanceLoading, setPayUseBalanceLoading] = useState(false);
	const { itemCount, setItemCount } = useContext(itemCountContext);
	const { globalCartVoucherCode, setGlobalCartVoucherCode } = useContext(itemCountContext);
	let count = itemCount;

	const [getOneVoucherDetails] = useMutation(GET_ONE_VOUCHER_DETAILS, {
		client: userCreditClient,
		onCompleted: data => {
			if (data) {
				console.log(data);
				setVoucherDetail(data.getOneVoucherDetails);
			}
		}
	});

	const [updateBooking] = useMutation(UPDATE, {
		client: client.bookingClient,
		onCompleted: data => {
			if (data) {
				console.log(data);
			}
		},
		onError: error => renderError(error)
	});

	const [paidPartofBooking] = useMutation(PAY_PART_OF_BOOKING_USING_USER_BALANCE, {
		client: client.bookingClient
	});

	const [addItemToCart] = useMutation(ADD_ITEMS_TO_CART, {
		client: cartClient,
		onCompleted: data => {
			if (data) {
				console.log(data);
				setLoading(false);
				setSuccess(true);
			}
		},
		onError: error => renderError(error)
	});

	useEffect(() => {
		getOneVoucherDetails({ variables: { voucherCode: code } });
		console.log(bookingData);
	}, []);

	if (loading_update) {
		return (
			<Section centered>
				<Loading />
			</Section>
		);
	}

	if (has_paid) {
		return (
			<Section centered>
				<Typography.Title className="mb2em">
					<Trans>Booking Success</Trans>
				</Typography.Title>
			</Section>
		);
	}

	if (local_country !== country) {
		return (
			<Section centered>
				<Typography.Paragraph className="mb2em">
					<Trans>Cannot process to payment with different country</Trans>
				</Typography.Paragraph>
			</Section>
		);
	}

	const processPayment = () => {
		const data = {
			productData: {
				serviceName: 'cudyBookingTutor',
				userId: bookingData.student.userPvid,
				paymentDetails: {
					currency,
					bookingId: bookingData.pvid,
					userId: bookingData.student.userPvid,
					voucherCode: code
				}
			}
		};

		history.push(`/${local_country}/checkout/booking`, {
			bookingData: data,
			sharedPaymentCode: sharedPaymentCode
		});
	};

	const bookUseUserBalance = async () => {
		try {
			setPayUseBalanceLoading(true);
			await updateBooking({ variables: { id: +bookingData.pvid, status: 'paid', paymentName: 'cudyBalance', voucherCode: code } });
			console.log(code);
			setBookingSuccess(true);
			// history.push(`/${country}/booking-list`)
			// eslint-disable-next-line no-empty
		} catch (err) {
			setPayUseBalanceLoading(false);
		}
	};

	const handlePaidPartofBooking = async () => {
		try {
			setPayPartLoading(true);
			await paidPartofBooking({ variables: { bookingId: bookingData.pvid } });
			setPayPartLoading(false);
			// eslint-disable-next-line no-empty
		} catch (err) {
			setPayPartLoading(false);
		}
	};

	const handleAddItem = () => {
		addItemToCart({ variables: { itemId: bookingData.pvid, itemType: 'Booking', quantity: 1 } });
		setItemCount(++count);
	};

	return (
		<CustomSection centered>
			{!bookingSuccess ? (
				<Fragment>
					<Typography.Paragraph className="mb2em">
						<Typography.Paragraph>
							<Typography.Paragraph className="pricePara">
								{isThisFirstBooking ? (
									<p>
										You are currently booking {firstBookingMsgData.numberOfLessons} lessons with {firstBookingMsgData.tutorName} for{' '}
										{bookingCurrency} {bookingPrice}. An advanced payment up to the first 8 lessons for any tutor/coach/counsellor is
										required for first-time lesson payments on Cudy. A total amount of {bookingCurrency} {bookingPrice} will be charged. For
										your remaining balance of {bookingCurrency} {firstBookingMsgData.remainsAmountAfterFirstBooking}, it will be credited to
										your account balance, which can be used to purchase more lessons with {firstBookingMsgData.tutorName}, or purchase any
										other lessons, services or goods on the platform by other tutors/coaches/counsellors
									</p>
								) : amountWillPaidUseUserBalance ? (
									<p>
										You have {bookingCurrency}
										{amountWillPaidUseUserBalance} in ur balance u need to pay remains of class price, You will be charged {bookingCurrency}
										{amountWillPaidUsePaymentsMethods}, if you do not compelete this payment after click payment button you can return your
										balance by cancel this booking in booking list page.
									</p>
								) : (
									<Fragment>
										<Trans>You will be charged </Trans>
										{code === '' ? (
											<p style={{ fontWeight: 'bold', color: 'orange' }}>{` ${bookingCurrency} ${bookingPrice} `}</p>
										) : voucherDetail && voucherDetail.typeOfDiscount === 'Fixed' && code !== '' ? (
											<>
												<s style={{ fontWeight: 'bold', color: 'orange' }}>{` ${bookingCurrency} ${bookingPrice} `}</s>
												{bookingPrice - voucherDetail.discountAmount <= 0 ? (
													<p style={{ fontWeight: 'bold', color: 'orange' }}>0</p>
												) : (
													<p style={{ fontWeight: 'bold', color: 'orange' }}>{` ${bookingCurrency} ${bookingPrice -
														voucherDetail.discountAmount} `}</p>
												)}
											</>
										) : voucherDetail && voucherDetail.typeOfDiscount === 'Percentage' && code !== '' ? (
											<>
												<s style={{ fontWeight: 'bold', color: 'orange' }}>{` ${bookingCurrency} ${bookingPrice} `}</s>
												{bookingPrice - (bookingPrice / 100) * voucherDetail.discountOfPercentage <= 0 ? (
													<p style={{ fontWeight: 'bold', color: 'orange' }}>0</p>
												) : (
													<p style={{ fontWeight: 'bold', color: 'orange' }}>{` ${bookingCurrency} ${bookingPrice -
														(bookingPrice / 100) * voucherDetail.discountOfPercentage} `}</p>
												)}
											</>
										) : (
											''
										)}
									</Fragment>
								)}
							</Typography.Paragraph>
							<Typography.Paragraph className="notePara">
								<Trans>NOTE: You need to compelete payment process to send booking to tutor account</Trans>
							</Typography.Paragraph>
						</Typography.Paragraph>
					</Typography.Paragraph>
					{code !== '' ? (
						<CustomGeneratePaymentURL
							paymentCode={sharedPaymentCode}
							setSharedPaymentCode={setSharedPaymentCode}
							nextPage={false}
							userId={userDataLocalStorage.pvid}
							country={country}
							serviceName={'cudyBookingTutor'}
						/>
					) : ('')}
					<Typography.Paragraph className="mb2em processPara">
						<Trans>Please click button below to proces the payments</Trans>
					</Typography.Paragraph>
					{!hasEnoughBalance && code === '' ? (
						<div style={{ width: '100%' }}>
							<Button
								onClick={bookUseUserBalance}
								style={{ marginTop: '1.3rem', width: '50%', background: 'orange', color: 'white', textAlign: 'center' }}>
								Book using balance <RightOutlined />
							</Button>
							<Button
								onClick={() => {
									setLoading(true);
									handleAddItem();
								}}
								style={{ marginTop: '1.3rem', width: '50%', background: 'orange', color: 'white', textAlign: 'center' }}>
								Add to cart {!loading ? <ShoppingCartOutlined /> : <LoadingOutlined />}
							</Button>
						</div>
					) : (
						<div style={{ width: '100%' }}>
							<Button
								onClick={
									amountWillPaidUseUserBalance
										? async () => {
											await handlePaidPartofBooking();
											processPayment();
										}
										: processPayment
								}
								style={{ marginTop: '1.3rem', width: '50%', background: 'orange', color: 'white', textAlign: 'center' }}
								loading={payPartLoadig}>
								Next <RightOutlined />
							</Button>
							<Button
								onClick={() => {
									setLoading(true);
									handleAddItem();
									setGlobalCartVoucherCode(code);
									localStorage.setItem('code', code);
								}}
								style={{ marginTop: '1.3rem', width: '50%', background: 'orange', color: 'white', textAlign: 'center' }}>
								Add to cart {!loading ? <ShoppingCartOutlined /> : <LoadingOutlined />}
							</Button>
						</div>
					)}{' '}
				</Fragment>
			) : (
				<Result
					status="success"
					title="Successfully booking tutor"
					extra={[
						<Button type="primary" key="console">
							Go to booking history
						</Button>
					]}
				/>
			)}
			<Modal
				title="Success"
				footer={[
					<Button key="back" onClick={() => setSuccess(false)} style={{ backgroundColor: 'orange', color: 'white' }}>
						Ok
					</Button>
				]}
				visible={success}>
				<StyledModal>
					<CheckCircleOutlined style={{ color: 'green', fontSize: 80, marginBottom: '20px' }} />
					<h4>Item added to cart successfully</h4>
				</StyledModal>
			</Modal>
		</CustomSection>
	);
};

export default Payments;

const CustomGeneratePaymentURL = styled(GeneratePaymentURL)`
	.share button {
		width: 100%;
	}

	.share_note {
		width: 100%;
		margin-bottom: 1rem;
	}

	.share {
		flex-direction: column;
		width: 100%;
		height: 100%;
		padding: 0 2rem;
		margin-top: 2rem;
	}

	.share button {
		margin: 1rem;
	}
`;
const CustomSection = styled(Section)`
	.pricePara {
		text-align: center;
		font-size: 1rem;
	}

	.processPara {
		margin-bottom: 0px;
		text-align: center;
	}

	.notePara {
		color: #a29999;
	}
`;

const StyledModal = styled.div`
	width: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
`;
