import React from "react"
import { List as Listy, Avatar, Typography, Rate, Skeleton } from "antd"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { boxShadow } from "../styles/base"
import { country } from "App"

const StyledList = styled(Listy)`
	.ant-list-item {
		padding: 12px;
		margin-bottom: 1.5em;
		background: #fff;
		border-radius: 8px;
		box-shadow: ${boxShadow[0]};
		transition: all 0.2s ease;
		&:hover {
			box-shadow: ${({ nohover }) => (nohover ? "none" : boxShadow[2])};
			transform: ${({ nohover }) => (nohover ? "none" : "translateY(-2px)")};
		}
	}
	.ant-list-item-meta-description {
		p {
			margin-bottom: 0;
		}
		span {
			color: #999;
		}
	}
	.ant-rate {
		margin-bottom: 1em;
	}
`

function List(props) {
	const { skeleton = {}, ...rest } = props

	const { loading = false } = skeleton

	return (
		<StyledList
			{...rest}
			dataSource={props.data || []}
			renderItem={item => {
				if (props.type === "review") {
					return (
						<Listy.Item actions={[item.date]} extra={item.extra || ""} key={item.id}>
							<Skeleton {...skeleton} loading={loading}>
								<Listy.Item.Meta
									avatar={<Avatar size={64} src={item.src || ""} />}
									title={item.title || ""}
									description={
										<div>
											{item.rating && (
												<Rate
													disabled
													defaultValue={item.rating}
													style={{ fontSize: ".9em" }}
												/>
											)}
											<Typography.Paragraph>{item.description}</Typography.Paragraph>
										</div>
									}
								/>
							</Skeleton>
						</Listy.Item>
					)
				}

				return (
					<Link key={item.id} to={`/${country}item.to` || `/${country}`}>
						<Listy.Item {...props}>
							<Skeleton {...skeleton} loading={loading}>
								<Listy.Item.Meta
									avatar={item.src ? <Avatar src={item.src || ""} /> : item.id}
									title={item.title || ""}
									description={
										<p>
											<span>{item.description || ""}</span> &middot;{" "}
											<span>{item.duration || ""}</span>
										</p>
									}
								/>
							</Skeleton>
						</Listy.Item>
					</Link>
				)
			}}
		/>
	)
}

export default List
