import React from "react"
import styled from "styled-components"
import Card from "./Card"
import { EyeOutlined, TeamOutlined } from '@ant-design/icons';
import { Avatar, Skeleton, Card as Cardee } from "antd";
import Tooltip from "./Tooltip"
import { Link } from "react-router-dom"

const parStyle = { rows: 1, width: "100%" }

const StyledCard = styled(Card)`
    && {
        height: auto;
        text-align: initial;
        border-radius: 8px;
        margin-bottom: 2em;
        .ant-card-meta-title {
            color: #777;
        }
        .ant-card-meta-detail {
            margin-bottom: 0;
        }
        .ant-card-actions {
            border-radius: 0 0 8px 8px;
        }
        .ant-card-cover {
            height: 200px;
            border-radius: 8px 8px 0 0;
            img {
                height: 100%;
                object-fit: cover;
                border-radius: 8px 8px 0 0;
            }
        }
        .ant-card-meta-description {
            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
`

const StyledSkeleton = styled(Skeleton)`
    margin-bottom: 1.5em;
`

function CourseCard(props) {
    const { title, description, skeleton = {}, ...rest } = props

    const { loading = false } = skeleton

    const actions = [
        <Tooltip title="Class capacity">
            <TeamOutlined /> {props.capacity}
        </Tooltip>,
        <Tooltip title="Views">
            <EyeOutlined /> 239
        </Tooltip>,
        <Tooltip title="Price">{props.price}</Tooltip>
    ]

    const actionsSkeleton = [
        <Skeleton active title={false} paragraph={parStyle}>
            <TeamOutlined /> {props.capacity}
        </Skeleton>,
        <Skeleton active title={false} paragraph={parStyle}>
            <EyeOutlined /> 239
        </Skeleton>,
        <Skeleton active title={false} paragraph={parStyle}>
            {props.price}
        </Skeleton>
    ]

    return (
        <StyledCard {...rest} actions={skeleton && skeleton.loading ? actionsSkeleton : actions}>
            <StyledSkeleton
                {...skeleton}
                avatar={false}
                title={false}
                paragraph={{ rows: 2 }}
                loading={loading}
            >
                <Cardee.Meta
                    title={title}
                    description={description}
                    style={{ marginBottom: "1.5em" }}
                />
            </StyledSkeleton>
            <Skeleton
                {...skeleton}
                loading={skeleton.loading || false}
                avatar
                title={false}
                paragraph={parStyle}
            >
                <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />{" "}
                &nbsp;
                <strong>
                    <Link to="/tutor/profile">{props.tutor}</Link>
                </strong>
            </Skeleton>
        </StyledCard>
    )
}

export default CourseCard
