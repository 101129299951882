import React from 'react';
import { Result, Row, Col } from 'antd';
import {HomeOutlined} from "@ant-design/icons"
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import { Trans } from '@lingui/macro';
import { country } from 'App';

const StyledResult = styled(Result)`
	.ant-result-subtitle {
		width: 80%;
		margin: 0 auto;
	}
`;

function ConditionalPage({ status = '500', ...props }) {
	if (status === '500') {
		return (
			<Row type="flex" justify="center">
				<Col lg={12}>
					<StyledResult
						{...props}
						status="500"
						title={<Trans>Something Went Wrong</Trans>}
						subTitle={
							<Trans>
								We are working on fixing the problem
								<br />
								Please try again later
							</Trans>
						}
						extra={
							<Link to={`/${country}`}>
								<Button type="primary" icon={<HomeOutlined />}>
									&nbsp; <Trans>Back home</Trans>
								</Button>
							</Link>
						}
					/>
				</Col>
			</Row>
		);
	}
	if (status === '502') {
		return (
			<Row type="flex" justify="center">
				<Col lg={12}>
					<StyledResult
						{...props}
						status="500"
						title={<Trans>Maintenance</Trans>}
						subTitle={<Trans>Currently we are under maintenance. We'll get back to you very soon. Thanks! :)</Trans>}
					/>
				</Col>
			</Row>
		);
	}

	if (status === '404') {
		return (
			<Row type="flex" justify="center">
				<Col lg={12}>
					<StyledResult
						{...props}
						status="404"
						title="404"
						subTitle={<Trans>You're playing too far. It's time to turn around and go back home.</Trans>}
						extra={
							<Link to={`/${country}`}>
								<Button type="primary" icon={<HomeOutlined/>}>
									&nbsp; <Trans>Back home</Trans>
								</Button>
							</Link>
						}
					/>
				</Col>
			</Row>
		);
	}
}

export default ConditionalPage;
