export const countries = {
	australia: { name: 'Australia', code: 'AU$ ' },
	austria: { name: 'Austria', code: 'EUR ' },
	argentina: { name: 'Argentina', code: 'ARS ' },
	andora: { name: 'Andora', code: 'EUR ' },
	afghanistan: { name: 'Afghanistan', code: 'AFN ' },
	angola: { name: 'Angola', code: 'AOA ' },
	'antigua-and-barbuda': { name: 'Antigua and Barbuda', code: 'XCD ' },
	armenia: { name: 'Armenia', code: 'AMD ' },
	azerbaijan: { name: 'Azerbaijan', code: 'AZN ' },
	algeria: { name: 'Algeria', code: 'DZD ' },
	albania: { name: 'Albania', code: 'ALL ' },
	aruba: { name: 'Aruba', code: 'AWG ' },
	abkhazia: { name: 'Abkhazia', code: 'RUB ' },
	belgium: { name: 'Belgium', code: 'EUR ' },
	brazil: { name: 'Brazil', code: 'BRL ' },
	bulgaria: { name: 'Bulgaria', code: 'BGN ' },
	bangladesh: { name: 'Bangladesh', code: 'BDT ' },
	bahrain: { name: 'Bahrain', code: 'BHD ' },
	barbados: { name: 'Barbados', code: 'BBD ' },
	botswana: { name: 'Botswana', code: 'BWP ' },
	brunei: { name: 'Brunei', code: 'BND ' },
	burma: { name: 'Burma', code: 'MMK ' },
	bahamas: { name: 'Bahamas', code: 'BSD ' },
	bermuda: { name: 'Bermuda', code: 'BMD ' },
	'bosnia-and-herzegovina': { name: 'Bosnia and Herzegovina', code: 'BAM ' },
	belize: { name: 'Belize', code: 'BZD ' },
	belarus: { name: 'Belarus', code: 'BYR ' },
	bolivia: { name: 'Bolivia', code: 'BOB ' },
	benin: { name: 'Benin', code: 'XOF ' },
	bhutan: { name: 'Bhutan', code: ' 13,' },
	canada: { name: 'Canada', code: 'CA$ ' },
	china: { name: 'China', code: 'CNY ' },
	'czech-republic': { name: 'Czech Republic', code: 'CZK ' },
	cyprus: { name: 'Cyprus', code: 'EUR ' },
	cambodia: { name: 'Cambodia', code: 'KHR ' },
	croatia: { name: 'Croatia', code: 'HRK ' },
	colombia: { name: 'Colombia', code: 'COP ' },
	'costa-rica': { name: 'Costa Rica', code: 'CRC ' },
	chile: { name: 'Chile', code: 'CLP ' },
	'cayman-islands': { name: 'Cayman Islands', code: ' 69,' },
	cameroon: { name: 'Cameroon', code: 'XAF ' },
	curaçao: { name: 'Curaçao', code: 'ANG ' },
	'congo-democratic-republic': { name: 'Congo Democratic Republic', code: 'CDF ' },
	'côte-d-ivoire': { name: 'Côte d Ivoire', code: 'XOF ' },
	cuba: { name: 'Cuba', code: 'CUP ' },
	denmark: { name: 'Denmark', code: 'DKK ' },
	'dominican-republic': { name: 'Dominican Republic', code: 'DOP ' },
	dominica: { name: 'Dominica', code: 'XCD ' },
	djibouti: { name: 'Djibouti', code: 'DJF ' },
	egypt: { name: 'Egypt', code: 'EGP ' },
	ethiopia: { name: 'Ethiopia', code: 'ETB ' },
	estonia: { name: 'Estonia', code: 'EUR ' },
	ecuador: { name: 'Ecuador', code: 'US$ ' },
	'el-salvador': { name: 'El Salvador', code: 'US$ ' },
	'east-timor': { name: 'East Timor', code: 'US$ ' },
	'equatorial-guinea': { name: 'Equatorial Guinea', code: 'XAF ' },
	france: { name: 'France', code: 'EUR ' },
	finland: { name: 'Finland', code: 'EUR ' },
	fiji: { name: 'Fiji', code: 'FJD ' },
	'faroe-islands': { name: 'Faroe Islands', code: 'DKK ' },
	germany: { name: 'Germany', code: 'EUR ' },
	ghana: { name: 'Ghana', code: 'GHS ' },
	greece: { name: 'Greece', code: 'EUR ' },
	guyana: { name: 'Guyana', code: 'GYD ' },
	guernsey: { name: 'Guernsey', code: 'GBP ' },
	georgia: { name: 'Georgia', code: 'GEL ' },
	guatemala: { name: 'Guatemala', code: 'GTQ ' },
	grenada: { name: 'Grenada', code: 'XCD ' },
	gabon: { name: 'Gabon', code: 'XAF ' },
	gambia: { name: 'Gambia', code: 'GMD ' },
	'hong-kong': { name: 'Hong Kong', code: 'HKD ' },
	hungary: { name: 'Hungary', code: 'HUF ' },
	haiti: { name: 'Haiti', code: 'HTG ' },
	honduras: { name: 'Honduras', code: 'HNL ' },
	india: { name: 'India', code: 'INR ' },
	indonesia: { name: 'Indonesia', code: 'IDR ' },
	ireland: { name: 'Ireland', code: 'EUR ' },
	italy: { name: 'Italy', code: 'EUR ' },
	israel: { name: 'Israel', code: 'ILS ' },
	iran: { name: 'Iran', code: 'IRR ' },
	iraq: { name: 'Iraq', code: 'IQD ' },
	iceland: { name: 'Iceland', code: 'ISK ' },
	'isle-of-man': { name: 'Isle of Man', code: 'GBP ' },
	jamaica: { name: 'Jamaica', code: 'JMD ' },
	japan: { name: 'Japan', code: 'JPY ' },
	jordan: { name: 'Jordan', code: 'JOD ' },
	jersey: { name: 'Jersey', code: 'GBP ' },
	kenya: { name: 'Kenya', code: 'KES ' },
	kuwait: { name: 'Kuwait', code: 'KWD ' },
	kazakhstan: { name: 'Kazakhstan', code: 'KZT ' },
	kosovo: { name: 'Kosovo', code: 'EUR ' },
	kyrgyzstan: { name: 'Kyrgyzstan', code: 'KGS ' },
	luxembourg: { name: 'Luxembourg', code: 'EUR ' },
	lebanon: { name: 'Lebanon', code: 'LBP ' },
	lithuania: { name: 'Lithuania', code: 'EUR ' },
	latvia: { name: 'Latvia', code: 'LVL ' },
	laos: { name: 'Laos', code: 'LAK ' },
	libya: { name: 'Libya', code: 'LYD ' },
	liechtenstein: { name: 'Liechtenstein', code: 'CHF ' },
	lesotho: { name: 'Lesotho', code: 'LSL ' },
	malaysia: { name: 'Malaysia', code: 'MYR ' },
	mauritius: { name: 'Mauritius', code: 'MUR ' },
	mexico: { name: 'Mexico', code: 'MXN ' },
	macau: { name: 'Macau', code: 'MOP ' },
	malta: { name: 'Malta', code: 'EUR ' },
	maldives: { name: 'Maldives', code: 'MVR ' },
	morocco: { name: 'Morocco', code: 'MAD ' },
	macedonia: { name: 'Macedonia', code: 'MKD ' },
	mozambique: { name: 'Mozambique', code: 'MZN ' },
	malawi: { name: 'Malawi', code: 'MWK ' },
	mongolia: { name: 'Mongolia', code: 'MNT ' },
	moldova: { name: 'Moldova', code: 'MDL ' },
	monaco: { name: 'Monaco', code: 'EUR ' },
	montenegro: { name: 'Montenegro', code: 'EUR ' },
	madagascar: { name: 'Madagascar', code: 'MGA ' },
	'new-zealand': { name: 'New Zealand', code: 'NZ$ ' },
	netherlands: { name: 'Netherlands', code: 'EUR ' },
	norway: { name: 'Norway', code: 'NOK ' },
	namibia: { name: 'Namibia', code: 'NAD ' },
	nepal: { name: 'Nepal', code: 'NPR ' },
	niger: { name: 'Niger', code: 'XOF ' },
	nicaragua: { name: 'Nicaragua', code: 'NIO ' },
	'new-caledonia': { name: 'New Caledonia', code: 'XPF ' },
	'north-korea': { name: 'North Korea', code: 'KPW ' },
	'northern-mariana-islands': { name: 'Northern Mariana Islands', code: 'US$ ' },
	oman: { name: 'Oman', code: 'OMR ' },
	philippines: { name: 'Philippines', code: 'PHP ' },
	poland: { name: 'Poland', code: 'PLN ' },
	pakistan: { name: 'Pakistan', code: 'PKR ' },
	portugal: { name: 'Portugal', code: 'EUR ' },
	'papua-new-guinea': { name: 'Papua New Guinea', code: 'PGK ' },
	panama: { name: 'Panama', code: 'US$ ' },
	peru: { name: 'Peru', code: 'PEN ' },
	palestine: { name: 'Palestine', code: 'ILS ' },
	paraguay: { name: 'Paraguay', code: 'PYG ' },
	qatar: { name: 'Qatar', code: 'QAR ' },
	romania: { name: 'Romania', code: 'RON ' },
	russia: { name: 'Russia', code: 'RUB ' },
	rwanda: { name: 'Rwanda', code: 'RWF ' },
	'south-africa': { name: 'South Africa', code: 'ZAR ' },
	singapore: { name: 'Singapore', code: 'SGD ' },
	sweden: { name: 'Sweden', code: 'SEK ' },
	'sri-lanka': { name: 'Sri Lanka', code: 'LKR ' },
	'saudi-arabia': { name: 'Saudi Arabia', code: 'SAR ' },
	switzerland: { name: 'Switzerland', code: 'CHF ' },
	spain: { name: 'Spain', code: 'EUR ' },
	'south-korea': { name: 'South Korea', code: 'KRW ' },
	serbia: { name: 'Serbia', code: 'RSD ' },
	slovakia: { name: 'Slovakia', code: 'EUR ' },
	slovenia: { name: 'Slovenia', code: 'EUR ' },
	seychelles: { name: 'Seychelles', code: 'SCR ' },
	'saint-lucia': { name: 'Saint Lucia', code: 'XCD ' },
	sudan: { name: 'Sudan', code: 'SDG ' },
	syria: { name: 'Syria', code: 'SYP ' },
	suriname: { name: 'Suriname', code: 'SRD ' },
	'saint-kitts-and-nevis': { name: 'Saint Kitts and Nevis', code: 'XCD ' },
	'sint-maarten': { name: 'Sint Maarten', code: 'ANG ' },
	'saint-vincent-and-the-gr-': { name: 'Saint Vincent and the Gr.', code: 'XCD ' },
	senegal: { name: 'Senegal', code: 'XOF ' },
	'sierra-leone': { name: 'Sierra Leone', code: 'SLL ' },
	samoa: { name: 'Samoa', code: 'WST ' },
	turkey: { name: 'Turkey', code: 'TRY ' },
	thailand: { name: 'Thailand', code: 'THB ' },
	'trinidad-and-tobago': { name: 'Trinidad and Tobago', code: 'TTD ' },
	taiwan: { name: 'Taiwan', code: 'TWD ' },
	tanzania: { name: 'Tanzania', code: 'TZS ' },
	tunisia: { name: 'Tunisia', code: 'TND ' },
	'turks-and-caicos-islands': { name: 'Turks and Caicos Islands', code: 'US$ ' },
	tonga: { name: 'Tonga', code: 'TOP ' },
	turkmenistan: { name: 'Turkmenistan', code: 'TMT ' },
	tajikistan: { name: 'Tajikistan', code: 'TJS ' },
	'united-states': { name: 'United States', code: 'US$ ' },
	'united-kingdom': { name: 'United Kingdom', code: 'GBP ' },
	'united-arab-emirates': { name: 'United Arab Emirates', code: 'AED ' },
	ukraine: { name: 'Ukraine', code: 'UAH ' },
	uganda: { name: 'Uganda', code: 'UGX ' },
	uzbekistan: { name: 'Uzbekistan', code: 'UZS ' },
	uruguay: { name: 'Uruguay', code: 'UYU ' },
	vietnam: { name: 'Vietnam', code: 'VND ' },
	venezuela: { name: 'Venezuela', code: 'VEF ' },
	vanuatu: { name: 'Vanuatu', code: 'VUV ' },
	yemen: { name: 'Yemen', code: 'YER ' },
	zimbabwe: { name: 'Zimbabwe', code: 'US$ ' },
	zambia: { name: 'Zambia', code: 'ZMK ' }
};

export const countriesList = [
	'australia',
	'austria',
	'argentina',
	'andora',
	'afghanistan',
	'angola',
	'antigua-and-barbuda',
	'armenia',
	'azerbaijan',
	'algeria',
	'albania',
	'aruba',
	'abkhazia',
	'belgium',
	'brazil',
	'bulgaria',
	'bangladesh',
	'bahrain',
	'barbados',
	'botswana',
	'brunei',
	'burma',
	'bahamas',
	'bermuda',
	'bosnia-and-herzegovina',
	'belize',
	'belarus',
	'bolivia',
	'benin',
	'bhutan',
	'canada',
	'china',
	'czech-republic',
	'cyprus',
	'cambodia',
	'croatia',
	'colombia',
	'costa-rica',
	'chile',
	'cayman-islands',
	'cameroon',
	'curaçao',
	'congo-democratic-republic',
	'côte-d-ivoire',
	'cayman-islands',
	'cuba',
	'denmark',
	'dominican-republic',
	'dominica',
	'djibouti',
	'egypt',
	'ethiopia',
	'estonia',
	'ecuador',
	'el-salvador',
	'east-timor',
	'equatorial-guinea',
	'france',
	'finland',
	'fiji',
	'faroe-islands',
	'germany',
	'ghana',
	'greece',
	'guyana',
	'guernsey',
	'georgia',
	'guatemala',
	'grenada',
	'gabon',
	'gambia',
	'hong-kong',
	'hungary',
	'haiti',
	'honduras',
	'india',
	'indonesia',
	'ireland',
	'italy',
	'israel',
	'iran',
	'iraq',
	'iceland',
	'isle-of-man',
	'jamaica',
	'japan',
	'jordan',
	'jersey',
	'kenya',
	'kuwait',
	'kazakhstan',
	'kosovo',
	'kyrgyzstan',
	'luxembourg',
	'lebanon',
	'lithuania',
	'latvia',
	'laos',
	'libya',
	'liechtenstein',
	'lesotho',
	'malaysia',
	'mauritius',
	'mexico',
	'macau',
	'malta',
	'maldives',
	'morocco',
	'macedonia',
	'mozambique',
	'malawi',
	'mongolia',
	'moldova',
	'monaco',
	'montenegro',
	'madagascar',
	'new-zealand',
	'netherlands',
	'norway',
	'namibia',
	'nepal',
	'niger',
	'nicaragua',
	'new-caledonia',
	'north-korea',
	'northern-mariana-islands',
	'oman',
	'philippines',
	'poland',
	'pakistan',
	'portugal',
	'papua-new-guinea',
	'panama',
	'peru',
	'palestine',
	'paraguay',
	'qatar',
	'romania',
	'russia',
	'rwanda',
	'south-africa',
	'singapore',
	'sweden',
	'sri-lanka',
	'saudi-arabia',
	'switzerland',
	'spain',
	'south-korea',
	'serbia',
	'slovakia',
	'slovenia',
	'seychelles',
	'saint-lucia',
	'sudan',
	'syria',
	'suriname',
	'saint-kitts-and-nevis',
	'sint-maarten',
	'saint-vincent-and-the-gr-',
	'senegal',
	'sierra-leone',
	'samoa',
	'turkey',
	'thailand',
	'trinidad-and-tobago',
	'taiwan',
	'tanzania',
	'tunisia',
	'turks-and-caicos-islands',
	'tonga',
	'turkmenistan',
	'tajikistan',
	'united-states',
	'united-kingdom',
	'united-arab-emirates',
	'ukraine',
	'uganda',
	'uzbekistan',
	'uruguay',
	'vietnam',
	'venezuela',
	'vanuatu',
	'yemen',
	'zimbabwe',
	'zambia'
];
