import { gql } from 'apollo-boost';

export const ALL = gql`
	query GET_ALL($role: String!, $status: String) {
		getBookings(role: $role, status: $status) {
			pvid
			startTime
			endTime
			location
			status
			notes
			classUrl
			statusAfterPayment
			isTutorWithdrawMoney
			amountPaidUsingUserBalance
			price
			duration
			student {
				pvid
				userPvid
				email
				firstName
				lastName
			}
			tutor {
				pvid
				userPvid
				email
				firstName
				lastName
			}
			subjectInfo {
				pvid
				subject
				level
			}
		}
	}
`;

export const ALL_PUBLIC = gql`
	query ALL_PUBLIC($id: Int, $role: String, $status: String) {
		getBookingsPublic(id: $id, role: $role, status: $status) {
			pvid
			startTime
			endTime
			location
			status
			notes
			amountPaidUsingUserBalance
			student {
				pvid
				email
				firstName
				lastName
			}
			tutor {
				pvid
				email
				firstName
				lastName
			}
			subjectInfo {
				pvid
				subject
				level
			}
		}
	}
`;

export const ONE = gql`
	query ONE($id: Int!) {
		getBooking(id: $id) {
			pvid
			startTime
			endTime
			location
			status
			notes
			student {
				pvid
				userPvid
				email
				firstName
				lastName
				country
				phone
				address
			}
			tutor {
				pvid
				userPvid
				email
				firstName
				lastName
				country
				phone
				address
			}
		}
	}
`;

export const ADD = gql`
	mutation ADD_BOOKING(
		$tutorPvid: String!
		$subjectPvid: String!
		$startTime: String!
		$endTime: String!
		$type: String!
		$location: String!
		$status: String!
		$notes: String
		$duration: Float!
	) {
		addBooking(
			tutorPvid: $tutorPvid
			subjectPvid: $subjectPvid
			startTime: $startTime
			endTime: $endTime
			type: $type
			location: $location
			status: $status
			notes: $notes
			duration: $duration
		) {
			booking {
				pvid
				startTime
				endTime
				location
				status
				notes
				price
				duration
				student {
					pvid
					email
					userPvid
					firstName
					lastName
					country
					phone
					address
				}
				tutor {
					pvid
					email
					userPvid
					firstName
					lastName
					country
					phone
					address
				}
			}
			isThisFirstBooking
			bookingPrice
			currency
			hasEnoughBalance
			firstBookingMsgData{
				numberOfLessons
				tutorName
				remainsAmountAfterFirstBooking
			}
			amountWillPaidUsePaymentsMethods
			amountWillPaidUseUserBalance
		}
	}
`;

export const UPDATE = gql`
	mutation UPDATE(
		$id: Int!
		$startTime: String
		$endTime: String
		$location: String
		$status: String
		$notes: String
		$paymentId: String
		$paymentName: String
		$voucherCode: String
	) {
		updateBooking(
			id: $id
			startTime: $startTime
			endTime: $endTime
			location: $location
			status: $status
			notes: $notes
			paymentId: $paymentId
			paymentName: $paymentName
			voucherCode: $voucherCode
		) {
			pvid
			price
		}
	}
`;
export const PAYMNET_SUCCESS_BOOKING = gql`
	mutation PaymentSuccessBooking($bookingId: String!) {
		sendNotificationSuccessBooking(bookingId: $bookingId)
	}
`;

export const CANCEL_BOOKING = gql`
	mutation CANCEL_BOOKING($bookingId: String!) {
		cancelBooking(bookingId: $bookingId)
	}
`;
export const DONE_BOOKING = gql`
	mutation DONE_BOOKING($bookingId: String!) {
		bookingDone(bookingId: $bookingId)
	}
`;

export const TRANSFER_MONEY_TO_BANK_ACCOUNT = gql`
	mutation TRANSFER_MONRY_TO_BANK_ACCOUNT($bookingId: String!) {
		tutorTransfareBookingMoneyToHisAccount(bookingId: $bookingId)
	}
`;

export const GET_BOOKING_SHARED_URL_DETAILS = gql`
	mutation BOOKING_SHARED_URL_DETAILS($id: String!){
		getBookingDetailsForSharedURL(id: $id){
			product_name
			product_end_date
			product_start_date
		}
	}
`


export const PAY_PART_OF_BOOKING_USING_USER_BALANCE = gql`
	mutation PAID_PART_OF_BOOKING_USING_USER_BALANCE($bookingId: String!){
		paidPartOfBookingPriceUseUserBalance(bookingId: $bookingId)
	}
`

export const CANCEL_BOOKING_BEFORE_PAID = gql`
	mutation HANDLE_CANCEL_BOOKING_BEFORE_PAID($bookingId: String!){
		cancelBookingBeforePaid(bookingId: $bookingId)
	}
`


export const GET_TUTOR_HIS_BOOKINGS_FOR_CALENDAR = gql`
	mutation GET_TUTOR_HIS_BOOKINGS_FOR_CALENDAR{
		getTutorHisBookingsForCalendar{
			id
			startTime
			endTime
			location
			status
			statusAfterPayment
			price
			title
			description
			studentName
  		}
	}
`


export const GET_USER_TUTOR_BOOKINGS_FOR_CALENDAR = gql`
	mutation GET_USER_TUTOR_BOOKINGS_FOR_CALENDAR($tutorId: String!){
		getUserTutorBookingsForCalendar(tutorId: $tutorId){
			id
			startTime
			endTime
			title
			description
  		}
	}
`