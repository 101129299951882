import React, { useState } from "react"
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Comment, Tooltip, Avatar } from "antd";
import { boxShadow } from "../styles/base"
import moment from "moment"
import styled from "styled-components"

const StyledComment = styled(Comment)`
	&& {
		margin-bottom: 1.5em;
		background-color: #fff;
		border-radius: 8px;
		box-shadow: ${boxShadow[0]};
		transition: all 0.2s ease;

		.ant-comment-inner {
			padding: 16px;
		}

		.ant-comment-actions {
			margin-bottom: 0;
		}

		&:hover {
			transform: translateY(-2px);
			box-shadow: ${boxShadow[1]};
		}
	}
`

function CommentItem({ type, ...props }) {
	const [action, setAction] = useState("liked")
	const [likes, setLikes] = useState(0)
	const [dislikes, setDislikes] = useState(0)

	const actions = [
		<span>
			<Tooltip title="Like">
				<LegacyIcon type="like" theme={action === "liked" ? "filled" : "outlined"} onClick={props.onLike} />
			</Tooltip>
			<span style={{ paddingLeft: 8, cursor: "auto" }}>{likes}</span>
		</span>,
		<span>
			<Tooltip title="Dislike">
				<LegacyIcon type="dislike" theme={action === "disliked" ? "filled" : "outlined"} onClick={props.onDislike} />
			</Tooltip>
			<span style={{ paddingLeft: 8, cursor: "auto" }}>{dislikes}</span>
		</span>
		// <Button type="primary" size="small" icon={<CheckOutlined/>} style={{ marginRight: "1em" }}>
		//     Mark as read
		// </Button>
		// <span>
		//     <Icon type="close" />
		//     &nbsp; Remove{" "}
		// </span>
	]

	return (
		<StyledComment
			{...props}
			actions={type === "notification" ? actions.slice(2) : actions.slice(0, 2)}
			author={<a>{props.author}</a>}
			avatar={<Avatar src={props.photoUrl} />}
			content={props.content}
			datetime={<span>{moment(props.datetime).fromNow()}</span>}
		/>
	)
}

export default CommentItem
