import React from "react"
import { Modal as Pop } from "antd"
import styled from "styled-components"

const StyledModal = styled(Pop)`
	border-radius: 10px;
	transition: all 0.3s ease;
	.ant-modal-content {
		border-radius: 10px;
		.ant-modal-header {
			border-radius: 10px 10px 0 0;
		}
	}
`

function Modal({ children, ...props }) {
	return (
		<StyledModal centered {...props}>
			{children}
		</StyledModal>
	)
}

export default Modal
