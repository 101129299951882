import React from "react"
import { Rate } from "antd"
import styled from "styled-components"

const StyledRate = styled(Rate)`
    && {
        color: ${({ color }) => (color === "primary" ? "#FF9D00" : color)};
        font-size: ${({ size }) => (size === "small" ? "14px" : "inherit")};
        .ant-rate-star-zero {
            .ant-rate-star-first,
            .ant-rate-star-second {
                color: #bbb;
            }
        }
        .ant-rate-star-half {
            .ant-rate-star-second {
                color: #bbb;
            }
        }
    }
`

function Rating(props) {
    return <StyledRate {...props} />
}

export default Rating
