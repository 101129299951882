import React from "react"

import {
    ApiOutlined,
    AppstoreOutlined,
    CloudDownloadOutlined,
    CloudOutlined,
    DollarOutlined,
    DotChartOutlined,
    FireOutlined,
    FundOutlined,
    MessageOutlined,
    TeamOutlined,
    ThunderboltOutlined,
    UserOutlined,
} from '@ant-design/icons';

import { Menu, Divider } from "antd";
import styled from "styled-components"
import { Trans } from "@lingui/macro"
import { country } from "App"

const StyledMenu = styled(Menu)`
    height: 100%;
    li {
        .anticon {
            color: #ff9d00;
        }
        &.ant-menu-item {
            padding: 0 25px;
        }
        .ant-menu-submenu-title {
            color: #ff9d00;
            padding: 0 25px;
        }
    }
`

function SidebarMenu(props) {
    const handleClick = ({ key }) => {
        props.history.push(`/${country}/dashboard/${key}`)
    }

    return (
        <StyledMenu theme="light" mode="vertical" onClick={handleClick}>
            <Menu.Item key="main">
                <Trans><AppstoreOutlined /> &nbsp;
                <span>Dashboard</span></Trans>
            </Menu.Item>
            <Menu.Item key="profile">
                <Trans><UserOutlined /> &nbsp;
                <span>View profile</span></Trans>
            </Menu.Item>
            <Menu.Item key="classes/manage">
                <Trans><FundOutlined /> &nbsp;
                <span>Manage classes</span></Trans>
            </Menu.Item>
            <Menu.Item key="classes/create">
                <Trans><ThunderboltOutlined /> &nbsp;
                <span>Create class</span></Trans>
            </Menu.Item>
            <Menu.Item key="reviews">
                <Trans><MessageOutlined /> &nbsp;
                <span>Reviews</span></Trans>
            </Menu.Item>
            <Menu.Item key="resources">
                <Trans><CloudDownloadOutlined /> &nbsp;
                <span>Resources</span></Trans>
            </Menu.Item>
            <Menu.Item key="analytics">
                <Trans><DotChartOutlined /> &nbsp;
                <span>Analytics</span></Trans>
            </Menu.Item>
            <Menu.Item key="payout">
                <Trans><DollarOutlined /> &nbsp;
                <span>Payout</span></Trans>
            </Menu.Item>
            <Menu.Item key="referral">
                <Trans><TeamOutlined /> &nbsp;
                <span>Referral</span></Trans>
            </Menu.Item>
            <Menu.Item key="feedback">
                <Trans><ApiOutlined /> &nbsp;
                <span>Feedback</span></Trans>
            </Menu.Item>
            <Divider />
            <Menu.Item key="storage">
                <Trans><CloudOutlined /> &nbsp;
                <span>Cloud storage</span></Trans>
            </Menu.Item>
            <Menu.Item key="social_media">
                <Trans><FireOutlined /> &nbsp;
                <span>Social media rewards</span></Trans>
            </Menu.Item>
        </StyledMenu>
    );
}

export default SidebarMenu
