import { gql } from 'apollo-boost';

export const mutateBasicProfile = gql`
	mutation mutateBasicProfile($desc: String!, $address: String!) {
		updateBasicProfile(desc: $desc, address: $address)
	}
`;

export const mutateUpdateProfile = gql`
	mutation mutateUpdateProfile(
		$firstName: String
		$lastName: String
		$dob: Date
		$nric: String
		$phone: String
		$gender: String
		$race: String
		$address: String
		$description: String
		$eventsFromGoogle: String
		$balance: Float
		$hasBalance: Boolean
	) {
		updateProfile(
			firstName: $firstName
			lastName: $lastName
			dob: $dob
			nric: $nric
			phone: $phone
			gender: $gender
			race: $race
			address: $address
			description: $description
			eventsFromGoogle: $eventsFromGoogle
			balance: $balance
			hasBalance: $hasBalance
		) {
			pvid
			firstName
			lastName
			dateModified
			dob
			nric
			phone
			gender
			race
			description
			eventsFromGoogle
			address
		}
	}
`;

export const mutateUpdateCurriculum = gql`
	mutation mutateUpdateCurriculum($list: [InputProfileCurriculum]!) {
		updateProfileCurriculum(list: $list) {
			pvid
		}
	}
`;

export const mutateAddSubjects = gql`
	mutation mutateAddSubjects($list: [InputUserSubject]!) {
		updateSubject(list: $list) {
			pvid
		}
	}
`;

export const mutateEducations = gql`
	mutation mutateEducation($list: [InputEducation]!) {
		updateEducation(list: $list) {
			pvid
		}
	}
`;

export const mutateExperiences = gql`
	mutation mutateExperiences($list: [InputExperience]!) {
		updateExperience(list: $list) {
			fromDate
			toDate
			isPrivateTutor
			isPresent
			subject {
				name
				level {
					pvid
					curriculum
					institution
				}
			}
		}
	}
`;

export const QUERY_INTRO_VIDEO_HOME = gql`
	query QUERY_INTRO_VIDEO_HOME($limit: Int, $country: String!) {
		introVideoHome(limit: $limit, country: $country) {
			userPvid
			email
			firstName
			lastName
			token
			videoIntroductionList {
				pvid
				path
				fileName
				mimeType
				encoding
				eTag
				durationSecs
			}
		}
	}
`;

export const MUTATE_USER_PROFILE_HOME = gql`
	mutation MUTATE_USER_PROFILE_HOME {
		getProfile {
			# credit
			userPvid
			firstName
			lastName
			country
			avatar {
				path
				fileName
			}
		}
	}
`;

export const mutateUserProfile = gql`
	mutation mutateUserProfile {
		getProfile {
			userPvid
			userCode
			pvid
			firstName
			lastName
			initialName
			dateModified
			dateJoined
			dob
			nric
			phone
			gender
			race
			description
			address
			referralCode
			roleCode
			roleName
			eventsFromGoogle
			country
			eventsFromGoogle
			balance
			school
			counsellingSubjects {
				pvid
				userPvid
				orderList
				programSubjectCoachesPvid
				pricePerHour
				subject {
					pvid
					name
				}
			}
			skillList {
				title
				pvid
			}
			educationList {
				pvid
				fromDate
				toDate
				institution
				isPresent
			}
			expertiseList {
				title
				pvid
			}
			experienceList {
				subject {
					name
					level {
						pvid
						curriculum
						institution
						fromDate
						toDate
						isPrivateTutor
						isPresent
						type
					}
				}
			}
			experienceNonAcademicList {
				subject {
					name
					level {
						pvid
						curriculum
						institution
						fromDate
						toDate
						isPrivateTutor
						isPresent
						type
					}
				}
			}
			experienceCounsellingList {
				subject {
					name
					level {
						pvid
						curriculum
						institution
						fromDate
						toDate
						isPrivateTutor
						isPresent
						type
					}
				}
			}
			subjectList {
				orderList
				subject {
					pvid
					name
					level {
						pvid
						programmeSubjectPvid
						programmeLevelPvid
						name
						pricePerHour
					}
				}
			}
			certificateList {
				pvid
				issuer
				issuedDate
				name
				relativePath
			}
			otherPlatformList {
				pvid
				link
				otherPlatformPvid
				name
				icon
	
			}
			curriculumList {
				pvid
				name
				source {
					pvid
					name
				}
			}
			completionUser {
				pvid
				completionPercent
			}
			completionDetail {
				pvid
				profileSection
			}
			postingAssignment {
				pvid
				title
				createdAt
				acceptanceTutorList {
					userPvid
					firstName
					lastName
					isAccept
					resource {
						path
					}
				}
			}
			userColleagues {
				pvid
				name
				phone
				email
			}
			videoIntroductionList {
				pvid
				path
				fileName
				mimeType
				encoding
				eTag
				durationSecs
			}
			avatar {
				pvid
				path
				fileName
				mimeType
				encoding
				eTag
				resourceTypePvid
				durationSecs
			}
			banner {
				pvid
				path
				fileName
				mimeType
				encoding
				eTag
				durationSecs
			}
			nonAcademic {
				pvid
				name
				status
				subNonAcademic {
					profileNonAcademicPvid
					programmeSubjectSubNonAcademicPvid
					name
					status
					pricePerHour
					orderList
				}
			}
		}
	}
`;

export const MUTATE_USER_PROFILE_NAVBAR = gql`
	mutation mutateUserProfile {
		getProfile {
			userPvid
			balance
			pvid
			firstName
			lastName
			country
		}
	}
`;

//$filter: InputLogSearch

export const mutateUserProfileForReferral = gql`
	mutation mutateUserProfileForReferral($userPvid: Int!) {
		getProfile(userPvid: $userPvid) {
			balance
			userPvid
			firstName
			lastName
			country
			userCode
			pvid
			avatar {
				path
				fileName
				durationSecs
			}
		}
	}
`;

export const MUTATE_USER_BY_TOKEN = gql`
	mutation MUTATE_USER_BY_TOKEN($code: String!, $keywords: String, $subjects: [String]!, $curriculums: [String]!) {
		getProfile(
			code: $code
			filter: { keywords: $keywords, subjects: $subjects, curriculums: $curriculums }
			requestSource: { profileRequestSourceCode: "SRCH", param: "" }
		) {
			userCode
			pvid
			userPvid
			firstName
			lastName
			initialName
			dateModified
			dateJoined
			dob
			nric
			phone
			gender
			race
			description
			address
			referralCode
			roleCode
			roleName
			eventsFromGoogle
			country
			eventsFromGoogle
			balance
			school
			counsellingSubjects {
				pvid
				userPvid
				orderList
				programSubjectCoachesPvid
				pricePerHour
				subject {
					pvid
					name
				}
			}
			skillList {
				title
				pvid
			}
			educationList {
				pvid
				fromDate
				toDate
				institution
				isPresent
			}
			expertiseList {
				title
				pvid
			}
			experienceList {
				subject {
					name
					level {
						pvid
						curriculum
						institution
						fromDate
						toDate
						isPrivateTutor
						isPresent
						type
					}
				}
			}
			experienceNonAcademicList {
				subject {
					name
					level {
						pvid
						curriculum
						institution
						fromDate
						toDate
						isPrivateTutor
						isPresent
						type
					}
				}
			}
			experienceCounsellingList {
				subject {
					name
					level {
						pvid
						curriculum
						institution
						fromDate
						toDate
						isPrivateTutor
						isPresent
						type
					}
				}
			}
			educationList {
				pvid
				fromDate
				toDate
				institution
				isPresent
			}
			subjectList {
				orderList
				subject {
					pvid
					name
					level {
						pvid
						programmeSubjectPvid
						programmeLevelPvid
						name
						pricePerHour
					}
				}
			}
			certificateList {
				pvid
				issuer
				issuedDate
				name
				relativePath
			}
			otherPlatformList {
				pvid
				link
				otherPlatformPvid
				name
				icon
			}
			curriculumList {
				pvid
				source {
					pvid
					name
				}
			}
			completionUser {
				pvid
				completionPercent
			}
			completionDetail {
				pvid
				profileSection
			}
			postingAssignment {
				pvid
				title
				createdAt
				acceptanceTutorList {
					userPvid
					firstName
					lastName
					isAccept
					resource {
						path
					}
				}
			}
			userColleagues {
				pvid
				name
				phone
				email
			}
			videoIntroductionList {
				pvid
				path
				fileName
				mimeType
				encoding
				eTag
				durationSecs
			}
			avatar {
				pvid
				path
				fileName
				mimeType
				encoding
				eTag
				durationSecs
			}
			banner {
				pvid
				path
				fileName
				mimeType
				encoding
				eTag
				durationSecs
			}
			nonAcademic {
				pvid
				name
				status
				subNonAcademic {
					profileNonAcademicPvid
					programmeSubjectSubNonAcademicPvid
					name
					status
					pricePerHour
					orderList
				}
			}
		}
	}
`;

export const GET_USER_NAME = gql`
	mutation getProfile($userPvid: Int!) {
		getProfile(userPvid: $userPvid) {
			userPvid
			pvid
			firstName
			lastName
		}
	}
`;

export const mutateUserById = gql`
	mutation mutateUserById($userPvid: Int!) {
		getProfile(userPvid: $userPvid) {
			userCode
			userPvid
			pvid
			email
			firstName
			lastName
			dateModified
			dateJoined
			dob
			nric
			phone
			gender
			race
			description
			address
			referralCode
			roleCode
			eventsFromGoogle
			balance
			roleName
			eventsFromGoogle
			country
			school
			counsellingSubjects{
				pvid
				userPvid
				orderList 
				programSubjectCoachesPvid
				pricePerHour
				subject {
					pvid
					name
					country
				}
			}
			skillList {
				title
				pvid
			}
			expertiseList {
				title
				pvid
			}
			educationList {
				pvid
				fromDate
				toDate
				institution
				isPresent
			}
			experienceList {
				subject {
					name
					level {
						pvid
						curriculum
						institution
						fromDate
						toDate
						isPrivateTutor
						isPresent
					}
				}
			}
			subjectList {
				pvid
				orderList
				subject {
					pvid
					name
					level {
						pvid
						programmeSubjectPvid
						programmeLevelPvid
						name
						pricePerHour
					}
				}
			}
			certificateList {
				pvid
				issuer
				issuedDate
				name
				relativePath
			}
			otherPlatformList {
				pvid
				link
				otherPlatformPvid
				name
				icon
			}
			curriculumList {
				pvid
				source {
					pvid
					name
				}
			}
			completionUser {
				pvid
				completionPercent
			}
			completionDetail {
				pvid
				profileSection
			}
			postingAssignment {
				pvid
				title
				createdAt
				acceptanceTutorList {
					userPvid
					firstName
					lastName
					isAccept
					resource {
						path
					}
				}
			}
			userColleagues {
				pvid
				name
				phone
				email
			}
			videoIntroductionList {
				pvid
				path
				fileName
				mimeType
				encoding
				eTag
				durationSecs
			}
			avatar {
				pvid
				path
				fileName
				mimeType
				encoding
				eTag
				durationSecs
			}
			banner {
				pvid
				path
				fileName
				mimeType
				encoding
				eTag
				durationSecs
			}
			nonAcademic {
				pvid
				name
				status
				subNonAcademic {
					profileNonAcademicPvid
					programmeSubjectSubNonAcademicPvid
					name
					status
					pricePerHour
					orderList
				}
			}
		}
	}
`;

export const mutateUploadProfileBanner = gql`
	mutation mutateUploadProfileBanner($file: Upload!) {
		uploadProfileBanner(file: $file) {
			fileName
			path
		}
	}
`;

export const mutateUploadProfilePicture = gql`
	mutation mutateUploadProfilePicture($file: Upload!) {
		uploadAvatar(file: $file) {
			fileName
			path
		}
	}
`;

export const mutateUploadIntroVideo = gql`
	mutation mutateUploadIntroVideo($file: Upload!) {
		uploadVideoIntroduction(file: $file) {
			fileName
			path
		}
	}
`;

export const mutateFetchTestimonials = gql`
	mutation mutateFetchTestimonials($limit: Int) {
		getTestimonialList(limit: $limit) {
			comment
			postedDate
			rating
			reviewer {
				pvid
				firstName
				lastName
				resource {
					path
				}
			}
		}
	}
`;

export const MUTATE_TESTIMONIALS_BY_ID = gql`
	mutation MUTATE_TESTIMONIALS_BY_ID($userPvid: Int!, $limit: Int) {
		getTestimonialList(userPvid: $userPvid, limit: $limit) {
			comment
			postedDate
			rating
			reviewer {
				pvid
				firstName
				lastName
				resource {
					path
				}
			}
		}
	}
`;

export const MUTATE_TESTIMONIALS_BY_TOKEN = gql`
	mutation MUTATE_TESTIMONIALS_BY_TOKEN($token: String!, $limit: Int!) {
		getTestimonialListByToken(token: $token, limit: $limit) {
			comment
			postedDate

			reviewer {
				pvid
				firstName
				lastName
			}
		}
	}
`;

export const MUTATE_ADD_TESTIMONIAL = gql`
	mutation MUTATE_ADD_TESTIMONIAL(
		$programmeSubjectPvid: Int!
		$comment: String!
		$referralCode: String!
		$reviewerPvid: Int
		$rating: Float!
		$newUser: NewUserTestimonial
	) {
		addTestimonial(
			programmeSubjectPvid: $programmeSubjectPvid
			comment: $comment
			referralCode: $referralCode
			reviewerPvid: $reviewerPvid
			rating: $rating
			newUser: $newUser
		) {
			text
			success
		}
	}
`;

export const mutateDeleteEducations = gql`
	mutation mutateDeleteEducations($pvidList: [String!]!) {
		deleteEducation(pvidList: $pvidList)
	}
`;

export const mutateUpdateEducations = gql`
	mutation mutateUpdateEducations($list: [InputEducation]!) {
		updateEducation(list: $list) {
			pvid
		}
	}
`;

export const mutateUpdateExperiences = gql`
	mutation mutateUpdateExperiences($list: [InputExperience]!) {
		updateExperience(list: $list) {
			subject {
				name
				level {
					pvid
					curriculum
					institution
					fromDate
					toDate
					isPrivateTutor
					isPresent
				}
			}
		}
	}
`;

export const UPDATE_EXPERIENCES_NON_ACADEMIC = gql`
	mutation UPDATE_EXPERIENCES_NON_ACADEMIC($list: [InputExperience]!) {
		updateExperience(list: $list) {
			subject {
				name
				level {
					pvid
					curriculum
					institution
					fromDate
					toDate
					isPrivateTutor
					isPresent
				}
			}
		}
	}
`;

export const DELETE_EXPERIENCES_NON_ACADEMIC = gql`
	mutation DELETE_EXPERIENCES_NON_ACADEMIC($subject: String!) {
		deleteExperienceSubject(subject: $subject)
	}
`;

export const mutateDeleteExperiences = gql`
	mutation mutateDeleteExperiences($pvidList: [String!]!) {
		deleteExperience(pvidList: $pvidList)
	}
`;

export const DELETE_EXPERIENCES = gql`
	mutation DELETE_EXPERIENCES($subject: String!) {
		deleteExperienceSubject(subject: $subject)
	}
`;

export const DELETE_EXPERIENCES_LEVEL = gql`
	mutation DELETE_EXPERIENCES_LEVEL($pvidList: [String!]!) {
		deleteExperienceLevel(pvidList: $pvidList)
	}
`;

export const mutateDeleteSubjects = gql`
	mutation mutateDeleteSubjects($pvid: Int!) {
		deleteSubject(pvid: $pvid)
	}
`;

export const DELETE_SUBJECTS_LEVEL = gql`
	mutation DELETE_SUBJECTS_LEVEL($pvidList: [String!]!) {
		deleteLevel(pvidList: $pvidList)
	}
`;

export const mutateUpdateSubjects = gql`
	mutation mutateUpdateSubjects($list: [InputUserSubject]!) {
		updateSubject(list: $list) {
			orderList
			subject {
				pvid
				name
				level {
					pvid
					programmeSubjectPvid
					programmeLevelPvid
					name
					pricePerHour
				}
			}
		}
	}
`;

export const MUTATE_FIND_SKILLS = gql`
	mutation MUTATE_FIND_SKILLS($filter: String!) {
		findSkills(filter: $filter) {
			pvid
			countryCode
			title
		}
	}
`;

export const mutateUpdateSkills = gql`
	mutation mutateUpdateSkills($list: [InputSkill]!) {
		updateSkill(list: $list) {
			pvid
		}
	}
`;

export const mutateDeleteSkills = gql`
	mutation mutateDeleteSkills($pvidList: [String!]!) {
		deleteSkill(pvidList: $pvidList)
	}
`;

export const mutateUploadCertification = gql`
	mutation mutateUploadCertification($file: Upload!) {
		uploadCertification(file: $file) {
			fileName
			path
		}
	}
`;

export const mutateAddCertification = gql`
	mutation mutateAddCertification($relativePath: String, $name: String!, $issuedDate: Date!, $issuer: String!) {
		addCertificate(relativePath: $relativePath, name: $name, issuedDate: $issuedDate, issuer: $issuer) {
			pvid
			name
			userPvid
			relativePath
			issuer
			issuedDate
		}
	}
`;

export const MUTATE_UPDATE_CERTIFICATE = gql`
	mutation MUTATE_UPDATE_CERTIFICATE($pvid: Int!, $name: String!, $issuedDate: Date!, $issuer: String!) {
		updateCertificate(pvid: $pvid, name: $name, issuedDate: $issuedDate, issuer: $issuer) {
			success
			text
		}
	}
`;

export const mutateDeleteCertification = gql`
	mutation mutateDeleteCertification($pvid: Int!) {
		deleteCertificate(pvid: $pvid)
	}
`;

export const mutateAddOtherPlatform = gql`
	mutation mutateAddOtherPlatform($list: [InputOtherPlatform]!) {
		updateOtherPlatform(list: $list) {
			pvid
		}
	}
`;

export const mutateDeleteOtherPlatform = gql`
	mutation mutateDeleteOtherPlatform($pvidList: [String!]!) {
		deleteOtherPlatform(pvidList: $pvidList)
	}
`;

export const subscribeNewNotification = gql`
	subscription subscribeNewNotification($id: String!, $userPvid: Int!) {
		notification(id: $id, userPvid: $userPvid) {
			pvid
			entityId
			code
			remarks
			postedDate
			archived
		}
	}
`;

export const queryReferralLink = gql`
	query queryReferralLink {
		referralLink: getUserReferralLink
	}
`;

export const mutateDeleteResource = gql`
	mutation mutateDeleteResource($pvid: String!) {
		deleteResource(pvid: $pvid) {
			text
			success
		}
	}
`;

export const mutatePriceSuggestion = gql`
	mutation mutatePriceSuggestion($programmeSubjectPvid: Int!, $programmeLevelPvid: Int!) {
		getSuggestionPrice(programmeSubjectPvid: $programmeSubjectPvid, programmeLevelPvid: $programmeLevelPvid) {
			programmeSubjectPvid
			programmeLevelPvid
			averagePrice
		}
	}
`;

export const mutateProfileCompletionRequest = gql`
	mutation mutateProfileCompletionRequest($userPvid: Int!, $section: String!, $messages: String!) {
		profileCompletionRequest(userPvid: $userPvid, section: $section, messages: $messages) {
			text
			success
		}
	}
`;

export const queryListPostingAssignment = gql`
	query queryListPostingAssignment {
		listPostingAssignment {
			pvid
			title
			tutorExperience
			location
			notes
			isActive
			tutorGender
			programmeLevelName
			programmeSubjectName
			priceRangeStartPrice
			priceRangeEndPrice
			createdAt
			resource {
				path
			}
			profileJoin {
				userPvid
				firstName
				lastName
				resource {
					path
				}
			}
			acceptanceTutorList {
				token
				userPvid
				firstName
				lastName
				isAccept
				email
				phone
				country
				resource {
					path
				}
			}
			broadcast {
				remark
			}
		}
	}
`;

export const QUERY_PUBLIC_ASSIGNMENTS = gql`
	query QUERY_PUBLIC_ASSIGNMENTS($country: String!) {
		listPostingAssignmentPublic(country: $country) {
			pvid
			title
			tutorExperience
			location
			notes
			isActive
			tutorGender
			programmeLevelName
			programmeSubjectName
			priceRangeStartPrice
			priceRangeEndPrice
			createdAt
			resource {
				path
			}
			profileJoin {
				userPvid
				firstName
				lastName
				resource {
					path
				}
			}
			acceptanceTutorList {
				userPvid
				email
				phone
				country
				firstName
				lastName
				isAccept
				isApply
				resource {
					path
				}
			}
			broadcast {
				remark
			}
			role {
				pvid
				code
				name
				isPrivate
			}
		}
	}
`;

export const queryWhoViewedMe = gql`
	query queryWhoViewedMe {
		listWhoViewedProfile {
			oneWeek {
				oneWeekCount
				startDate
				endDate
			}
			twoWeek {
				twoWeekCount
				startDate
				endDate
			}
			oneMonth {
				oneMonthCount
			}
			listWhoViewed {
				userPvid
				firstName
				lastName
				roleName
				profileViewedDate
				resource {
					path
				}
			}
		}
	}
`;

export const mutateAddPostingAssignment = gql`
	mutation mutateAddPostingAssignment(
		$title: String!
		$curriculumPvid: Int!
		$subjectPvid: Int!
		$tutorExperience: String!
		$location: String!
		$notes: String
		$isActive: Int!
		$tutorGender: Int!
		$isJoin: Int!
		$joinStudent: [JoinStudent]!
		$priceRange: [PriceRange!]!
		$broadcast: [InputBroadcast]!
	) {
		addPostingAssignment(
			title: $title
			curriculumPvid: $curriculumPvid
			subjectPvid: $subjectPvid
			tutorExperience: $tutorExperience
			location: $location
			notes: $notes
			isActive: $isActive
			tutorGender: $tutorGender
			isJoin: $isJoin
			broadcast: $broadcast
			joinStudent: $joinStudent
			priceRange: $priceRange
		) {
			text
			success
		}
	}
`;

export const MUTATE_FILTER_ASSIGNMENTS = gql`
	mutation MUTATE_FILTER_ASSIGNMENTS(
		$keywords: String
		$location: String
		$subject: Int
		$curriculum: Int
		$limit: Int
		$startPrice: Float
		$endPrice: Float
		$country: String!
	) {
		findAssignment(
			keywords: $keywords
			location: $location
			subject: $subject
			curriculum: $curriculum
			limit: $limit
			startPrice: $startPrice
			endPrice: $endPrice
			country: $country
		) {
			pvid
			title
			tutorExperience
			location
			notes
			isActive
			tutorGender
			resource {
				path
			}
			programmeLevelName
			programmeSubjectName
			priceRangeStartPrice
			priceRangeEndPrice
			createdAt
			profileJoin {
				userPvid
				firstName
				lastName
				resource {
					path
				}
			}
			acceptanceTutorList {
				userPvid
				email
				phone
				country
				firstName
				lastName
				isAccept
				isApply
				resource {
					path
				}
			}
			broadcast {
				remark
			}
			role {
				pvid
				code
				name
				isPrivate
			}
		}
	}
`;

export const QUERY_ASSIGNMENT_DETAILS = gql`
	query QUERY_ASSIGNMENT_DETAILS($postingAssignmentPvid: Int!) {
		detailPostingAssignment(postingAssignmentPvid: $postingAssignmentPvid) {
			pvid
			title
			tutorExperience
			location
			firstNamePosting
			lastNamePosting
			countryPosting
			notes
			isActive
			tutorGender
			programmeLevelName
			programmeSubjectName
			priceRangeStartPrice
			priceRangeEndPrice
			createdAt
			resource {
				path
			}
			profileJoin {
				userPvid
				firstName
				lastName
				resource {
					path
				}
			}
			acceptanceTutorList {
				token
				userPvid
				firstName
				lastName
				isAccept
				email
				phone
				country
				resource {
					path
				}
			}
			broadcast {
				remark
			}
		}
	}
`;

export const GET_BALANCE_INFO = gql`
	query GET_BALANCE_INFO {
		getProfile {
			pvid
			userPvid
			balance
			hasBalance
		}
	}
`;
export const GET_TUTOR_READS = gql`
	query GET_TUTOR_ARTICLE($tutorPvid: Int!) {
		getTutorArticles(tutorPvid: $tutorPvid) {
			pvid
			title
			userPvid
			image
			comments
			views
			upvotes
			downvotes
			created_at
			status
			controlPanelStatus
			content
			tags {
				pvid
				name
			}
			avatar {
				name
				FirstName
				LastName
				RelativePath
				AwsPath
			}
		}
	}
`;

export const GET_USER_DATA_FOR_PAYMENT = gql`
	mutation GET_USER_DATA_PAYMENT($userId: String!) {
		getUserDetailsOnPaymentURL(userId: $userId) {
			firstName
			lastName
			userAvatar
			userEmail
		}
	}
`;

export const GET_USER_PAYMENT_HISTORY = gql`
	mutation USER_PAYMENT_HISTORY {
		getUserTransactions {
			price
			fee
			status
			userId
			id
			service
			currency
			createdAt
		}
	}
`;
export const GET_USER_BANK_ACCOUNT = gql`
	mutation GET_USER_BANK_ACCOUNT {
		getUsersBankAccounts {
			id
			userId
			currency
			accountHolderName
			accountNumber
			abartn
			accountType
			bankCode
			branchCode
			ifscCode
			swiftCode
			country
			city
			postCode
			firstLine
		}
	}
`;

export const ADD_USER_BANK_ACCOUNT = gql`
	mutation addBankAccount(
		$currency: String!
		$accountHolderName: String!
		$accountNumber: String
		$abartn: String
		$accountType: String
		$bankCode: String
		$branchCode: String
		$ifscCode: String
		$swiftCode: String
		$country: String!
		$city: String!
		$postCode: String!
		$firstLine: String!
	) {
		addBankAccount(
			data: {
				currency: $currency
				accountHolderName: $accountHolderName
				accountNumber: $accountNumber
				abartn: $abartn
				accountType: $accountType
				bankCode: $bankCode
				branchCode: $branchCode
				ifscCode: $ifscCode
				swiftCode: $swiftCode
				country: $country
				city: $city
				postCode: $postCode
				firstLine: $firstLine
			}
		)
	}
`;

export const DELETE_USER_BANK_ACCOUNT = gql`
	mutation deleteBankAccount($accountId: String!) {
		deleteBankAccount(accountId: $accountId)
	}
`;


export const mutateUpdateSchool = gql`
	mutation mutateUpdateSchool($school: String) {
		updateSchoolProfile(school: $school) 
	}
`;

export const CHECK_GOOGLE_TOKEN = gql`
	mutation CHECK_GOOGLE_TOKEn {
		checkGoogleToken
	}
`


export const CREATE_GOOGLE_TOKEN = gql`
	mutation CREATE_GOOGLE_TOKEN($code: String!) {
		createGoogleToken(code: $code)
	}
`

export const STORE_REFERRAL_USER = gql`
	mutation STORE_REFFERRAL_USER($id: String!, $code: String!, $country: String!) {
		storeReferralUser(id: $id, code: $code, country: $country)
	}
`