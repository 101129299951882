/* eslint-disable react/display-name */
import React from "react"
import { Link } from "react-router-dom"
import { EditOutlined, RightOutlined } from '@ant-design/icons';
import { Badge } from "antd";
import Button from "../components/Button"
import { countryMap } from "helpers/countryMapping"

export const siteKey = "6LenZaIUAAAAACruEcy82IRIJ5qXla_4wDaQjrUg"
export const secretKey = "6LenZaIUAAAAAI7x2IZp3FfT8aHlwEGGu_p-9uL1"

export const coolColors = ["#f50", "#51e28b", "#87d068", "#108ee9", "#dc3599", "#09d2ff"]

const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {}
const country = countryMap[userDetails.country] || {}

export const months = [
	{
		value: "January",
		label: "January",
		number: "01",
	},
	{
		value: "February",
		label: "February",
		number: "02",
	},
	{
		value: "March",
		label: "March",
		number: "03",
	},
	{
		value: "April",
		label: "April",
		number: "04",
	},
	{
		value: "May",
		label: "May",
		number: "05",
	},
	{
		value: "June",
		label: "June",
		number: "06",
	},
	{
		value: "July",
		label: "July",
		number: "07",
	},
	{
		value: "August",
		label: "August",
		number: "08",
	},
	{
		value: "September",
		label: "September",
		number: "09",
	},
	{
		value: "October",
		label: "October",
		number: "10",
	},
	{
		value: "November",
		label: "November",
		number: "11",
	},
	{
		value: "December",
		label: "December",
		number: "12",
	},
]

export const dates = Array.from(
	{
		length: 31,
	},
	(_, idx) => ({
		value: idx + 1,
		label: idx + 1,
	})
)

export const tutors = [
	{
		id: 1,
		name: "Jono Aditya",
		imageUrl: "https://randomuser.me/api/portraits/men/64.jpg",
		experience: 4,
		education: [
			{
				id: 1,
				name: "Nanyang Polytechnic",
				from: "2010",
				to: "2014",
			},
			{
				id: 2,
				name: "Lim Yee Ken Secondary High School",
				from: "2005",
				to: "2009",
			},
			{
				id: 3,
				name: "Lim Yee Ken Primary High School",
				from: "2000",
				to: "2004",
			},
		],
		pricePerHour: [
			{
				subject: "English",
				price: 30,
			},
			{
				subject: "Hindi",
				price: 40,
			},
			{
				subject: "Bahasa",
				price: 70,
			},
		],
		rating: 4.5,
		testimonials: 18,
		area: "Paya Lebar",
		location: "19 Paya Lebar View, Paya Lebar",
		zip: "319949",
	},
	{
		id: 2,
		name: "Melki Rahmad",
		imageUrl: "https://randomuser.me/api/portraits/men/16.jpg",
		experience: 2,
		education: [
			{
				id: 1,
				name: "University of Maryland",
				from: "2010",
				to: "2014",
			},
			{
				id: 2,
				name: "Lim Yee Ken Secondary High School",
				from: "2005",
				to: "2009",
			},
			{
				id: 3,
				name: "Lim Yee Ken Primary High School",
				from: "2000",
				to: "2004",
			},
		],
		pricePerHour: [
			{
				subject: "Mathematic",
				price: 30,
			},
			{
				subject: "History",
				price: 40,
			},
		],
		rating: 5,
		testimonials: 29,
		area: "Bukit Batok",
		location: "Bukit Batok View 45",
		zip: "204930",
	},
	{
		id: 3,
		name: "Gilbert Ho",
		imageUrl: "https://randomuser.me/api/portraits/men/4.jpg",
		experience: 5,
		education: [
			{
				id: 1,
				name: "Binus University",
				from: "2010",
				to: "2014",
			},
			{
				id: 2,
				name: "Lim Yee Ken Secondary High School",
				from: "2005",
				to: "2009",
			},
			{
				id: 3,
				name: "Lim Yee Ken Primary High School",
				from: "2000",
				to: "2004",
			},
		],
		pricePerHour: [
			{
				subject: "Hindi",
				price: 30,
			},
			{
				subject: "Mathematic",
				price: 40,
			},
		],
		rating: 4,
		testimonials: 31,
		area: "Dhoby Ghaut",
		location: "1 Dhoby Ghaut View, Dhoby Ghaut",
		zip: "990349",
	},
	{
		id: 4,
		name: "Siddharta Kap",
		imageUrl: "https://randomuser.me/api/portraits/men/35.jpg",
		experience: 6,
		education: [
			{
				id: 1,
				name: "National University of Singapore",
				from: "2010",
				to: "2014",
			},
			{
				id: 2,
				name: "Lim Yee Ken Secondary High School",
				from: "2005",
				to: "2009",
			},
			{
				id: 3,
				name: "Lim Yee Ken Primary High School",
				from: "2000",
				to: "2004",
			},
		],
		pricePerHour: [
			{
				subject: "Biology",
				price: 30,
			},
		],
		rating: 3.5,
		testimonials: 45,
		area: "Fort Canning",
		location: "77 Fort Canning View, Fort Canning",
		zip: "242334",
	},
	{
		id: 5,
		name: "Jono Aditya",
		imageUrl: "https://randomuser.me/api/portraits/men/64.jpg",
		experience: 4,
		education: [
			{
				id: 1,
				name: "Nanyang Polytechnic",
				from: "2010",
				to: "2014",
			},
			{
				id: 2,
				name: "Lim Yee Ken Secondary High School",
				from: "2005",
				to: "2009",
			},
			{
				id: 3,
				name: "Lim Yee Ken Primary High School",
				from: "2000",
				to: "2004",
			},
		],
		pricePerHour: [
			{
				subject: "English",
				price: 30,
			},
			{
				subject: "Hindi",
				price: 40,
			},
			{
				subject: "Bahasa",
				price: 70,
			},
		],
		rating: 4.5,
		testimonials: 18,
		area: "Paya Lebar",
		location: "19 Paya Lebar View, Paya Lebar",
		zip: "319949",
	},
	{
		id: 6,
		name: "Melki Rahmad",
		imageUrl: "https://randomuser.me/api/portraits/men/16.jpg",
		experience: 2,
		education: [
			{
				id: 1,
				name: "University of Maryland",
				from: "2010",
				to: "2014",
			},
			{
				id: 2,
				name: "Lim Yee Ken Secondary High School",
				from: "2005",
				to: "2009",
			},
			{
				id: 3,
				name: "Lim Yee Ken Primary High School",
				from: "2000",
				to: "2004",
			},
		],
		pricePerHour: [
			{
				subject: "Mathematic",
				price: 30,
			},
			{
				subject: "History",
				price: 40,
			},
		],
		rating: 5,
		testimonials: 29,
		area: "Bukit Batok",
		location: "Bukit Batok View 45",
		zip: "204930",
	},
	{
		id: 7,
		name: "Gilbert Ho",
		imageUrl: "https://randomuser.me/api/portraits/men/4.jpg",
		experience: 5,
		education: [
			{
				id: 1,
				name: "Binus University",
				from: "2010",
				to: "2014",
			},
			{
				id: 2,
				name: "Lim Yee Ken Secondary High School",
				from: "2005",
				to: "2009",
			},
			{
				id: 3,
				name: "Lim Yee Ken Primary High School",
				from: "2000",
				to: "2004",
			},
		],
		pricePerHour: [
			{
				subject: "Hindi",
				price: 30,
			},
			{
				subject: "Mathematic",
				price: 40,
			},
		],
		rating: 4,
		testimonials: 31,
		area: "Dhoby Ghaut",
		location: "1 Dhoby Ghaut View, Dhoby Ghaut",
		zip: "990349",
	},
	{
		id: 8,
		name: "Siddharta Kap",
		imageUrl: "https://randomuser.me/api/portraits/men/35.jpg",
		experience: 6,
		education: [
			{
				id: 1,
				name: "National University of Singapore",
				from: "2010",
				to: "2014",
			},
			{
				id: 2,
				name: "Lim Yee Ken Secondary High School",
				from: "2005",
				to: "2009",
			},
			{
				id: 3,
				name: "Lim Yee Ken Primary High School",
				from: "2000",
				to: "2004",
			},
		],
		pricePerHour: [
			{
				subject: "Biology",
				price: 30,
			},
		],
		rating: 3.5,
		testimonials: 45,
		area: "Fort Canning",
		location: "77 Fort Canning View, Fort Canning",
		zip: "242334",
	},
]

export const courses = [
	{
		id: 1,
		title: "H2 Chemistry - from Zero to Hero",
		description: "A Levels Chemistry",
		price: "$25",
		capacity: 2,
		tutor: "Shaun Lee",
		src: "https://app.cudy.co/processor/_doDisplayImage.php?request=progImg&id=1",
	},
	{
		id: 2,
		title: "Introduction to Comprehension",
		description: "Lower Secondary English",
		price: "$20",
		capacity: 5,
		tutor: "Abraham Gunawan",
		src: "https://app.cudy.co/processor/_doDisplayImage.php?request=progImg&id=2",
	},
	{
		id: 3,
		title: "Mathematics Lesson for all.",
		description: "Primary Maths",
		price: "$35",
		capacity: 7,
		tutor: "Gerry Salim",
		src: "https://app.cudy.co/processor/_doDisplayImage.php?request=progImg&id=3",
	},
	{
		id: 4,
		title: "Fundamental of Mathematics Class",
		description: "Primary Maths",
		price: "$45",
		capacity: 10,
		tutor: "Hendra Bako",
		src: "https://app.cudy.co/processor/_doDisplayImage.php?request=progImg&id=1",
	},
	{
		id: 5,
		title: "A-math (O-level)",
		description: "O Levels A Maths",
		price: "$15",
		capacity: 2,
		tutor: "Shaun Lee",
		src: "https://app.cudy.co/processor/_doDisplayImage.php?request=progImg&id=5",
	},
	{
		id: 6,
		title: "Quick Revision for Upcoming Prelim 1 - Chemistry",
		description: "O Levels Chemistry",
		price: "$25",
		capacity: 1,
		tutor: "Tiara Beck",
		src: "https://app.cudy.co/processor/_doDisplayImage.php?request=progImg&id=7",
	},
	{
		id: 7,
		title: "Introduction to O level chemistry",
		description: "O Levels Chemistry",
		price: "$30",
		capacity: 2,
		tutor: "Gesyon Yu",
		src: "https://app.cudy.co/processor/_doDisplayImage.php?request=progImg&id=7",
	},
]

export const companyLogo = [
	"https://app.cudy.co/resource/edtechxasia_logo.png",
	"https://app.cudy.co/resource/e27-logo.png",
	"https://app.cudy.co/resource/sbr_logo.png",
	"https://app.cudy.co/resource/wantedly.png",
	"https://app.cudy.co/resource/capitalistlad.png",
]

export const dummyWhoViewed = [
	{
		id: 1,
		name: "Tanto Suhendro",
		time: "2019/09/21",
		role: "STDN",
		location: "201 Orchard Rd 232994",
		avatar: "https://pbs.twimg.com/profile_images/974736784906248192/gPZwCbdS.jpg",
	},
	{
		id: 2,
		name: "Felix Salim",
		time: "2019/10/23",
		role: "TTR0",
		location: "201 Orchard Rd 232994",
		avatar: "https://randomuser.me/api/portraits/women/17.jpg",
	},
	{
		id: 3,
		name: "Juni Hasyim",
		time: "2019/08/22",
		role: "STDN",
		location: "201 Orchard Rd 232994",
		avatar: "https://randomuser.me/api/portraits/men/61.jpg",
	},
	{
		id: 4,
		name: "Gilbert Tan",
		time: new Date(),
		role: "TTR0",
		location: "201 Orchard Rd 232994",
		avatar: "https://randomuser.me/api/portraits/women/63.jpg",
	},
	{
		id: 5,
		name: "Billy Ton",
		time: new Date(),
		role: "STDN",
		location: "201 Orchard Rd 232994",
		avatar: "https://randomuser.me/api/portraits/women/31.jpg",
	},
]

export const dummyTestimonials = [
	{
		comment:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
		postedDate: "2019-10-08 08:05:39",
		rating: 4,
		reviewer: {
			firstName: "John",
			lastName: "Doe",
			pvid: "100",
		},
	},
	{
		comment:
			"At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos.",
		postedDate: "2019-10-08 08:05:39",
		rating: 4,
		reviewer: {
			firstName: "Jane",
			lastName: "Doe",
			pvid: "99",
		},
	},
	{
		comment: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.",
		postedDate: "2019-10-08 08:05:39",
		rating: 5,
		reviewer: {
			firstName: "Alicia",
			lastName: "Doe",
			pvid: "98",
		},
	},
]

export const dummySkills = [
	{
		title: "Computer",
	},
	{
		title: "English",
	},
	{
		title: "Math",
	},
	{
		title: "Biology",
	},
	{
		title: "Physics",
	},
	{
		title: "Programming",
	},
	{
		title: "Adobe Photoshop",
	},
	{
		title: ".Net",
	},
	{
		title: "React",
	},
	{
		title: "Nodejs",
	},
	{
		title: "Javascript",
	},
]

export const skillList = [
	"Computer",
	"English",
	"Math",
	"Biology",
	"Physics",
	"Programming",
	"Adobe Photoshop",
	"Demo jemaah",
	"MS Excel",
	"MS Word",
	"Accounting",
	"Crafting",
	"UI UX",
	"Poetry",
	"Literature",
]

export const liveLessons = [
	{
		id: 1,
		title: "Make a new component",
		description: "12k views",
		duration: "43:20",
		src: "https://source.unsplash.com/random/",
		link: "https://www.youtube.com/watch?v=oUFJJNQGwhk",
	},
	{
		id: 2,
		title: "Create a new component",
		description: "12k views",
		duration: "22:20",
		src: "https://source.unsplash.com/500x500/",
		link: "https://www.youtube.com/watch?v=_b_YVrex0yI",
	},
	{
		id: 3,
		title: "Update a new component",
		description: "12k views",
		duration: "54:20",
		src: "https://source.unsplash.com/501x501/",
		link: "https://www.youtube.com/watch?v=1hHhQCQg9BA",
	},
	{
		id: 4,
		title: "Delete a new component",
		description: "12k views",
		duration: "12:20",
		src: "https://source.unsplash.com/501x500/",
		link: "https://www.youtube.com/watch?v=DOxwO9WMWi0",
	},
	{
		id: 5,
		title: "Throw a new component",
		description: "12k views",
		duration: "34:09",
		src: "https://source.unsplash.com/500x501/",
		link: "https://www.youtube.com/watch?v=sCMzmGENRHE",
	},
	{
		id: 6,
		title: "Get a new component",
		description: "7k views",
		duration: "15:00",
		src: "https://source.unsplash.com/500x499/",
		link: "http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4",
	},
]

export const lessons = [
	{
		id: 1,
		title: "Make a new component",
		description: "15 Sept 2018 10:00 PM",
		duration: "90 minutes",
		src: "https://source.unsplash.com/random/",
	},
	{
		id: 2,
		title: "Create a new component",
		description: "15 Sept 2018 10:00 PM",
		duration: "90 minutes",
		src: "https://source.unsplash.com/500x500/",
	},
	{
		id: 3,
		title: "Update a new component",
		description: "15 Sept 2018 10:00 PM",
		duration: "90 minutes",
		src: "https://source.unsplash.com/501x501/",
	},
	{
		id: 4,
		title: "Delete a new component",
		description: "15 Sept 2018 10:00 PM",
		duration: "90 minutes",
		src: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
	},
	{
		id: 5,
		title: "Throw a new component",
		description: "15 Sept 2018 10:00 PM",
		duration: "90 minutes",
		src: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
	},
]

export const reviews = [
	{
		id: 1,
		content: "Best teacher I've ever met, so far.",
		author: "Felix Sanjaya",
		datetime: "2015-02-10 13:55:02",
		photoUrl: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
	},
	{
		id: 2,
		content: "Really a crystal clear explanation",
		author: "Bumi Adiyaksa",
		datetime: "2017-08-10 13:55:02",
		photoUrl: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
	},
	{
		id: 3,
		content: "Please update the content!",
		author: "Jerry Yan",
		datetime: "2018-12-09 13:55:02",
		photoUrl: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
	},
]

export const reviewsTutor = [
	{
		id: 1,
		src: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
		title: "Gerry Adams",
		description:
			"Great course from a great tutor! We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
		date: "21 January 2019",
		rating: 4,
	},
	{
		id: 2,
		src: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
		title: "Husein Al-attas",
		description:
			"Great course from a great tutor! We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
		date: "03 March 2019",
		rating: 5,
	},
	{
		id: 3,
		src: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
		title: "Albert Polin",
		description:
			"Keep up the good work! We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
		date: "15 April 2019",
		rating: 5,
	},
]

export const reviewsDashboard = [
	{
		id: 1,
		src: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
		student: "Gerry Adams",
		title: "Introduction to Math",
		level: "Lower Secondary English",
		description: "Keep up the good work!",
		date: "21 January 2019",
		rating: 4,
	},
	{
		id: 2,
		src: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
		student: "Husein Al-attas",
		title: "Introduction to Science",
		level: "A Level English",
		description: "Why such person like you alive?",
		date: "03 March 2019",
		rating: 3,
	},
	{
		id: 3,
		src: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
		student: "Albert Polin",
		title: "Science for Religious Person",
		level: "Keep up the good work!",
		description: "Lorem ipsum is not everything you need to know",
		date: "15 April 2019",
		rating: 5,
	},
]

export const questions = [
	{
		id: 1,
		src: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
		title: "Gerry Adams",
		description: "Great course from a great tutor!",
		date: "21 January 2019",
	},
	{
		id: 2,
		src: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
		title: "Husein Al-attas",
		description: "Great course from a great tutor!",
		date: "03 March 2019",
	},
	{
		id: 3,
		src: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
		title: "Albert Polin",
		description: "Keep up the good work!",
		date: "15 April 2019",
	},
]

export const additionals = [
	{
		label: "Be the first to receive discounts and deals from the Cudy newsletter",
		value: "newsletter",
	},
	{
		label: "I have agreed to the terms and conditions and privacy policy of the site",
		value: "tc",
	},
]

export const cloudStorage = [
	{
		id: 1,
		capacity: 1,
		price: 2,
	},
	{
		id: 2,
		capacity: 5,
		price: 5,
	},
	{
		id: 3,
		capacity: 10,
		price: 8,
	},
	{
		id: 4,
		capacity: 30,
		price: 20,
	},
	{
		id: 5,
		capacity: 50,
		price: 30,
	},
	{
		id: 6,
		capacity: 100,
		price: 50,
	},
	{
		id: 7,
		capacity: 200,
		price: 80,
	},
]

export const uploadedResources = [
	{
		value: "How to be a Photographer",
		label: "How to be a Photographer",
		children: [
			{
				value: "Getting Started with Photography",
				label: "Getting Started with Photography",
				dataSource: [
					{
						key: "1",
						name: "E-book for another lessons",
						instructions: "Please read the book carefully",
						file: "file.pdf",
					},
					{
						key: "2",
						name: "Design guide",
						instructions: "Please read the book carefully",
						file: "frontend.pdf",
					},
					{
						key: "3",
						name: "Religion guide",
						instructions: "Please read the bible carefully",
						file: "religion.pdf",
					},
				],
			},
		],
	},
	{
		value: "Be a Good Programmer",
		label: "Be a Good Programmer",
		children: [
			{
				value: "Frontend or Backend?",
				label: "Frontend or Backend?",
				dataSource: [
					{
						key: "1",
						name: "E-book for another lessons",
						instructions: "Please read the book carefully",
						file: "file.pdf",
					},
					{
						key: "2",
						name: "John",
						instructions: "Please read the book carefully",
						file: "frontend.pdf",
					},
				],
			},
			{
				value: "Love of Hate Song?",
				label: "Love of Hate Song?",
				dataSource: [
					{
						key: "1",
						name: "E-book for another lessons",
						instructions: "Please read the book carefully",
						file: "file.pdf",
					},
					{
						key: "2",
						name: "Gamepad",
						instructions: "Please read the book carefully",
						file: "frontend.pdf",
					},
				],
			},
			{
				value: "Setting up the Project",
				label: "Setting up the Project",
				dataSource: [
					{
						key: "1",
						name: "E-book for another lessons",
						instructions: "Please read the book carefully",
						file: "hello.pdf",
					},
					{
						key: "2",
						name: "Design guide",
						instructions: "Please read the book carefully",
						file: "design.pdf",
					},
				],
			},
		],
	},
]

export const userExperiences = [
	{
		id: 1,
		pvt: false,
		name: "88 Tuition Bukit Batok",
		level: "N Level: Secondary 1 – 4",
		subject: "English",
		monthFrom: "January",
		yearFrom: "2018",
		monthTo: "July",
		yearTo: "2018",
	},
	{
		id: 2,
		pvt: false,
		name: "Great Tuit",
		level: "O Level: Secondary 1 – 5",
		subject: "Biology",
		monthFrom: "June",
		yearFrom: "2017",
		monthTo: "July",
		yearTo: "2018",
	},
	{
		id: 3,
		pvt: true,
		name: "",
		level: "A Level: Junior College 1 – 2",
		subject: "History",
		monthFrom: "March",
		yearFrom: "2014",
		monthTo: "March",
		yearTo: "2016",
	},
	{
		id: 4,
		pvt: true,
		name: "",
		level: "O Level: Junior College 1 – 2",
		subject: "Chemistry",
		monthFrom: "August",
		yearFrom: "2012",
		monthTo: "February",
		yearTo: "2013",
	},
]

export const feedSample = [
	{
		id: 1,
		name: "Han Solo",
		date: "21 August 2019",
		likes: 29,
		dislikes: 9,
		content: "Feels too much these days, but we're still dealing with those.",
		avatar: "https://randomuser.me/api/portraits/men/63.jpg",
	},
	{
		id: 2,
		name: "Gilbert Tan",
		date: "23 October 2019",
		likes: 80,
		dislikes: 11,
		content:
			"We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
		avatar: "https://randomuser.me/api/portraits/men/64.jpg",
	},
	{
		id: 3,
		name: "Henny Salim",
		date: "29 October 2019",
		likes: 110,
		dislikes: 3,
		content: "Open for assignments. Feel free to reach me and have a great deal for this month only",
		avatar: "https://randomuser.me/api/portraits/women/64.jpg",
	},
]

export const profileSections = [
	{
		value: "Skills",
		label: "Skills",
	},
	{
		value: "Other Platforms",
		label: "Other Platforms",
	},
	{
		value: "Introduction Videos",
		label: "Introduction Videos",
	},
	{
		value: "About me",
		label: "About me",
	},
	{
		value: "Tutoring experiences",
		label: "Tutoring experiences",
	},
	{
		value: "Subjects",
		label: "Subjects",
	},
	{
		value: "Certifications",
		label: "Certifications",
	},
]

export const teachingDate = [
	{
		id: 1,
		subject: "English",
		location: "Chua Chu Kang",
		startDate: "2020-03-01",
		endDate: "2020-03-01",
	},
	{
		id: 2,
		subject: "History",
		location: "Redhill",
		startDate: "2020-03-01",
		endDate: "2020-03-05",
	},
	{
		id: 3,
		subject: "A Math",
		location: "Tua Payoh",
		startDate: "2019-11-14",
		endDate: "",
	},
	{
		id: 4,
		subject: "History",
		location: "Redhill",
		startDate: "2020-03-10",
		endDate: "2020-03-13",
	},
	{
		id: 5,
		subject: "Math",
		location: "Redhill",
		startDate: "2020-03-10",
		endDate: "2020-03-13",
	},
]

export const eventData = [
	{
		id: 1,
		title: "Tutor Day 2019",
		location: "Chua Chu Kang",
		description: "Some lorem ipsum is a nice trick to get your page filled out with something",
		startDate: "2019-11-01",
		endDate: "2019-11-02",
	},
	{
		id: 2,
		title: "Education Summit 2019",
		location: "Redhill",
		description: "Some lorem ipsum is a nice trick to get your page filled out with something",
		startDate: "2019-11-10",
		endDate: "2019-11-12",
	},
	{
		id: 3,
		title: "Singapore Bachelor Event 2019",
		location: "Tua Payoh",
		description: "Some lorem ipsum is a nice trick to get your page filled out with something",
		startDate: "2019-11-14",
		endDate: "",
	},
]

export const winners = [
	{
		id: "1",
		name: "Peggy Melati",
		src: "https://randomuser.me/api/portraits/men/64.jpg",
	},
	{
		id: "2",
		name: "Andre Hehanusa",
		src: "https://randomuser.me/api/portraits/men/4.jpg",
	},
	{
		id: "3",
		name: "Jono Karmila",
		src: "https://randomuser.me/api/portraits/men/36.jpg",
	},
	{
		id: "4",
		name: "Gilbert Goh",
		src: "https://randomuser.me/api/portraits/women/2.jpg",
	},
	{
		id: "5",
		name: "Felix Nurdin",
		src: "https://randomuser.me/api/portraits/women/63.jpg",
	},
	{
		id: "6",
		name: "Gunadarma Sasongko",
		src: "https://randomuser.me/api/portraits/men/2.jpg",
	},
	{
		id: "7",
		name: "Yuan Kadir",
		src: "https://randomuser.me/api/portraits/women/12.jpg",
	},
	{
		id: "8",
		name: "Indiryati Matthew",
		src: "https://randomuser.me/api/portraits/men/67.jpg",
	},
]

export const curriculum = [
	{
		key: "All",
		label: "All",
		value: "All",
	},
	{
		key: "PSLE",
		label: "PSLE",
		value: "PSLE",
	},
	{
		key: "N Level",
		label: "N Level",
		value: "N Level",
	},
	{
		key: "O Level",
		label: "O Level",
		value: "O Level",
	},
	{
		key: "A Level",
		label: "A Level",
		value: "A Level",
	},
]

export const sortBy = [
	{
		key: "recent",
		value: "recent",
		label: "Recent added",
	},
	{
		key: "alphabet",
		value: "alphabet",
		label: "Alphabetical",
	},
]

export const level = [
	{
		label: "All",
		value: "All",
	},
	{
		label: "PSLE: Primary 1 - 6",
		value: "PSLE: Primary 1 - 6",
	},
	{
		label: "N Level: Secondary 1 – 4",
		value: "N Level: Secondary 1 – 4",
	},
	{
		label: "O Level: Secondary 1 – 5",
		value: "O Level: Secondary 1 – 5",
	},
	{
		label: "A Level: Junior College 1 – 2",
		value: "A Level: Junior College 1 – 2",
	},
]

export const subjects = [
	{
		label: "All",
		value: "All",
		icon: "",
	},
	{
		label: "A Maths",
		value: "A Maths",
		icon: "dot-chart",
	},
	{
		label: "E Maths",
		value: "E Maths",
		icon: "fund",
	},
	{
		label: "Basic Chinese",
		value: "Basic Chinese",
		icon: "account-book",
	},
	{
		label: "Biology",
		value: "Biology",
		icon: "alert",
	},
	{
		label: "Chemistry",
		value: "Chemistry",
		icon: "api",
	},
	{
		label: "English",
		value: "English",
		icon: "bank",
	},
	{
		label: "Physics",
		value: "Physics",
		icon: "book",
	},
	{
		label: "History",
		value: "History",
		icon: "bulb",
	},
	{
		label: "Geography",
		value: "Geography",
		icon: "fire",
	},
]

export const preferredDays = [
	{
		label: "Monday",
		value: "Monday",
	},
	{
		label: "Tuesday",
		value: "Tuesday",
	},
	{
		label: "Wednesday",
		value: "Wednesday",
	},
	{
		label: "Thursday",
		value: "Thursday",
	},
	{
		label: "Friday",
		value: "Friday",
	},
	{
		label: "Saturday",
		value: "Saturday",
	},
	{
		label: "Sunday",
		value: "Sunday",
	},
]

export const subjectsOnProfile = [
	{
		subject: "History",
		level: "N Level",
		price: 30,
	},
	{
		subject: "Mathematics",
		level: "N Level",
		price: 20,
	},
	{
		subject: "Hindi",
		level: "O Level",
		price: 20,
	},
]

export const certificatesOnProfile = [
	{
		certificate: "TOEFL",
		year: "2018",
		issuer: "EF Singapore",
	},
	{
		certificate: "Mathematics Olympic",
		year: "2018",
		issuer: "MathSing Inc",
	},
	{
		certificate: "Best Hindi Tutor",
		year: "2018",
		issuer: "HindCommunity SG",
	},
]

export const yearListFrom1980 = Array.from(
	{
		length: new Date().getFullYear() - 1980 + 1,
	},
	(_, idx) => idx + 1980
).sort((a, b) => b - a)

export const otherPlatformsData = [
	{
		id: 1,
		label: "ManyTutors",
		value: "ManyTutors",
		icon: "https://ask.manytutors.com/favicon.ico",
	},
	{
		id: 2,
		label: "Tutopiya",
		value: "Tutopiya",
		icon: "https://app.tutopiya.com/img/favicon.ico",
	},
	{
		id: 3,
		label: "Tutoroo",
		value: "Tutoroo",
		icon: "https://app.tutopiya.com/img/favicon.ico",
	},
]

export const otherPlatforms = [
	{
		id: 1,
		website: "ManyTutors",
		link: "https://ask.manytutors.com/profile/goh.keng.siang",
		icon: "https://ask.manytutors.com/favicon.ico",
	},
	{
		id: 2,
		website: "Tutopiya",
		link: "https://app.tutopiya.com/#/dashboard/profile",
		icon: "https://app.tutopiya.com/img/favicon.ico",
	},
]

export const aboutMe = {
	name: "Aditya Ginanjar",
	dob: "21 September 1990",
	education: [
		{
			id: 1,
			name: "Nanyang Polytechnic",
			from: "2010",
			to: "2014",
		},
		{
			id: 2,
			name: "Lim Yee Ken Secondary High School",
			from: "2005",
			to: "2009",
		},
		{
			id: 3,
			name: "Lim Yee Ken Primary High School",
			from: "2000",
			to: "2004",
		},
	],
	qualification: "Bachelor",
	location: "1 Paya Lebar View, Paya Lebar 132933",
	bio:
		"Hi! My name is Malik Abimanyu, a bad person who trying to become a good person. I love art and design, that's why I choose to concentrate in UI/UX design field and taking a school in design department in Semarang State University. As UI/UX designer, I'm not just making a good looking design, but also useful design. Besides focusing on UI / UX, I also try to be an expert in the illustration and front-end fields. I have gained a lot of experience through workplaces and by participating in the Olympics. If you want to know me more, you can watch my video up there. You can also contact me by clicking orange button beside. Let's talk about future with me!",
}

export const typeOfClass = [
	{
		label: "All",
		value: "All",
	},
	{
		label: "Consultation Class",
		value: "Consultation Class",
	},
	{
		label: "Structured Class",
		value: "Structured Class",
	},
]

export const announcements = [
	{
		title: "Withdraw your balance",
		description: "There is some error with our system, we recommend all the teacher to withdraw your balance today",
		date: "10:09 AM",
		color: "red",
	},
	{
		title: "Check the weather",
		description: "Hello! We recommend all the teacher to withdraw your balance today",
		date: "07:33 PM",
	},
	{
		title: "Go grab some food!",
		description: "Hi, we recommend all the teacher to withdraw your balance today",
		date: "02:56 AM",
		color: "green",
	},
	{
		title: "Should go bed earlier",
		description:
			"Hi, we recommend all the teacher to go bed resting earlier today, since we are not in a good mood, just like you. Enjoy your night!",
		date: "00:11 AM",
		color: "green",
	},
]

export const referral = {
	columns: [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
		},
		{
			title: "Your reward",
			dataIndex: "reward",
			key: "reward",
		},
		{
			title: "Remarks",
			dataIndex: "remarks",
			key: "remarks",
		},
	],
	data: [
		{
			key: 1,
			name: "Junia Halim",
			date: "21 January 2019",
			reward: "$10",
			remarks: "-",
		},
		{
			key: 2,
			name: "Gerry Kho",
			date: "8 August 2018",
			reward: "$15",
			remarks: "Transferred",
		},
		{
			key: 3,
			name: "Kim Bum",
			date: "21 March 2019",
			reward: "$20",
			remarks: "-",
		},
		{
			key: 4,
			name: "Handoko Tjung",
			date: "9 December 2019",
			reward: "$10",
			remarks: "Cancelled",
		},
	],
}

export const classSchedule = {
	columns: [
		{
			title: "Class name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Type of class",
			dataIndex: "type",
			key: "type",
		},
		{
			title: "Date of class",
			dataIndex: "date",
			key: "date",
		},
		{
			title: "Execution time",
			dataIndex: "time",
			key: "time",
		},
		{
			title: "",
			dataIndex: "details",
			key: "details",
			render: (text) => (
				<Link to="/dashboard">
					Details <RightOutlined />
				</Link>
			),
		},
	],
	data: [
		{
			key: 1,
			name: "Knowing Diagrams in Maths",
			type: "Flexible class",
			date: "Fri, 20 Jun 2019",
			time: "10:00 AM - 02:00 PM",
		},
		{
			key: 2,
			name: "Loving Each Other",
			type: "Flexible class",
			date: "Fri, 20 Jun 2019",
			time: "10:00 AM - 02:00 PM",
		},
		{
			key: 3,
			name: "Be a Good Programmer",
			type: "Flexible class",
			date: "Thu, 01 May 2019",
			time: "10:00 AM - 02:00 PM",
		},
		{
			key: 4,
			name: "Getting Started with Science",
			type: "Flexible class",
			date: "Sun, 14 Dec 2019",
			time: "10:00 AM - 02:00 PM",
		},
	],
}

export const upcomingClasses = {
	columns: [
		{
			title: "Subject",
			dataIndex: "subject",
			key: "subject",
		},
		{
			title: "Class",
			dataIndex: "class",
			key: "class",
		},
		{
			title: "Lessons",
			dataIndex: "lessons",
			key: "lessons",
		},
		{
			title: "Tutor",
			dataIndex: "tutor",
			key: "tutor",
		},
		{
			title: "Lessons date",
			dataIndex: "lessons_date",
			key: "lessons_date",
		},
		{
			title: "Attendance",
			dataIndex: "attendance",
			key: "attendance",
			render: (t) => <Badge color={t === "Attended" ? "green" : "red"} text={t} />,
		},
	],
	data: [
		{
			id: 1,
			subject: "Knowing Diagrams in Maths",
			class: "Flexible class",
			lessons: "Lorem ipsum",
			tutor: "Andra Hirata",
			lessons_date: "21 January 2019",
			attendance: "Attended",
		},
		{
			id: 2,
			subject: "Loving Each Other",
			class: "Flexible class",
			lessons: "Lorem ipsum",
			tutor: "Gunawan Sasongko",
			lessons_date: "Fri, 20 Jun 2019",
			attendance: "Attended",
		},
		{
			id: 3,
			subject: "Math O Level",
			class: "Flexible class",
			lessons: "Lorem ipsum",
			tutor: "Lim Ham Song",
			lessons_date: "Thu, 19 Jun 2019",
			attendance: "Not attended",
		},
	],
}

function handleModal(key) {
	const actualKey = structuredClasses.data.find((item) => item.key === key)
	return actualKey.key
}

export const structuredClasses = {
	columns: [
		{
			title: "Subject",
			dataIndex: "subject",
			key: "subject",
		},
		{
			title: "Class",
			dataIndex: "class",
			key: "class",
		},
		{
			title: "Lessons",
			dataIndex: "lessons",
			key: "lessons",
		},
		{
			title: "Tutor",
			dataIndex: "tutor",
			key: "tutor",
		},
		{
			title: "Lessons date",
			dataIndex: "lessons_date",
			key: "lessons_date",
		},
		{
			title: "",
			dataIndex: "slots",
			key: "slots",
			render: (_, { key }) => {
				return (
					<Button icon={<EditOutlined />} type="link" size="default" onClick={() => handleModal(key)}>
						Edit slots...
					</Button>
				)
			},
		},
	],
	data: [
		{
			id: 1,
			subject: "Knowing Diagrams in Maths",
			class: "Flexible class",
			lessons: "Lorem ipsum",
			tutor: "Andra Hirata",
			lessons_date: "21 January 2019",
			classType: "Consultation",
			completed: true,
			price: 70,
			rating: 5,
		},
		{
			id: 2,
			subject: "Loving Each Other",
			class: "Flexible class",
			lessons: "Lorem ipsum",
			tutor: "Gunawan Sasongko",
			lessons_date: "Fri, 20 Jun 2019",
			classType: "Consultation",
			completed: false,
			price: 45,
			rating: 4,
		},
		{
			id: 3,
			subject: "Math O Level",
			class: "Flexible class",
			lessons: "Lorem ipsum",
			tutor: "Lim Ham Song",
			lessons_date: "Thu, 19 Jun 2019",
			classType: "Consultation",
			completed: true,
			price: 25,
			rating: 4,
		},
	],
}

export const bankInfo = [
	{
		key: "bca",
		value: "bca",
		label: "BCA",
	},
	{
		key: "mandiri",
		value: "mandiri",
		label: "Mandiri",
	},
]

export const profileInitialValues = {
	profile_link: "http://app.cudy.co/gunawan",
	biography: "I am a tutor with passion in education. I love Albert Einstein",
}

export const feedbackQuery = [
	{
		key: "Report an issue",
		value: "Report an issue",
		label: "Report an issue",
	},
	{
		key: "Enquiry",
		value: "Enquiry",
		label: "Enquiry",
	},
	{
		key: "Report a user",
		value: "Report a user",
		label: "Report a user",
	},
	{
		key: "Suggestions for improvements",
		value: "Suggestions for improvements",
		label: "Suggestions for improvements",
	},
]

export const school = [
	{
		key: "Red Mark School",
		value: "Red Mark School",
		label: "Red Mark School",
	},
	{
		key: "Kartini School",
		value: "Kartini School",
		label: "Kartini School",
	},
	{
		key: "Genius 3 School",
		value: "Genius 3 School",
		label: "Genius 3 School",
	},
	{
		key: "Hup Teng School",
		value: "Hup Teng School",
		label: "Hup Teng School",
	},
]

export const gender = [
	{
		key: "Male",
		value: "Male",
		label: "Male",
	},
	{
		key: "Female",
		value: "Female",
		label: "Female",
	},
]

export const race = [
	{
		key: "Malay",
		value: "Malay",
		label: "Malay",
	},
	{
		key: "Chinese",
		value: "Chinese",
		label: "Chinese",
	},
	{
		key: "Indian",
		value: "Indian",
		label: "Indian",
	},
	{
		key: "Others",
		value: "Others",
		label: "Others",
	},
]

export const thirdParties = [
	{ value: "telegram", label: "Telegram" },
	// { value: "reddit", label: "Reddit" }, // ==> Turned off for a while 🧐🧐🧐🧐🧐🧐
	{ value: "sgadspost", label: "Adspost - SG" },
	{ value: "sgchaosads", label: "Chaos Ads - SG" },
	{ value: "sgadsonline", label: "Ads Online - SG" },
	{ value: "sglocanto", label: "Locanto - SG" },
	{ value: "sggumtree", label: "Gumtree - SG" },
	{ value: "sgcraiglist", label: "Craiglist - SG" },
	{ value: "tutorsms", label: "TutorSMS" },
	{ value: "idlocanto", label: "Locanto - ID" },
	{ value: "idzobazo", label: "Zobazo - ID" },
	{ value: "idadpost", label: "Adpost - ID" },
	{ value: "myadpost", label: "Adpost - MY" },
	{ value: "myyello", label: "Yello - MY" },
	{ value: "mycraiglist", label: "Craiglist - MY" },
	{ value: "phlocanto", label: "Locanto - PH" },
	{ value: "phadpost", label: "Adpost - PH" },
	{ value: "phcraiglist", label: "Craiglist - PH" },
	{ value: "phbuyandsell", label: "Buy and Sell - PH" },
	// {
	// 	value: country.key + "facebookgroup",
	// 	label: "Facebook Group - " + country.name
	// }
	// { value: 'tutorsms', label: 'TutorSMS' },
	// { value: 'sgchaosads', label: 'Chaos Ads' },
	// { value: 'sglocanto', label: 'Locanto' },
	// { value: 'gumtree', label: 'Gumtree' },
]

export const payout = {
	status: [
		{
			key: "All",
			value: "All",
			label: "All",
		},
		{
			key: "Paid",
			value: "Paid",
			label: "Paid",
		},
		{
			key: "Pending",
			value: "Pending",
			label: "Pending",
		},
		{
			key: "Rejected",
			value: "Rejected",
			label: "Rejected",
		},
		{
			key: "Approved",
			value: "Approved",
			label: "Approved",
		},
	],
	columns: [
		{
			title: "Class",
			dataIndex: "class",
			key: "class",
		},
		{
			title: "Student",
			dataIndex: "student",
			key: "student",
		},
		{
			title: "Date requested",
			dataIndex: "date_requested",
			key: "date_requested",
		},
		{
			title: "Date payout",
			dataIndex: "date_payout",
			key: "date_payout",
		},
		{
			title: "Amount",
			dataIndex: "amount",
			key: "amount",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (t) => <Badge color={t === "Accepted" ? "green" : "red"} text={t} />,
		},
	],
	data: [
		{
			key: 1,
			class: "Art O Level",
			student: "Gerry Neville",
			date_requested: "21 January 2019",
			date_payout: "21 January 2019",
			amount: "$30",
			status: "Rejected",
		},
		{
			key: 2,
			class: "Math O Level",
			student: "Vincent Kompany",
			date_requested: "Fri, 20 Jun 2019",
			date_payout: "Fri, 20 Jun 2019",
			amount: "$45",
			status: "Rejected",
		},
		{
			key: 3,
			class: "Science N Level",
			student: "Adam Lambert",
			date_requested: "Thu, 19 Jun 2019",
			amount: "$75",
			status: "Accepted",
		},
	],
}

export const requestPayout = {
	status: [
		{
			key: "All",
			value: "All",
			label: "All",
		},
		{
			key: "Available",
			value: "Available",
			label: "Available",
		},
		{
			key: "Pending",
			value: "Pending",
			label: "Pending",
		},
	],
	type: [
		{
			key: "All",
			value: "All",
			label: "All",
		},
		{
			key: "Flexible",
			value: "Flexible",
			label: "Flexible",
		},
		{
			key: "Fixed",
			value: "Fixed",
			label: "Fixed",
		},
	],
	payment: [
		{
			key: "All",
			value: "All",
			label: "All",
		},
		{
			key: "Paid",
			value: "Paid",
			label: "Paid",
		},
		{
			key: "Rejected",
			value: "Rejected",
			label: "Rejected",
		},
	],
	columns: [
		{
			title: "Payout status",
			dataIndex: "payout_status",
			key: "payout_status",
			render: (t) => <Badge color={t === "Available" ? "green" : "red"} text={t} />,
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Class",
			dataIndex: "class",
			key: "class",
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
		},
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
		},
		{
			title: "Amount",
			dataIndex: "amount",
			key: "amount",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (t) => <Badge color={t === "Accepted" ? "green" : "red"} text={t} />,
		},
	],
	data: [
		{
			key: 1,
			payout_status: "Pending",
			name: "Gerry Neville",
			class: "Art O Level",
			type: "Flexible",
			date: "21 January 2019",
			amount: "$30",
			status: "Rejected",
		},
		{
			key: 2,
			payout_status: "Available",
			name: "Vincent Kompany",
			class: "Math O Level",
			type: "Flexible",
			date: "Fri, 20 Jun 2019",
			amount: "$45",
			status: "Rejected",
		},
		{
			key: 3,
			payout_status: "Available",
			name: "Adam Lambert",
			class: "Science N Level",
			type: "Fixed",
			date: "Thu, 19 Jun 2019",
			amount: "$75",
			status: "Accepted",
		},
	],
}

export const orderedNumber = Array.from(
	{
		length: 10,
	},
	(_, i) => ({
		key: i + 1,
		value: i + 1,
		label: i + 1,
	})
)

export const chatList = [
	{
		id: 1,
		type: "text",
		sender: "them",
		message: "Halo",
		time: "02-02-2020 16:12",
	},
	{
		id: 2,
		type: "text",
		sender: "me",
		message: "Yes, how can I help you?",
		time: "02-02-2020 16:13",
	},
	{
		id: 3,
		type: "text",
		sender: "them",
		message: "What about some chitchat in another space?",
		time: "02-02-2020 16:14",
	},
	{
		id: 4,
		type: "text",
		sender: "me",
		message:
			"Sure, what would you like to buy? A monochrome camera maybe? As we can see a lot of people getting used to use it outside.",
		time: "02-02-2020 16:15",
	},
	{
		id: 5,
		type: "text",
		sender: "them",
		message: "I don't know, you tell me.",
		time: "02-02-2020 16:16",
	},
]
