import React from "react"
import NoData from "antd/lib/empty"
import empty from "assets/images/empty.png"
import styled from "styled-components"
import Logo from "./Logo"

const StyledEmpty = styled(NoData)`
	.ant-empty-image {
		height: ${({ cudylogo }) => cudylogo ? "auto" : ""};
	}
	p {
		color: #ccc;
	}
`

function Empty({ description, boxImage = false, cudylogo = 0, ...props }) {
	return (
		<StyledEmpty
			{...props}
			cudylogo={cudylogo}
			description={description}
			image={(boxImage && empty) || (cudylogo && <Logo />) || NoData.PRESENTED_IMAGE_SIMPLE}
		/>
	)
}

export default Empty
