import React, { useState } from "react"
import { Trans } from "@lingui/macro"
import { Modal, Button, Tooltip, Loading } from "components"
import TutorCalendar from "../pages/Tutor/Profile/Calendar"
import { CheckOutlined } from "@ant-design/icons"

const Booking = ({ userData = {}, teachingDate = {}, loading, unauthorizedUser, userToken, tokenHandler, code }) => {
	const [showModal, setShowModal] = useState(false)
	const [editMode, setEditMode] = useState("")

	if (loading) return <Loading />;

	return (
		<>
			<Modal
				title={<Trans>Booking {userData.firstName}'s</Trans>}
				visible={showModal}
				onCancel={() => setShowModal(false)}
				footer={false}
				width="80%">
				<TutorCalendar
					onEditMode={{ editMode, setEditMode }}
					unauthorizedUser={unauthorizedUser}
					teachingDate={teachingDate}
					refetchData={() => setShowModal(false)}
					loading={loading}
					userData={userData}
					code={code}
				/>
			</Modal>
			<Tooltip title={<Trans>Booking this tutor</Trans>}>

				{!userToken ? (
					<Button type="primary" icon={<CheckOutlined />} onClick={tokenHandler} style={{ marginBottom: "1em" }}>
						&nbsp; <Trans>Book tutor</Trans>
					</Button>
				) : (
					<Button type="primary" icon={<CheckOutlined />} onClick={() => setShowModal(!showModal)} style={{ marginBottom: "1em" }}>
						&nbsp; <Trans>Book tutor</Trans>
					</Button>
				)}


			</Tooltip>
			<br />
		</>
	);
};

export default Booking;
