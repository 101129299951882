import * as Types from '../constants';
import { newClient, renderError } from 'helpers';
import { message } from 'antd';
import { outsideI18n } from 'App';
import { t } from '@lingui/macro';
import { loggedIn, loggedOut } from 'helpers/crossDomainDataSharing';

export const loadingAuth = () => ({ type: Types.LOADING_AUTH });

export const authUserOptions = (setError, fromParams, prevRoute, iframe) => ({
	client: newClient('account'),
	onCompleted: async ({ signIn = {}, singInToCommunity = {} }) => {
		try {
			const { token, firstTime, user = {}, companydata, communityToken } = Object.keys(signIn).length > 0 ? signIn : singInToCommunity;
			const { role, country } = user;
			const placeUrl = localStorage.getItem('placeUrl')
			if (!communityToken) {
				await loggedIn(token, fromParams, user, iframe);
			}
			const url =
				(firstTime && role.code === 'TTR0' && `/${country}/profile/onboarding_steps`) ||
				(firstTime && role.code === 'STDN' && `/${country}/profile/onboarding_students`) ||
				(role.code === 'CMPN' && placeUrl && `${placeUrl}`) ||
				(prevRoute && `/${country}${prevRoute}`) ||
				(fromParams !== '' && fromParams) ||
				`/${country}`;

			localStorage.setItem('i18n', country === 'id' ? 'id' : 'en');
			localStorage.setItem('country', country);
			localStorage.setItem('token', token);
			localStorage.setItem('userDetails', JSON.stringify(user));
			localStorage.setItem('firstTime', firstTime);
			localStorage.setItem('companyData', JSON.stringify(companydata));
			localStorage.setItem('communityToken', communityToken);




			message.loading(outsideI18n._(t`Logging you in...`)).then(() => {
				if (communityToken) {
					window.location.href = communityToken;
				} else if(!fromParams) {
					role?.code === 'CMPN' && placeUrl
						? window.location.href = url
						: window.location.replace(url)
				}
			});

			return {
				token, user
			}
		} catch (err) {
			console.log(err);
		}
	},
	onError: (err, netError) => {
		const error = renderError(err);
		setError({ code: error.code, message: error.message });
	}
});

export const unauthUser = {
	client: newClient('account'),
	onCompleted: async (data) => {
		if (data) {
			// await loggedOut();
			localStorage.removeItem('i18n');
			localStorage.removeItem('country');
			localStorage.removeItem('token');
			localStorage.removeItem('userDetails');
			localStorage.removeItem('firstTime');
			message.loading(outsideI18n._(t`Logging you out...`)).then(() => {
				window.location.replace('/');
			});
		}

		// socket.emit("leave_channel", { token: userDetails.token })
	},
	onError: (err) => renderError(err)
};
