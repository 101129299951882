
// converts string to an arr which is comma separated and limits the arr length to 10
export const LimitAndSplit = dependency => {
    let arr = [];
    if (dependency) {
        arr = dependency.split(',');
        arr?.length > 10 && arr.splice(10);
        return arr;
    }
    return arr;
};

// limits a string length to be >= 250
export const LimitDescription = des => {
    if (des.length >= 250) {
        const newDes = des.slice(0, 250).concat('...');
        return newDes;
    }
    return des;
};
