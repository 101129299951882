import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from './reducers';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const middleware = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['favorite']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export function createAppStore() {
	let store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middleware)));
	let persistor = persistStore(store);
	return { store, persistor };
}
