import React from "react"
import Tabs from "antd/lib/tabs"
import styled from "styled-components"

const StyledTab = styled(Tabs)`
	.ant-tabs-nav {
		width: ${({ sameWidth }) => sameWidth && "100%"};
		.ant-tabs-tab {
			width: ${({ sameWidth }) => (sameWidth ? "50%" : undefined)};
			text-align: ${({ sameWidth }) => sameWidth && "center"};
			margin: ${({ sameWidth }) => sameWidth && 0};
		}
	}
	.ant-tabs-bar {
		margin-bottom: 3em;
	}
	.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
		width: ${({ sameWidth }) => sameWidth && "100%"};
	}
`

function Tab({ children, ...props }) {
	return <StyledTab {...props}>{children}</StyledTab>
}

export default Tab
