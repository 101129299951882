import React from 'react';
import { Avatar, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import { useHistory } from 'react-router-dom';
import Button from '../../../Button';
import { availableCountryForBankAccountAddress as countries } from '../../../../helpers/helperData';
import { countries as currencyCodeList } from '../../../../helpers/salaryCountries';
import { LimitDescription, LimitAndSplit } from '../functions/resultsFunctions';
import { ResultsContainer, Heading } from '../components/resultsComponents';

const Tutors = ({ data }) => {
	const {
		id,
		FirstName,
		LastName,
		Description,
		Address,
		level,
		Subjects,
		Prices,
		curriculum,
		experienceInYears,
		Country,
		Token,
		avatarData,
		testimonial,
		rating,
		skill
	} = data;

	const CTRY = country => {
		if (country?.length === 0) return '';
		let name = '';
		countries.forEach(e => {
			if (e.value === country.toUpperCase()) name = e.label;
		});
		return name;
	};

	const currencyCode = country => {
		if (country?.length === 0) return '';
		let code = '';
		Object.keys(currencyCodeList).forEach(e => {
			if (e === String(country).toLowerCase()) code = currencyCodeList[e].code;
		});
		return code;
	};

	const countryName = CTRY(Country);
	const currency = currencyCode(countryName);

	const colorsArr = ['magenta', 'red', 'volcano', 'orange', 'lime', 'green', 'cyan', 'blue', 'geekblue', 'purple'];

	// returns the lowest price
	const getPrice = priceStr => {
		const priceArr = LimitAndSplit(priceStr);
		if (priceArr.length > 1) {
			const newArr = [];
			priceArr.map(e => {
				newArr.push(parseFloat(e));
			});
			const lowest_price = Math.min(...newArr);
			return lowest_price;
		}
		return priceArr[0];
	};

	const skillsArr = LimitAndSplit(skill);
	const subjectsArr = LimitAndSplit(Subjects);
	const price = getPrice(Prices);
	const des = LimitDescription(Description);

	let history = useHistory();

	const VisitProfile = () => {
		const country = localStorage.getItem('country') || 'sg';
		const genPath = `/${country}/${Token}--${id}--${FirstName}--${LastName}`;
		const newPath = encodeURI(genPath);
		history.push(newPath);
	};

	return (
		<ResultsContainer>
			<AvatarContainer>
				<div>
					{avatarData ? (
						<Avatar size={100} src={process.env.REACT_APP_BASE_URL.concat(avatarData)} />
					) : (
						<Avatar size={100} style={{ backgroundColor: 'orange' }} icon={<UserOutlined />} />
					)}
					<AvatarName>
						<Trans>{FirstName + ' ' + LastName}</Trans>
					</AvatarName>
				</div>
			</AvatarContainer>
			<DescriptionContainer>
				<Trans>{des}</Trans>
			</DescriptionContainer>
			<FlexGroup>
				<LocationContainer>
					<Heading>
						<Trans>location</Trans>
					</Heading>
					{Address ? <Trans>{Address + ', ' + countryName}</Trans> : <Trans>{countryName}</Trans>}
				</LocationContainer>
				<ExperienceContainer>
					<Heading>
						<Trans>Experience</Trans>
					</Heading>
					{experienceInYears ? <div>{experienceInYears} yrs</div> : <div>0 yrs</div>}
				</ExperienceContainer>
			</FlexGroup>
			{/* {level && (
				<LevelContainer>
					<Heading>
						<Trans>Level</Trans>
					</Heading>
					{level}
				</LevelContainer>
			)} */}
			{subjectsArr.length !== 0 && (
				<SubjectsContainer>
					<Heading>
						<Trans>Subjects</Trans>
					</Heading>
					{subjectsArr.map((e, i) => {
						return (
							<Tag color="gold" key={i} style={{ marginBottom: '0.4rem' }}>
								<Trans>{e}</Trans>
							</Tag>
						);
					})}
				</SubjectsContainer>
			)}
			{Prices && (
				<PricesContainer>
					<Heading>
						<Trans>Price</Trans>
					</Heading>
					<Trans>{currency + ' ' + price}</Trans>
				</PricesContainer>
			)}
			{/* {curriculum && (
				<CurriculumContainer>
					<Heading>
						<Trans>Curriculum</Trans>
					</Heading>
					{curriculum}
				</CurriculumContainer>
			)} */}
			<TestimonialContainer>
				<Heading>
					<Trans>testimonial</Trans>
				</Heading>
				{testimonial}
			</TestimonialContainer>
			{rating && (
				<div>
					<Heading>
						<Trans>rating</Trans>
					</Heading>
					{rating}
				</div>
			)}
			{skillsArr.length !== 0 && (
				<SkillContainer>
					<Heading style={{ paddingBottom: '0.5rem' }}>
						<Trans>skills</Trans>
					</Heading>
					{skillsArr.map((e, i) => {
						return (
							<Tag color={colorsArr[i]} key={e} style={{ marginBottom: '1rem' }}>
								<Trans>{e}</Trans>
							</Tag>
						);
					})}
				</SkillContainer>
			)}

			<VisitProfileContainer>
				<Button type="primary" size="medium" onClick={VisitProfile} style={{ position: 'absolute', bottom: '1rem', width: '15%' }}>
					<Trans>
						<p style={{ fontSize: '0.9rem' }}>View Profile</p>
					</Trans>
				</Button>
			</VisitProfileContainer>
		</ResultsContainer>
	);
};

export default Tutors;

// ███████ ████████ ██    ██ ██      ███████ ███████
// ██         ██     ██  ██  ██      ██      ██
// ███████    ██      ████   ██      █████   ███████
//      ██    ██       ██    ██      ██           ██
// ███████    ██       ██    ███████ ███████ ███████

const AvatarContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 0.8rem;
`;

const AvatarName = styled.div`
	padding-top: 0.8rem;
	padding-left: 0.5rem;
	font-size: 1.1rem;
	font-weight: 500;
	color: #555555;
`;

const DescriptionContainer = styled.div`
	text-align: center;
	padding-top: 0.5rem;
	width: 100%;
	padding-bottom: 0.5rem;
`;

const FlexGroup = styled.div`
	display: flex;
	gap: 2rem;
	justify-content: start;
	padding-top: 1rem;
`;

const LocationContainer = styled.div`
	padding-bottom: 0.5rem;
`;

const LevelContainer = styled.div`
	padding-bottom: 0.5rem;
`;

const TestimonialContainer = styled.div`
	padding-bottom: 0.5rem;
`;

const ExperienceContainer = styled.div`
	padding-bottom: 0.5rem;
`;

const SubjectsContainer = styled.div`
	padding: 0.5rem 0rem;
`;

const PricesContainer = styled.div`
	padding-bottom: 0.5rem;
`;

const CurriculumContainer = styled.div`
	padding-bottom: 0.5rem;
`;

const SkillContainer = styled.div`
	padding-bottom: 0.5rem;
`;

const VisitProfileContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;
