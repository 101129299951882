import { SEARCH_VALUE, HAS_RESULTS } from '../constants';

export const searchValue = (str) => {
    return {
        type: SEARCH_VALUE,
        payload: str
    }
};

export const searchResult = (val) => {
    return {
        type: HAS_RESULTS,
        payload: val
    }
};