import styled from 'styled-components';

export const ResultsContainer = styled.div`
	width: 30.78vw;
	height: 70vh;
	overflow-x: auto;
	padding: 0.5rem;
	background-color: white;
	border-left: 1px solid #e6e6e6;
	border-bottom-right-radius: 1rem;
	padding: 0rem 1rem;
`;

export const Heading = styled.div`
	text-transform: uppercase;
	font-size: 0.8rem;
	font-weight: 500;
	color: orange;
`;
