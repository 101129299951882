import React from "react"
import { Progress } from "antd"
import { baseStyles } from "styles/base"
import styled from "styled-components"

const StyledProgress = styled(Progress)`
	.ant-progress-inner {
		.ant-progress-text {
			color: ${({ percent, color }) => percent === 100 && color.greenColor.primary};
		}
	}
`

export default function Percentage(props) {
	const { percent = 0 } = props
	const { primaryColor, greenColor, yellowColor } = baseStyles

	return (
		<StyledProgress
			{...props}
			status="active"
			color={{ greenColor }}
			strokeColor={{
				"0%": percent === 100 ? greenColor.primary : primaryColor,
				"100%": percent === 100 ? greenColor.light : yellowColor
			}}
		/>
	)
}
