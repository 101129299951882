import io from "socket.io-client";

let socket;

socket = io(process.env.REACT_APP_NOTIFICATION_MICROSERVICE_URL);

export const joinNotifChannel = (token) => {
  socket.emit("join", { channel: token })
}

// export const disconnectNotifChannel = () => {
//   socket.emit("leave_channel", { channel: userDetails.token })
//   socket.off()
// }


export const getNotifSocket = () => socket