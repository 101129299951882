export const typesenseSearch = {
    apiKey: `${process.env.REACT_APP_TYPESENSE_SEARCH_API_KEY}`,
    connectionTimeoutSeconds: 30,
    nodes: [
        {
            host: `${process.env.REACT_APP_TYPESENSE_HOST}`,
            port: `${process.env.REACT_APP_TYPESENSE_PORT}`,
            protocol: `${process.env.REACT_APP_TYPESENSE_PROTOCOL}`,
        },
    ],
};