import React, { useState } from 'react';
import { HomeOutlined, MenuOutlined } from '@ant-design/icons';
import { Layout as PageLayout, Breadcrumb, Button, Affix } from 'antd';
import Navbar from './Navbar';
import styled from 'styled-components';
import Section from './Section';
import { Link, useLocation, useHistory } from 'react-router-dom';
import SidebarMenu from './common/SidebarMenu';
import useMobile from '../helpers/hooks/useMobile';
import { media } from 'helpers';
import { country } from 'App';
import Footer from './Footer';

const Header = styled(PageLayout.Header)`
	&& {
		background-color: #fff;
		height: auto;
		padding: 0;
		${media.mobile`
            height: auto;
        `}
	}
`;

const Bread = styled(Breadcrumb)`
	margin-bottom: 2em;
`;

const Sidebar = styled(PageLayout.Sider)`
	&& {
		min-height: 100vh;
		background: #fff;
		border-right: 1px solid #e8e8e8;
		.ant-layout-sider-trigger {
			background: #ff9d00;
		}
	}
`;

const pathMap = {
	'/browse/tutors': 'Browse tutors',
	'/profile/me': 'My profile',
	'/profile/viewed': "Who's viewed me",
	'/profile': 'Profile',
	'/assignments': 'Assignments',
	'/assignments/post_assignment': 'Post an assignment',
	'/browse': 'Tutors'
};

const unlinkedUrl = ['/profile', '/browse'];

function Layout({ breadcrumb = false, customBread = '', className, ...props }) {
	const { basic = false, sidebar = false, children, footer = true } = props;
	const isMobile = useMobile('(max-width: 414px)');
	const location = useLocation();
	const history = useHistory();

	const [collapsed, setCollapsed] = useState(true);
	const pathSnippets = location.pathname.split('/').filter(item => item);

	const extraBreadcrumbItems = pathSnippets.map((_, index) => {
		const url = `/${country}/${pathSnippets.slice(1, index + 1).join('/')}`;
		const isNotClickable = unlinkedUrl.filter(item => url.includes(item));

		if (customBread) return <Breadcrumb.Item>{customBread}</Breadcrumb.Item>;

		return <Breadcrumb.Item key={url}>{isNotClickable.length > 0 ? pathMap[url] : <Link to={url}>{pathMap[url]}</Link>}</Breadcrumb.Item>;
	});

	const breadcrumbItems = [
		<Breadcrumb.Item key="home">
			<Link to={`/${country}`}>
				<HomeOutlined />
			</Link>
		</Breadcrumb.Item>,
		...extraBreadcrumbItems
	];

	return (
		<PageLayoutStyled className={className || ''}>
			{!basic && (
				<Header>
					<Navbar loading={props.loading} onUnauthUser={props.unauthUser} language={props.language} />
				</Header>
			)}

			<PageLayout.Content>
				<PageLayout>
					{sidebar && (
						<Sidebar
							collapsible
							collapsed={collapsed}
							onMouseEnter={() => setCollapsed(false)}
							onMouseLeave={() => setCollapsed(true)}
							trigger={!isMobile ? <Button icon={<MenuOutlined />} type="link" /> : ''}
							breakpoint="lg"
							collapsedWidth={isMobile ? 0 : 0}>
							<Affix offsetTop={20}>
								<SidebarMenu onSetCollapsed={setCollapsed} history={history} />
							</Affix>
						</Sidebar>
					)}

					<PageLayout>
						<PageLayout.Content>
							{breadcrumb && (
								<Section paddingHorizontal="padded">
									<Bread>{breadcrumbItems}</Bread>
								</Section>
							)}
							{children}
						</PageLayout.Content>
						{(footer || sidebar) && <Footer />}
					</PageLayout>
				</PageLayout>
				{/* <StickyButton>
					<Trans>
						<Button type="dashed" icon="coffee" id="show-updates">
							Show updates
						</Button>
					</Trans>
				</StickyButton> */}
			</PageLayout.Content>

			{/* {(footer || (!basic && !sidebar)) && <Footer />} */}
		</PageLayoutStyled>
	);
}

export default Layout;

//styles

const PageLayoutStyled = styled(PageLayout)`
	padding-top: 66.5px;

	${media.mobile`
	padding-top:48px;

	`}
`;
