import React from 'react';
import styled from 'styled-components';
import {Skeleton} from "antd"

const StyledSkeleton = styled.div`
	display : flex;
	flex-wrap : wrap;
	height : 355px;
	overflow : hidden;

`

const OuterDiv = styled.div`
	position : relative;
	width : 230px;
	margin : 0 auto;

	@media (max-width : 1400px){
		width : 200px;
	}

	@media (max-width : 750px){
		width : 300px;
	}
`

const SkelInp = styled.div`
	width : 100%;
	position : relative;
	background-color : white;
	border-radius : 8px;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.1);

`
const StyledDiv = styled.div`
	position : absolute;
	top : 10px;
	left : 40px;
	width : 150px;

	@media (max-width : 1400px){
		position : absolute;
		top : 10px;
		left : 20px;
	}

	@media (max-width : 750px){
		position : absolute;
		top : 10px;
		left : 50px;
	}

	@media (max-width : 350px){
		position : absolute;
		top : 10px;
		left : 30px;
	}
`

const StyledComp = styled(Skeleton.Input)`
	width : 150px;
	height : 30px;
	margin: 20px auto;
	border-radius : 8px;
`

const StyledSkel = styled(Skeleton)`
	height : 30px;
	margin: 5px auto;

	@media (max-width : 750px){
		width : 200px;
	}
`
  
const StyledCircle = styled.div`
	width : 80px;
	height : 80px;
	background-color : #E4E5E5;
	border-radius : 80px;
	margin : 10px auto;
`
  const CardSkelton = () => {
	  const arr = [1,2,3,4];
   	  return (
		<StyledSkeleton>
			{arr.map(() => (
				<OuterDiv>
					<SkelInp style = {{height : '350px'}}  active={true} size= "default"/>
					<StyledDiv>
						<StyledCircle />
						<StyledSkel active />
						<StyledComp active = {true} />
					</StyledDiv>
				</OuterDiv>
			))}
        </StyledSkeleton>
	  );
	}
  

  

export default CardSkelton;