import React , { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { country } from 'App';
import { mobile, media } from 'helpers';
import { baseStyles } from 'styles/base';
import DynamicIcon from 'components/common/DynamicIcon';






const  ToolsMenu = ({ sliderifram, planneriframe}) => {
	
	const src_slides = 'https://slides.cudy.co/iframe.html';

	const src_planner = process.env.REACT_APP_MODE === "development" ? "http://localhost:3001" : "https://planner.cudy.co";
	
	const onClick = () => {
		return new Promise((resolve, reject) => {
			try {
				planneriframe.style.display = "none";
				planneriframe.src = src_planner + '/iframe.html';
				document.body.appendChild(planneriframe);
	
			} catch (error) {
				console.log(error)
				resolve();
			}
		});
	};

	
	
	
	const onClickSlides = () => {
		sliderifram.src = src_slides;
		document.body.appendChild(sliderifram);
		return new Promise((resolve, reject) => {
			try {
				sliderifram.style.display = "none";
	
	
			} catch (error) {
				resolve();
			}
		});
	}


	return (
		<ServicesMenu>
			<ServicesCategoryWrapper>
				<ServicesWrapper>
					<CategoryHeading>Learning Tools</CategoryHeading>
					<a onClick={async () => {
						await onClickSlides();
					}}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-Slideshow'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Slides</span>
						</Service>
					</a>

					{/* <a href={process.env.REACT_APP_MODE === "development" ? "http://localhost:3001" : ""}> */}
					<Service onClick={async () => {
						await onClick();
					}}>
						<DynamicIcon projectName="Cudy3.0" type={'icon-planner'} size={mobile ? 34 : 32} />
						<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Planner</span>
					</Service>
					{/* </a> */}


					<Link to={'/' + country + '/portfolio/leaderboard'}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-icon-test29'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Portfolio</span>
						</Service>
					</Link>
				</ServicesWrapper>
				<ServicesWrapper style={{ paddingTop: 43 }}>
					<Link to={'/' + country + '/launching-soon'}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-icon-test15'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Software</span>
							<ComingSoon>Coming Soon </ComingSoon>
						</Service>
					</Link>
					<a href={'https://lms.cudy.co'}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-icon-test21'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy LMS 2.0</span>
						</Service>
					</a>
				</ServicesWrapper>
				<ServicesWrapper style={{ paddingTop: 43 }}>
					<Link to={'/' + country + '/launching-soon'}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-icon-test22'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Hub</span>
							<ComingSoon>Coming Soon </ComingSoon>
						</Service>
					</Link>
					<Link to={'/' + country + '/cudytranscribe'}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-transcribe'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Scribe</span>
						</Service>
					</Link>
				</ServicesWrapper>
			</ServicesCategoryWrapper>
		</ServicesMenu>
	);
}


export default ToolsMenu;

//styles

const Tool = styled.span`
	margin: 20px 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	text-align: center;
	color: #777;
	width: 100px;
	position: relative;
`;
const ToolsMenuStyled = styled.div`
	background-color: #fff;
	width: 360px;
	padding: 1.5rem 1.2rem;

	border-radius: ${baseStyles.borderRadius};
	box-shadow: ${baseStyles.boxShadow.main};
	transform: translateY(1.4rem);

	${media.mobile`
	width:100vw;
	transform: translateY(0.8rem);
	`}
`;
const ToolsHeading = styled.h3`
	margin: 0;
`;

const ToolsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
`;

//

const Service = styled.span`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	text-align: center;
	color: #777;
	background-color: #fff;
	padding: 10px 20px;
	margin: 10px auto;
	border-radius: 4px;
	border: 1px solid ${baseStyles.lightGrey.one};
	width: 200px;
	box-shadow: ${baseStyles.boxShadow.hover};
	border-radius: 10px;
	position: relative;
	cursor: pointer;
	${media.mobile`
	width:200px`};
`;
const ServicesMenu = styled.div`
	background-color: ${baseStyles.lightGrey.two};
	width: 100vw;
	padding: 2rem 1.2rem;
	user-select: none;

	${media.mobile`
	height:100vh;
        overflow-y:scroll;
	`}
`;

const ServicesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-start;

	${media.mobile`
	&& {
		padding:0 3rem;
		margin:0px;
		flex-wrap:wrap;

	}
	`}
`;

const ServicesCategoryWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: flex-start;

	${media.mobile`
    flex-direction:column;
	align-items: center;
    `}
`;
const CategoryHeading = styled.h3`
	margin-top: 10px;
`;

const ComingSoon = styled.span`
	position: absolute;
	right: 5px;
	top: 3px;
	color: ${baseStyles.primaryColor};
	font-size: 0.5rem;
`;
