import React, { useState } from 'react';

import {
	AppstoreOutlined,
	BellOutlined,
	MessageOutlined,
	NotificationOutlined,
	ProjectOutlined,
	SettingOutlined,
	UserOutlined
} from '@ant-design/icons';

import { Badge, Col, Divider, Drawer, Menu, Row, Tooltip } from 'antd';
import NotificationPanel from './NotificationPanel';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Search from '../forms/Search';
import Button from 'components/Button';
import { media, mobile } from 'helpers';
import { Trans } from '@lingui/macro';
import { country } from 'App';
import DynamicIcon from './DynamicIcon';
import { countries } from 'helpers/countryMapping';
import { Heading, Percentage } from 'components';
import ProfileCompletion from './ProfileCompletion';
import { useBottomBarLinks } from 'helpers/hooks/data';
import Favourite from 'components/Favourite';

const LoggedinItems = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	min-width: 120px;
	.ant-badge {
		margin-right: 2em;
		button {
			margin-top: 7px;
			border-color: #d9d9d9;
			+ sup {
				top: 5px;
			}
		}
	}

	${media.mobile`
        display: block;
        .ant-menu {
            border-right: none;
            margin-bottom: 2em;
        }
        .ant-badge {
            margin-right: 0;
            display: block;
        }
    `}
`;

const UnloggedinItems = styled.div`
	text-align: right;
	margin-bottom: 2em;

	${media.mobile`
        > * {
            display: block;
        }
    `}
`;

const ProfileComplete = styled(Row)`
	.ant-typography {
		h4 {
			font-size: 1em;
		}
	}
`;

export default function MenuDrawer({ data, handlers, loading }) {
	const { push } = useHistory();
	const { isPageLifelong, isCounselling } = useBottomBarLinks();
	const { userData, menuDrawer, reviews, notifList } = data;
	const { showDrawer, setDrawer, setMenuDrawer, setSignout, handleSendKeywords, fetchUser } = handlers;
	const [profileCompletionDrawer, setProfileCompletionDrawer] = useState(false);

	const token = localStorage.getItem('token');
	const detailsFromStorage = JSON.parse(localStorage.getItem('userDetails')) || {};
	const isTutor = (detailsFromStorage.role || {}).code === 'TTR0';
	const isStudent = (detailsFromStorage.role || {}).code === 'STDN';
	const { completionUser = {}, completionDetail = [] } = userData;
	const percent = completionUser.completionPercent;

	const handleOpenCompletionDrawer = () => {
		fetchUser();
		setProfileCompletionDrawer(true);
	};

	const cudyMenu = (
		<Menu onClick={({ key }) => push(`/${country}/${key}`)}>
			{isPageLifelong ? <Menu.Item key="browse/tutors">Tutors</Menu.Item> : <Menu.Item key="lifelong/browse">LifeLong</Menu.Item>}
			{isCounselling ? <Menu.Item key="browse/tutors">Tutors</Menu.Item> : <Menu.Item key="counselling">Counsellor</Menu.Item>}
		</Menu>
	);
	return (
		<Drawer
			title={token ? <span>Hi, {userData.firstName || userData.roleName}</span> : 'Menu'}
			placement="right"
			onClose={() => setMenuDrawer(false)}
			visible={menuDrawer}
			width={320}>
			<ProfileCompletion
				data={{ completionUser, completionDetail }}
				placement="right"
				loading={loading}
				visible={profileCompletionDrawer}
				onClose={() => setProfileCompletionDrawer(false)}
			/>
			<NotificationPanel visible={showDrawer} onClose={() => setDrawer(false)} data={reviews} />
			{token ? (
				<LoggedinItems>
					<Menu>
						{/* {(detailsFromStorage.role || {}).code === "STDN" && (
							<Menu.Item key="assignment">
								<Link to={`/${country}/assignments`}>
									<Trans>
										<Icon type="trophy" /> &nbsp; Assignments
									</Trans>
								</Link>
							</Menu.Item>
						)} */}
						{isTutor && (
							<Menu.Item key="completion" style={{ height: 80 }} onClick={handleOpenCompletionDrawer}>
								<ProfileComplete gutter={32} type="flex" align="middle">
									<Col lg={6} xs={6}>
										<Percentage type="circle" percent={percent} width={50} />
									</Col>{' '}
									<Col lg={18} xs={18}>
										<Heading
											content={percent === 100 ? <Trans>You've made it!</Trans> : <Trans>You're almost there</Trans>}
											subheader={percent === 100 ? <Trans>Your profile is complete</Trans> : <Trans>Complete your profile</Trans>}
											level={4}
											marginBottom="0"
										/>
									</Col>
								</ProfileComplete>
							</Menu.Item>
						)}
						{isTutor && (
							<Menu.Item key="cudyclass">
								<Link to={`/${country}/cudy-classes`}>
									<Trans>
										<AppstoreOutlined /> &nbsp; My online classes
									</Trans>
								</Link>
							</Menu.Item>
						)}
						{isTutor && (
							<Menu.Item key="profile">
								<Link to={`/${country}/profile/me`}>
									<Trans>
										<UserOutlined /> &nbsp; Your profile
									</Trans>
								</Link>
							</Menu.Item>
						)}
						<Menu.Item key="chats">
							<Link to={`/${country}/chats`}>
								<Trans>
									<MessageOutlined /> &nbsp; Chats
								</Trans>
							</Link>
						</Menu.Item>
						{isStudent && (
							<Menu.Item key="assignments">
								<Link to={`/${country}/assignments`}>
									<Trans>
										<ProjectOutlined /> &nbsp; Your assignments
									</Trans>
								</Link>
							</Menu.Item>
						)}
						<Menu.Item key="1">
							<Link to={`/${country}/profile/settings`}>
								<Trans>
									<SettingOutlined /> &nbsp; Settings
								</Trans>
							</Link>
						</Menu.Item>
						<Menu.Item key="updates" id="show-updates">
							<Trans>
								<NotificationOutlined /> &nbsp; Cudy updates
							</Trans>
						</Menu.Item>
						<Menu.Item key="3" onClick={() => setSignout()}>
							<Trans>Logout</Trans>
						</Menu.Item>
					</Menu>
					<Divider />
				</LoggedinItems>
			) : (
				<UnloggedinItems>
					<Link to={`/${country}/login`}>
						<Button type="ghost" size="default" block={mobile}>
							<Trans>Login</Trans>
						</Button>
					</Link>
					&nbsp; &nbsp;
					<Link to={`/${country}/register`}>
						<Button type="primary" size="default" block={mobile}>
							<Trans>Sign up</Trans>
						</Button>
					</Link>
				</UnloggedinItems>
			)}
			<Row>
				<Col lg={24} xs={24} style={{ marginBottom: '4em' }}>
					<Search size="large" onSearch={handleSendKeywords} width="100%" placeholder="Search for tutors..." />{' '}
					{/* <Divider>
						<Trans>or</Trans>
					</Divider>
					<Link to={`/${country}/${isPageLifelong ? `browse/tutors` : `lifelong/browse`}`}>
						<Button type="primary" size="medium" block className="mb1em">
							<DynamicIcon type={`icon-${isPageLifelong ? 'book' : 'guitar'}`} />
							&nbsp; <Trans>Go to Cudy</Trans> <span style={{ marginLeft: 2 }}>{isPageLifelong ? ' Academics' : ' Lifelong'}</span>
						</Button>
					</Link>
					<Link to={`/${country}/${isCounselling ? `browse/tutors` : `counselling`}`} className="">
						<Button type="primary" size="medium" block>
							<DynamicIcon type={`icon-${isCounselling ? 'book' : 'CloudSunWeatherTe'}`} />
							&nbsp; <Trans>Go to Cudy</Trans> <span style={{ marginLeft: 2 }}>{isCounselling ? ' Academics' : ' Counselling'}</span>
						</Button>
					</Link> */}
					{/* <Dropdown overlay={cudyMenu}>
						<Button type="primary" size="medium" block>
							<DynamicIcon type={`icon-${isPageLifelong ? "book" : "guitar"}`}/>
							&nbsp; <Trans>Go to Cudy</Trans> <Icon type="down"/>
						</Button>
					</Dropdown> */}
				</Col>
			</Row>
			{token && (
				<>
					<Row type="flex" justify="center" className="mb2em">
						<Col xs={24} style={{ textAlign: 'center' }}>
							<Trans>
								<Favourite />
							</Trans>
						</Col>
					</Row>
					<Row type="flex" justify="center" className="mb2em">
						<Col xs={24} style={{ textAlign: 'center' }}>
							<Badge count={notifList.length || 0} onClick={() => setDrawer(!showDrawer)}>
								<BellOutlined /> <Trans style={{ marginLeft: 20 }}>See notifications</Trans>
							</Badge>
						</Col>
					</Row>
				</>
			)}
			{/* <Row type="flex" justify="center">
				{countries.map(item => (
					<Col key={item.key} xs={6} style={{ textAlign: 'center', marginBottom: '1em' }}>
						<Button type="link" shape="circle" onClick={() => handlers.handleChangeLanguage(item.key)}>
							<Tooltip title={item.name}>
								<DynamicIcon size={28} type={item.icon} />
							</Tooltip>
						</Button>
					</Col>
				))}
			</Row> */}
		</Drawer>
	);
}
