import { gql } from 'apollo-boost';

export const ADD_ITEMS_TO_CART = gql`
	mutation addItemToCart($itemId: String!, $itemType: String!, $quantity: Int!) {
		addItemToCart(itemId: $itemId, itemType: $itemType, quantity: $quantity)
	}
`;

export const GET_USER_CART_ITEMS = gql`
	mutation getUserCartItems($voucherId: String){
		getUserCartsItems (voucherId: $voucherId){
			totalPrice
			currency
			orginalTotalPrice
			totalDiscountAmount
			bookingCart {
				bookingPrices
				currency
				bookingDiscountAmount
				orginalBookingPrice
				bookingItems {
					bookingId
					startTime
					endTime
					location
					duration
					type
					status
					subject
					hourPrice
					totalPrice
					tutor {
						firstName
						lastName
						avatar
					}
				}
			}
			contentCart {
				contentPrices
				orginalContentPrice
				currency
				contentDiscountAmount
				contentItems {
					id
					seller {
						firstName
						lastName
						avatar
					}
					title
					description
					price
					currency
					quantity
					subject
					createdAt
					reviewFile {
						path
						type
					}
				}
			}
		}
	}
`;

export const CHECKOUT_USING_BALANCE = gql`
	mutation {
		checkoutCartWithCudyBalance
	}
`;

export const DELETE_ITEM_FROM_CART = gql`
	mutation deleteItemFormCart($itemId: String!, $itemType: String!) {
		deleteItemFormCart(itemId: $itemId, itemType: $itemType)
	}
`;

export const INCREASE_BY_ONE = gql`
	mutation increaseItemQuantityByOne($itemId: String!, $itemType: String!) {
		increaseItemQuantityByOne(itemId: $itemId, itemType: $itemType)
	}
`;

export const DECREASE_BY_ONE = gql`
	mutation decreaseItemQuantityByOne($itemId: String!, $itemType: String!) {
		decreaseItemQuantityByOne(itemId: $itemId, itemType: $itemType)
	}
`;


