import React from "react"
import Section from "./Section"
import { Row, Col, Typography } from "antd"
import DynamicIcon from "./common/DynamicIcon"
import styled from "styled-components"
import { baseStyles } from "styles/base"
import { mobile } from "helpers"

const StyledSection = styled(Section)`
	border-radius: 10px;
	position: relative;
	padding-left: 1em;
	padding-right: 1em;
	box-shadow: ${baseStyles.boxShadow.hover};
`

export default function AdSection({ title, description, icon, bg }) {
	return (
		<StyledSection bg={bg || "aliceblue"}>
			<Row type="flex" align="middle" gutter={mobile && 16}>
				<Col lg={4} xs={4} className="ta-center">
					<DynamicIcon type={icon || "icon-CloudSunWeatherTe"} size={46} />
				</Col>
				<Col lg={20} xs={20}>
					<Typography.Paragraph className="mb0">{title}</Typography.Paragraph>
					<Typography.Text type="secondary">{description}</Typography.Text>
				</Col>
			</Row>
		</StyledSection>
	)
}
