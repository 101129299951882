import React from "react"
import _Tag from "antd/lib/tag"
import styled from "styled-components"

const StyledTag = styled(_Tag)`
    display: inline-block;
`

export default function Tag(props) {
    return <StyledTag {...props} />
}
