import * as types from "../constants"
import { countries, countryMap } from "helpers/countryMapping"
// import { country } from "App"
import { range } from "helpers"

const country = localStorage.getItem("country") || "sg"

const initials = {
	loading: false,
	products: [],
	categories: [],
	categoryOptions: [],
	consultants: [],
	ecommerceSites: [],
	lifelongSliders: [],
	assessments: [],
	assessmentOptions: [],
	assessmentCategories: [],
	assessmentResults: [],
	singleResult: {},

	consultantsError: null,
	productsError: null,
	categoriesError: null,
	ecommerceSitesError: null,
	lifelongSlidersError: null,
	assessmentsError: null,
	assessmentOptionsError: null,
	assessmentCategoriesError: null,
	assessmentResultsError: null
}

function reducer(state = initials, action) {
	const currentCountry = countryMap[country] && countryMap[country].name
	switch (action.type) {
		case types.LOADING_CONTENTFUL: {
			return { ...state, loading: true }
		}
		case types.FETCH_ECOMMERCE_PRODUCTS: {
			const productsUpdated = action.payload
				.filter((item) => item.countryAvailable.includes(currentCountry))
				.map(({ rebateAmount, ...item }) => {
					const rebate = rebateAmount[country] || 0
					return {
						...item,
						siteListings: item.siteListings.fields,
						images: item.images.map((image) => image.fields),
						prices: item.prices[country],
						rebate: { percent: rebate + "%", amount: (rebate / 100) * item.prices[country] }
					}
				})
			return { ...state, products: productsUpdated, loading: false }
		}

		case types.FETCH_SHOP_CATEGORIES: {
			const allCategories = action.payload.map((item) => item.category)
			const categories = [...new Set(allCategories)]
			const categoryOptions = categories.map((item) => ({ value: item, label: item }))
			return { ...state, categories, categoryOptions, loading: false }
		}

		case types.FETCH_ECOMMERCE_SITES: {
			const sitesWithRebates = action.payload
				.filter((item) => item.country === currentCountry)
				.map(({ rebateAmount, ...item }) => ({
					...item,
					rebate: { percent: (rebateAmount[country] || 0) + "%" }
				}))
			return { ...state, ecommerceSites: sitesWithRebates, loading: false }
		}

		case types.FETCH_CONSULTANTS: {
			const consultantsByCountry = action.payload
				.filter((item) => {
					const theCountry = countries.find((item) => item.key === country) || {}
					return theCountry.name === item.country
				})
				.map((item) => ({ ...item, whatsapp: item.whatsapp.startsWith("+") ? item.whatsapp.slice(1) : item.whatsapp }))
			return { ...state, consultants: consultantsByCountry, loading: false }
		}

		case types.FETCH_LIFELONG_SLIDERS: {
			const formattedSliders = action.payload
				.filter((item) => item.country.includes(currentCountry))
				.map((item) => ({ ...item, image: item.image.fields.file.url }))
			return { ...state, lifelongSliders: formattedSliders, loading: false }
		}

		case types.FETCH_ASSESSMENTS: {
			const formatted = action.payload.data.filter((item) => item.category.fields.slug === action.payload.category)
			const assessments = formatted.map((item) => ({
				...item,
				title: country === "id" ? item.titleId : item.title,
				question: country === "id" ? item.questionId : item.question
			}))
			return { ...state, assessments, loading: false }
		}

		case types.FETCH_ASSESSMENT_OPTIONS: {
			const assessmentOptions = action.payload.data
				.filter((item) => {
					if (action.payload.category === "focus") return item.forFocusTest === true
					return item.forFocusTest === false
				})
				.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0)) // Sort ascending
				.map((item) => ({
					...item,
					label: country === "id" ? item.labelId : item.label
				}))
			return { ...state, assessmentOptions, loading: false }
		}

		case types.FETCH_ASSESSMENT_CATEGORIES: {
			const formatted = action.payload.map((item) => ({
				...item,
				title: country === "id" ? item.titleId : item.title,
				description: country === "id" ? item.descriptionId : item.description
			}))
			return { ...state, assessmentCategories: formatted, loading: false }
		}

		case types.FETCH_ASSESSMENT_RESULTS: {
			const assessmentResults = action.payload
				.filter((item) => item.key === localStorage.getItem("quiz_taken"))
				.sort((a, b) => a.from - b.from)
				.map((item) => ({
					...item,
					color: item.resultLevel.fields.color,
					explanation: country === "id" ? item.explanationId : item.explanation,
					resultLabel: country === "id" ? item.resultLevel.fields.labelId : item.resultLevel.fields.label
				}));
			const singleResult = assessmentResults
				.map((item) => ({
					...item,
					color: item.resultLevel.fields.color,
					explanation: country === "id" ? item.explanationId : item.explanation,
					resultLabel: country === "id" ? item.resultLevel.fields.labelId : item.resultLevel.fields.label
				}))
				.find((item) => {
					const theRange = range(item.from, item.to)
					return theRange.includes(action.points)
				})
			return { ...state, assessmentResults, singleResult, loading: false }
		}

		//========================================================//

		case types.FETCH_ECOMMERCE_PRODUCTS_ERROR: {
			return { ...state, productsError: action.payload, loading: false }
		}
		case types.FETCH_CONSULTANTS_ERROR: {
			return { ...state, consultantsError: action.payload, loading: false }
		}
		case types.FETCH_SHOP_CATEGORIES_ERROR: {
			return { ...state, categoriesError: action.payload, loading: false }
		}
		case types.FETCH_ECOMMERCE_SITES_ERROR: {
			return { ...state, ecommerceSitesError: action.payload, loading: false }
		}
		case types.FETCH_LIFELONG_SLIDERS_ERROR: {
			return { ...state, lifelongSlidersError: action.payload, loading: false }
		}
		case types.FETCH_ASSESSMENTS_ERROR: {
			return { ...state, assessmentsError: action.payload, loading: false }
		}
		case types.FETCH_ASSESSMENT_OPTIONS_ERROR: {
			return { ...state, assessmentOptionsError: action.payload, loading: false }
		}
		case types.FETCH_ASSESSMENT_CATEGORIES_ERROR: {
			return { ...state, assessmentCategoriesError: action.payload, loading: false }
		}
		case types.FETCH_ASSESSMENT_RESULTS_ERROR: {
			return { ...state, assessmentResultsError: action.payload, loading: false }
		}
		default:
			return state
	}
}

export default reducer
