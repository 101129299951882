import { gql } from 'apollo-boost';

export const MUTATE_SEARCH_TUTORS = gql`
	mutation MUTATE_SEARCH_TUTORS(
		$keywords: String!
		$subjects: Int
		$curriculums: Int
		$price_range: PriceRange!
		$limit: Int
		$page: Int
		$recently: Int!
		$alphabet: Int!
		$pricePerHour: Int
		$rating: Int
		$testimonial: Int
		$country: String!
		$location: String
		$nonAcademicPvid: String
		$cities: String
		$fromWhere: String
	) {
		findTutors(
			keywords: $keywords
			price_range: $price_range
			subjects: $subjects
			curriculums: $curriculums
			limit: $limit
			page: $page
			recently: $recently
			alphabet: $alphabet
			pricePerHour: $pricePerHour
			rating: $rating
			testimonial: $testimonial
			country: $country
			location: $location
			nonAcademicPvid: $nonAcademicPvid
			cities: $cities
			fromWhere: $fromWhere
		) {
			page
			total
			totalPages
			tutors {
				pvid
				firstName
				lastName
				address
				role
				token
				country
				userPvid
				pricePerHour
				experienceYears

				resource {
					path
				}

				experienceLevel
				experienceSubject
				experienceCurriculum
				experienceInstitution
				experienceYears

				testimonial

				resource {
					path
				}
				completionUser {
					pvid
					completionPercent
				}
				nonAcademic {
					pvid
					name
					status
					subNonAcademic {
						pvid
						profileNonAcademicPvid
						name
						status
						pricePerHour
						orderList
					}
				}

				testimonial
			}
		}
	}
`;

export const MUTATE_SEARCH_COACHES = gql`
	mutation MUTATE_SEARCH_TUTORS(
		$keywords: String!
		$subjects: Int
		$curriculums: Int
		$price_range: PriceRange!
		$limit: Int
		$recently: Int!
		$alphabet: Int!
		$country: String!
		$location: String
		$nonAcademicPvid: String
	) {
		findTutors(
			keywords: $keywords
			price_range: $price_range
			subjects: $subjects
			curriculums: $curriculums
			limit: $limit
			recently: $recently
			alphabet: $alphabet
			country: $country
			location: $location
			nonAcademicPvid: $nonAcademicPvid
		) {
			pvid
			firstName
			lastName
			address
			role
			token
			country

			pricePerHour
			experienceYears

			resource {
				path
			}

			experienceLevel
			experienceSubject
			experienceCurriculum
			experienceInstitution
			experienceYears

			testimonial

			resource {
				path
			}
			completionUser {
				pvid
				completionPercent
			}
			nonAcademic {
				pvid
				name
				status
				subNonAcademic {
					pvid
					profileNonAcademicPvid
					name
					status
					pricePerHour
					orderList
				}
			}

			testimonial
		}
	}
`;
export const mutateSortTutors = gql`
	mutation mutateSortTutors($recently: Int!, $alphabet: Int!) {
		sortTutors(recently: $recently, alphabet: $alphabet) {
			pvid
			firstName
			lastName
			dateModified
			dob
			nric
			phone
			gender
			race
			description
			address
			pricePerHour
			education
			experienceYears
			resource {
				path
			}

			testimonial
			rating
		}
	}
`;

export const queryNotificationList = gql`
	query queryNotificationList {
		listNotification {
			pvid
			entityId
			code
			remarks
			postedDate
			archived
			status
			read
		}
	}
`;


export const mutationMakeNotificationsASRead = gql`
	mutation MutationMakeNotificationsASRead($notificationIds: [String]!){
		makeNotificationsAsRead(notificationIds: $notificationIds) 
	}
`

export const MUTATE_FEED_LIST = gql`
	mutation MUTATE_FEED_LIST($startIndex: Int, $length: Int) {
		getFeeds(startIndex: $startIndex, length: $length) {
			startIndex
			length
			totalRecord
			list {
				pvid
				firstName
				lastName
				avatar
				comment
				postedDate
				totalUpVoted
				totalDownVoted
				totalReply
				list {
					comment
					postedDate
					firstName
					lastName
					avatar
				}
			}
		}
	}
`;

export const MUTATE_FEED_MENTION_LIST = gql`
	mutation MUTATE_FEED_MENTION_LIST($search: String!) {
		getFeedMentions(search: $search) {
			list {
				userPvid
				firstName
				lastName
			}
		}
	}
`;

export const MUTATE_ADD_FEED = gql`
	mutation MUTATE_ADD_FEED($comment: String!, $mentions: String!) {
		addComment(comment: $comment, mentions: $mentions) {
			pvid
		}
	}
`;

export const MUTATE_VOTE_FEED = gql`
	mutation MUTATE_VOTE_FEED($feedCommentPvid: String!, $vote: Int!) {
		addVote(feedCommentPvid: $feedCommentPvid, vote: $vote) {
			pvid
			vote
		}
	}
`;

export const MUTATE_ADD_REPLY_FEEDS = gql`
	mutation MUTATE_ADD_REPLY_FEEDS($feedCommentPvid: String!, $comment: String!, $mentions: String!) {
		addReply(feedCommentPvid: $feedCommentPvid, comment: $comment, mentions: $mentions) {
			pvid
		}
	}
`;

export const QUERY_LIFELONG_CATEGORIES = gql`
	query QUERY_LIFELONG_CATEGORIES($parentCategory: Int!, $country: String!) {
		listProfileNonAcademic(parentCategory: $parentCategory, country: $country) {
			pvid
			name
			status
			subNonAcademic {
				pvid
				programmeSubjectSubNonAcademicPvid
				name
				status
			}
		}
	}
`;

export const MUTATE_SUBJECT_NON_ACADEMIC = gql`
	mutation MUTATE_SUBJECT_NON_ACADEMIC($list: [InputUserSubjectNonAcademic]!) {
		updateSubjectNonAcademic(list: $list) {
			pvid
			name
			status
			subNonAcademic {
				profileNonAcademicPvid
				pvid
				programmeSubjectSubNonAcademicPvid
				name
				status
				pricePerHour
				orderList
			}
		}
	}
`;

export const DELETE_SUBJECT_NON_ACADEMIC = gql`
	mutation DELETE_SUBJECT_NON_ACADEMIC($pvidList: [String!]!) {
		deleteSubjectNonAcademic(pvidList: $pvidList)
	}
`;

export const GET_USER_TRANSACTIONS = gql`
	query GET_USER_TRANSACTIONS($role: String!) {
		getUserTransactions(role: $role) {
			id
			fromUser {
				pvid
				email
				firstName
				lastName
			}
			toUser {
				pvid
				email
				firstName
				lastName
			}
			booking {
				pvid
				price
				startTime
				subjectInfo {
					pvid
					subject
					level
				}
			}
			status
			createdAt
		}
	}
`;

export const MUTATE_CUTOR_USER_INFO = gql`
	mutation MUTATE_CUTOR_USER_INFO($userId: Int!, $startIndex: Int!, $length: Int!) {
		getUsersTeam(userId: $userId, startIndex: $startIndex, length: $length) {
			list {
				team {
					id
					name
				}
				status {
					total_member_count
					active_member_count
				}
				icon {
					image
				}
			}
		}
		getUserLastActivity(userId: $userId, startIndex: $startIndex, length: $length) {
			last_activity_at
			user_id
			manual
			status
		}
		getBrandIcon(userId: $userId) {
			image
		}
	}
`;
export const MUTATE_COMMUNITY_IS_FOLLOW = gql`
	mutation MUTATE_COMMUNITY_IS_FOLLOW($userId: Int!) {
		isFollowing(userId: $userId) {
			isUserFollowing
		}
	}
`;

export const MUTATE_COMMUNITY_FOLLOW = gql`
	mutation MUTATE_COMMUNITY_FOLLOW($userId: Int!) {
		followUser(userId: $userId) {
			isUserFollowing
		}
	}
`;

export const MUTATE_COMMUNITY_UNFOLLOW = gql`
	mutation MUTATE_COMMUNITY_UNFOLLOW($userId: Int!) {
		unfollowUser(userId: $userId) {
			isUserFollowing
		}
	}
`;

export const MUTATE_COMMUNITY_GET_POST = gql`
	mutation MUTATE_COMMUNITY_GET_POST($userId: Int! ) {
		getPostList(userId: $userId) {
			length
			list {
				_id
				id
				createdAt
				content
				slug
				status
				anonymous
				url
				followersCount
				reactionsCount
				repliesCount
			}
		}
	}
`;

export const MUTATE_COMMUNITY_GET_ANSWER = gql`
	mutation MUTATE_COMMUNITY_GET_ANSWER($userId: Int!, $startIndex: Int!, $length: Int!) {
		getAnswerList(userId: $userId, startIndex: $startIndex, length: $length) {
			startIndex
			length
			list {
				_id
				shortId
				lang
				status
				content
				summary
				publishedAt
				portal
				score
				question {
					_id
					shortId
					lang
					slug
					title
					publishedAt
					type
					url
				}
			}
		}
	}
`;

export const MUTATE_COMMUNITY_USER_INFO = gql`
	mutation MUTATE_COMMUNITY_USER_INFO($userId: Int!) {
		getUserInfo(userId: $userId) {
			_id
			profile {
				score
				description
				title
				banner
				picture
				website
				location
				gender
				name
				username
				url
				counts {
					posts
					questions
					comments
					answers
					followings
					followers
				}
			}
		}
	}
`;

export const GET_MATCHING_TUTORS = gql`
	query GET_MATCHING_TUTORS($lessonPerWeek: Int!, $preferredDays: [String], $country: String, $subjects: [String]) {
		getMatchingTutors(lessonPerWeek: $lessonPerWeek, preferredDays: $preferredDays, subjects: $subjects, country: $country) {
			userPvid
			firstName
			lastName
			description
			address
			token
			country
			pricePerHour
			experienceLevel
			experienceSubject
			experienceCurriculum
			experienceInstitution
			experienceYears
			testimonial
			resource {
				path
			}
			completionUser {
				pvid
				completionPercent
			}
		}
	}
`;
