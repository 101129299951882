/* eslint-disable no-undef */
import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { Provider } from 'react-redux';
import useSocket from 'use-socket.io-client';
import { BackTop, ConfigProvider, notification } from 'antd';
import { I18nProvider } from '@lingui/react';
import { setupI18n } from '@lingui/core';
import moment from 'moment';

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'contentful-management';
import 'moment/locale/id';
import { ReloadOutlined } from '@ant-design/icons';
import { createAppStore } from './store';
import ScrollToTop from 'components/common/ScrollToTop';

import catalogID from 'locales/id/messages.js';
import catalogEN from 'locales/en/messages.js';
import Routes from 'routes';
import idID from 'antd/es/locale/id_ID';
import enUS from 'antd/es/locale/en_US';
import { countryMap } from 'helpers/countryMapping';
import { BASE_URL } from 'helpers/constants';
import * as Sentry from '@sentry/react';
import ConditionalPage from 'components/common/ConditionalPage';
import { getNotifSocket, joinNotifChannel } from 'utils/notification-socket';
import axios from 'axios';
import { Button, Heading, Modal } from 'components';
import { Trans } from '@lingui/macro';
import useIdle from 'helpers/hooks/useIdle';

import GlobalcartItemCounter from './components/GlobalcartItemCount';

import IdealNotification from './IdealNotification';

import { PersistGate } from 'redux-persist/integration/react';

const contentful = require('contentful');

const store = createAppStore();
export const AppContext = createContext({});
export const ContentfulContext = createContext(null);

const catalogMap = { id: catalogID, en: catalogEN };
export let handleChangeLanguage = null;
const i18n = localStorage.getItem('i18n') || 'en';
export let country = localStorage.getItem('country') || 'sg';
export const outsideI18n = setupI18n({ catalogs: catalogMap, language: i18n });

moment.locale(i18n || 'en');

export const SocketContext = React.createContext(null);
const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: createUploadLink({
		uri: `${BASE_URL}/profile`,
		headers: { 'auth-token': localStorage.getItem('token') }
	})
});

export const contentfulClient = contentful.createClient({
	space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
	accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
});

export const contentfulManagement = createClient({
	accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
});

function FallbackComponent() {
	return <div>Something went wrong! Please try again.</div>;
}

function App() {
	const [catalogs, setCatalogs] = useState(catalogMap);
	const [language, setLanguage] = useState(i18n);
	const [valueFromMarketplace, setValueFromMarketplace] = useState({});
	const isIdle = useIdle({ timeToIdle: 60 * 1000 * 20, inactivityEvents: [] });

	const openNotification = () => {
		const key = `open${Date.now()}`;
		const btn = (
			<Button type="primary" size="middle" onClick={handleRefresh} icon={<ReloadOutlined />}>
				Refresh page
			</Button>
		);
		notification.open({
			message: 'Your session is not active',
			description: "You haven't done anything for a while. Please refresh the page to restore your session.",
			btn,
			key,
			duration: 0
		});
	};

	setInterval(() => {
		if (isIdle) {
			openNotification();
		}
	}, 60 * 1000 * 20);

	const [socket] = useSocket(process.env.REACT_APP_CUDY_CHAT_URL);
	const notifiSocket = getNotifSocket();

	const userDetails = JSON.parse(localStorage.getItem('userDetails')) || {};
	let pathArr = window.location.pathname.split('/');
	handleChangeLanguage = (lang, cb) => {
		country = lang;
		const selectedLang = lang === 'id' ? 'id' : 'en';
		return import(`@lingui/loader!./locales/${selectedLang}/messages.json`)
			.then(catalog => {
				localStorage.setItem('i18n', selectedLang);
				localStorage.setItem('country', lang);
				if (cb && cb !== null) {
					setCatalogs({ ...catalogs, [selectedLang]: catalog });
					setLanguage(selectedLang);
				}
			})
			.then(() => {
				let pathArray = window.location.pathname.split('/');
				if (cb && cb !== null) cb();
				else window.location.replace('/' + countryMap[lang].key + '/' + pathArray.slice(2).join('/'));
			});
	};

	const handleRefresh = () => window.location.reload(true);

	document.addEventListener('copy', event => {
		const pagelink = `-- Read more at: ${document.location.href}`;
		event.clipboardData.setData('text', document.getSelection() + pagelink);
		event.preventDefault();
	});

	useEffect(() => {
		if (pathArr[1] && country !== pathArr[1]) {
			handleChangeLanguage(pathArr[1], () => 0);
		}
		if (localStorage.getItem('token')) {
			socket.emit('join_channel', { channel: userDetails.token });
			joinNotifChannel(userDetails.token);
		}
	}, [language, pathArr, socket, userDetails.token]);

	useEffect(() => {
		const checkServerIsRun = async () => {
			try {
				await axios.get(`${process.env.REACT_APP_BASE_URL}/isServerRun`);
			} catch (err) {
				notification.error({
					message: 'We’re Undergoing Maintenance',
					description: `Our server is undergoing maintenance and your experience will be affected. Most functions in the site may not work properly. We apologise for the inconvenience. When completed, this message will disappear when you refresh the page.
					In the meantime, you can learn how to enhance your experience on our platform by reading our information pages located at the bottom of the page at the footer.
					`,
					duration: 0
				});
			}
		};

		checkServerIsRun();
	}, []);

	return (
		<Sentry.ErrorBoundary fallback={ConditionalPage}>
			<div className="main-wrapper">
				<I18nProvider language={language} catalogs={catalogs}>
					<ContentfulContext.Provider value={contentfulClient}>
						<AppContext.Provider value={{ onMarketplace: { valueFromMarketplace, setValueFromMarketplace } }}>
							<ConfigProvider hi locale={country === 'id' ? idID : enUS}>
								<SocketContext.Provider value={{ socket, notifiSocket }}>
									<Provider store={store.store}>
										<PersistGate loading={null} persistor={store.persistor}>
											<ApolloProvider client={client}>
													<BrowserRouter>
													<GlobalcartItemCounter>
														<ScrollToTop />
														<BackTop />
														
														<Routes language={language} country={country} /> {/* This is the routes */}
														</GlobalcartItemCounter>
													</BrowserRouter>
											</ApolloProvider>
										</PersistGate>
									</Provider>
								</SocketContext.Provider>
							</ConfigProvider>
						</AppContext.Provider>
					</ContentfulContext.Provider>
				</I18nProvider>
			</div>
		</Sentry.ErrorBoundary>
	);
}

export default App;
