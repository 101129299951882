import React from 'react';
import styled from 'styled-components';

const initAd = () => {
	(window.adsbygoogle = window.adsbygoogle || []).push({});
  };
  
  class CardAdd extends React.Component {

    shouldComponentUpdate(nextProps) {
        return this.props.currentPath !== nextProps.currentPath
    }
  
	componentDidUpdate() {
	  initAd();
	}
  
	render() {
		
	const HorizAdd = styled.ins`
	display: block;
	min-width: ${this.props.width ? this.props.width : "250px"};
	height: ${this.props.height ? this.props.height : "350px"};
	max-width: 250px;
	margin: 0 auto;
	background-color: #fff;
	border-radius: 12px;
	box-shadow: 0 12px 20px 0 rgba(0,0,0,0.1), 0 17px 10px 0 rgba(0,0,0,0.1); 
	`
	  return (
		<div key={Math.random()}>
		  <HorizAdd
			className="adsbygoogle" 
			data-ad-client="ca-pub-9691916143882648"
			data-ad-slot="7457575276"
			data-full-width-responsive= "true"
		  ></HorizAdd>
		</div>
	  );
	}
  }

  

export default CardAdd;