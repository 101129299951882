import * as Types from "../constants"

const initialState = {
	company: {},
}

const companyAuthReducer = (state = initialState, action) => {
	switch (action.type) {
		case Types.LOADING_AUTH_COMPANY:
			return { ...state, loading: true }
		case Types.AUTH_COMPANY:
			return {
				...state,
				token: action.payload,
				company: { name: "Adrian Wibi" },
				loading: false,
			}
		case Types.UNAUTH_COMPANY:
			return { ...state, company: null, token: null, loading: false }
		default:
			return state
	}
}

export default companyAuthReducer
