import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Headroom from 'react-headroom';

import {
	AppstoreOutlined,
	DownOutlined,
	MessageOutlined,
	NotificationOutlined,
	ProjectOutlined,
	SettingOutlined,
	UserOutlined,
	MoreOutlined,
	HeartOutlined,
	DollarCircleOutlined,
	BellOutlined,
	FundProjectionScreenOutlined,
	CreditCardOutlined,
	FileSyncOutlined,
	SearchOutlined,
	PlusOutlined,
	ShoppingCartOutlined,
	HourglassOutlined,
	ApiOutlined,
} from '@ant-design/icons';


import { Avatar, Tooltip, Badge, Col, Dropdown, Menu, Row, Select, Typography } from 'antd';

import Button from '../Button';
import Search from '../forms/Search';
import Heading from '../Heading';
import { Link, useHistory, useLocation } from 'react-router-dom';
import NotificationPanel from '../common/NotificationPanel';
import { reviews } from '../../dummy';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { queryLogout } from 'queries/auth';
import { mutateUserProfile } from 'queries/profile';
import { fetchUserOptions } from 'store/actions/userActions';
import { unauthUser } from 'store/actions/authActions';
import { fetchUser as fetchUserAction } from 'store/actions/userActions';
import { formatCurrency, media, mobile } from 'helpers';
import Percentage from '../Percentage';
import ProfileCompletion from '../common/ProfileCompletion';
import MenuDrawer from '../common/MenuDrawer';
import { itemCountContext } from '../GlobalcartItemCount';
import { queryNotificationList, mutationMakeNotificationsASRead } from 'queries/user';
import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import DynamicIcon from '../common/DynamicIcon';
import { countries, countryMap } from 'helpers/countryMapping';
import useAvatar from 'helpers/hooks/useAvatar';
import { baseStyles } from 'styles/base';
import Logo from '../Logo';
import { notifyBooking, notifyMe } from 'helpers/notification';
import { country, handleChangeLanguage, SocketContext } from 'App';
import { SERVER_BASE_URL } from 'helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import Favourite from '../Favourite';
import FindMenu from './FindMenu';
import LearnMenu from './LearnMenu';
import ToolsMenu from './ToolsMenu';
import Vouchers from '../../assets/images/Vouchers.svg';
import { GET_UNREAD_MSGS_COUNT } from 'queries/chat';
import { newClient } from 'helpers';
import { GET_USER_CREDITS } from 'queries/credits';
import { GET_USER_CART_ITEMS } from 'queries/globalCart';
import { renderError } from 'helpers';

//
import SearchOverlay from './overlay/SearchOverlay';

/*global Beamer*/
const chatClient = newClient('chat');
const userCreditClient = newClient('credits');

function Navbar({ i18n }) {
	const history = useHistory();
	const location = useLocation();
	let userDataLocalStorage = localStorage.getItem('userDetails');
	userDataLocalStorage = JSON.parse(userDataLocalStorage);

	const { notifiSocket } = useContext(SocketContext);
	const [notifList, setNotificationList] = useState([]);
	const [showDrawer, setDrawer] = useState(false);
	const [cudyCredits, setCudyCredits] = useState(0);
	const [menuDrawer, setMenuDrawer] = useState(false);
	const [profileCompletionDrawer, setProfileCompletionDrawer] = useState(false);
	const [unReadChatCount, setUnReadChatCount] = useState(0);
	const [unReadNotificationCount, setUnReadNotificationCount] = useState(0);
	const { itemCount, setItemCount } = useContext(itemCountContext);
	// const item=useContext(itemCountContext);

	const detailsFromStorage = JSON.parse(localStorage.getItem('userDetails')) || {};
	const token = localStorage.getItem('token');
	const currency = detailsFromStorage.country === 'sg' ? 'SGD' : detailsFromStorage.country === 'id' ? 'IDR' : '';

	const [setSignout] = useLazyQuery(queryLogout, unauthUser);
	const dispatch = useDispatch();

	// const [fetchUser, { data, loading: loadingUser }] = useMutation(mutateUserProfile, fetchUserOptions(history))
	const { user: data, loading: loadingUser, error } = useSelector(({ user }) => user);
	const fetchUser = useCallback(() => dispatch(fetchUserAction()), [dispatch, fetchUserAction]);
	const cartClient = newClient('globalCart');
	const [total, setTotal] = useState(0);

	const [getUserCartsItems] = useMutation(GET_USER_CART_ITEMS, {
		client: cartClient,
		onCompleted: (data) => {
			if (data) {
				setItemCount((data?.getUserCartsItems.contentCart.contentItems).length + (data?.getUserCartsItems.bookingCart.bookingItems).length);
			}
		},
		onError: (error) => console.log(error)
	});
	// this state will be shared with all components

	useEffect(() => {
		getUserCartsItems();
	}, []);

	useEffect(() => {
		fetchUser();
	}, [dispatch]);
	const {
		data: notif,
		refetch,
		loading: loadingNotif
	} = useQuery(queryNotificationList, {
		fetchPolicy: 'network-only',
		onCompleted: (data) => {
			setNotificationList(data.listNotification);
			setUnReadNotificationCount(data.listNotification.filter((item) => item.read === false).length);
		}
	});

	// if (notif && notif.listNotification) {
	// 	notifList = notif.listNotification;
	// 	console.log(notifList)
	// }

	const userData = data || {};
	// const companyData = JSON.parse(localStorage.getItem('companyDetails')) || {};
	const avatar = (userData.avatar || [])[0] || {};
	// const companyAvatar = companyData.preferences.companyLogo || [] || {}
	const actualUploadedAva = userData && userData.avatar && (userData.avatar[userData.avatar.length - 1] || {}).path;

	const userAvatar = useAvatar(avatar.path);
	const ava = (userData.avatar || []).length > 1 ? SERVER_BASE_URL + actualUploadedAva : userAvatar;
	const handleSendKeywords = (value) => history.push(`/${country}/browse/tutors?keywords=${value}`);
	const handleOpenCompletionDrawer = () => {
		fetchUser();
		setProfileCompletionDrawer(true);
	};

	const { completionUser = {}, completionDetail = [] } = userData;
	const percent = completionUser.completionPercent;

	const pathIncludesCountryCode = Object.keys(countryMap).findIndex((item) => location.pathname.includes('/' + item));
	const previousRoute = pathIncludesCountryCode > -1 ? location.pathname.slice(3) : location.pathname;

	const [getUnReadMsgsCount] = useMutation(GET_UNREAD_MSGS_COUNT, {
		client: chatClient
	});

	const [getUserCredits] = useMutation(GET_USER_CREDITS, {
		client: userCreditClient,
		onCompleted: (data) => {
			if (data.getUserCredits) {
				setCudyCredits(data.getUserCredits.credits);
			}
		},
		onError: (err) => {
			console.log(err);
		}
	});

	const [makeNotificationsAsRead] = useMutation(mutationMakeNotificationsASRead, {
		onCompleted: () => {
			setUnReadNotificationCount(0);
		}
	})

	useEffect(() => {
		const getData = async () => {
			try {
				const {
					data: { getUnReadMsgsCount: count }
				} = await getUnReadMsgsCount();
				setUnReadChatCount(count);
			} catch (err) { }
		};
		getUserCredits();
		getData();
	}, []);


	const src_slides = 'https://slides.cudy.co/iframe.html';
	const src_planner = process.env.REACT_APP_MODE === "development" ? "http://localhost:3001" : "https://planner.cudy.co";
	const [doRedirectOnce, setDoRedirectOnce] = useState(false)

	let planneriframe = document.createElement('iframe');
	let sliderifram = document.createElement('iframe');

	planneriframe.onload = (event) => {
		if (event.target.src) {
			//do some stuff here
			planneriframe.contentWindow.postMessage({ token: localStorage.getItem("token") }, src_planner + '/iframe.html');
	
			if(!doRedirectOnce){
				setDoRedirectOnce(true)
				window.open(src_planner);
			}
	
			setTimeout(()=>{
				planneriframe.remove()
				setDoRedirectOnce(false)
			}, 5000)
		}
	  

		// resolve();
	}

	sliderifram.onload = (event) => {
		if (event.target.src) {
			sliderifram.contentWindow.postMessage(localStorage.getItem("token"), src_slides);

			if(!doRedirectOnce){
				setDoRedirectOnce(true)
				window.open("https://slides.cudy.co");
			}

			setTimeout(()=>{
				planneriframe.remove()
				setDoRedirectOnce(false)
			}, 5000)
			// resolve();
		}
	}

	const menu = (
		<Menu style={{ padding: '1.4em 1.2em', width: mobile ? '100%' : 280 }}>
			<Menu.Item key="hi" disabled>
				<ProfileHeading content={<Trans>Hi, {userData.firstName || userData.roleName}</Trans>} />
			</Menu.Item>
			<Menu.Item key="balance">
				{/* <ProfileHeading content={`${currency} ${formatCurrency(userData.balance)}`} /> */}
				<Button type="ghost" block>
					<DynamicIcon type="icon-dollar" /> {`${currency} ${formatCurrency(userData.balance)}`} &nbsp;{' '}
					<PlusOutlined
						onClick={() =>
							history.push({
								pathname: `${userDataLocalStorage && userDataLocalStorage.role ? `/${country}/balance/buy/balance` : `/${country}/login`}`,
								state: { amount: 100, currency: 'SGD' }
							})
						}
						style={{ color: 'orange' }}
					/>
				</Button>
			</Menu.Item>
			<Menu.Item key="credits">
				{/* <ProfileHeading content={`${currency} ${formatCurrency(userData.balance)}`} /> */}
				<Button
					type="ghost"
					block
					onClick={() =>
						history.push({
							pathname: `${userDataLocalStorage && userDataLocalStorage.role ? `/${country}/credits/home` : `/${country}/login`}`
						})
					}>
					<DynamicIcon type="icon-dollar" /> {`${cudyCredits} credits`} &nbsp;
				</Button>
			</Menu.Item>
			{(detailsFromStorage.role || {}).code === 'TTR0' && (
				<Menu.Item key="completion" onClick={handleOpenCompletionDrawer}>
					<ProfileComplete gutter={32} type="flex" align="middle">
						<Col lg={6}>
							<Percentage type="circle" percent={percent} width={50} />
						</Col>{' '}
						<Col lg={18}>
							<Heading
								content={percent === 100 ? <Trans>You've made it!</Trans> : <Trans>You're almost there</Trans>}
								subheader={percent === 100 ? <Trans>Your profile is complete</Trans> : <Trans>Complete your profile</Trans>}
								level={4}
								marginBottom="0"
							/>
						</Col>
					</ProfileComplete>
				</Menu.Item>
			)}
			{(detailsFromStorage.role || {}).code === 'TTR0' && (
				<Menu.Item key="profile">
					<Link to={`/${country}/profile/me`}>
						<Trans>
							<UserOutlined /> &nbsp; Your profile
						</Trans>
					</Link>
				</Menu.Item>
			)}
			{/* {(detailsFromStorage.role || {}).code === 'TTR0' && (
				<Menu.Item key="profile">
					<Link to={`/${country}/credits/home`}>
						<Trans>
							<CreditCardOutlined /> &nbsp; Cudy Credits
						</Trans>
					</Link>
				</Menu.Item>
			)} */}

			{(detailsFromStorage.role || {}).code === 'TTR0' && (
				<Menu.Item key="cudyclass">
					<Link to={`/${country}/cudy-classes`}>
						<AppstoreOutlined />{"      "}
						<Trans>
							My online classes
						</Trans>
					</Link>
				</Menu.Item>
			)}

			{(detailsFromStorage.role || {}).code === 'STDN' && (
				<Menu.Item key="assignments">
					<Link to={`/${country}/assignments`}>
						<Trans>
							<ProjectOutlined /> &nbsp; Your assignments
						</Trans>
					</Link>
				</Menu.Item>
			)}

			{(detailsFromStorage.role || {}).code !== 'CMPN' && <Menu.Item key="booking-list">
				<Link to={`/${country}/booking-list`}>
					<FundProjectionScreenOutlined /> {"       "}
					<Trans>
						Booking list
					</Trans>
				</Link>
			</Menu.Item>}

			<Menu.Item key="content">
				<Link to={`/${country}/content/dashboard`}>
					<FileSyncOutlined /> {"     "}
					<Trans>
						Content dashboard
					</Trans>
				</Link>
			</Menu.Item>

			{(detailsFromStorage.role || {}).code === 'CMPN' && (
				<Menu.Item key="job-dash">
					<Link to={`/${country}/jobs/dashboard`}>
						<HourglassOutlined /> {"   "}
						<Trans>
							Jobs dashboard
						</Trans>
					</Link>
				</Menu.Item>
			)}

			<Menu.Item key="job-applied">
				<Link to={`/${country}/jobs/applied-jobs`}>
					<ApiOutlined /> {"    "}
					<Trans>
						Jobs applied
					</Trans>
				</Link>
			</Menu.Item>

			<Menu.Item key="transaction-list">
				<Link to={`/${country}/transaction-list`}>
					<CreditCardOutlined /> {"      "}
					<Trans>
						Transaction list
					</Trans>
				</Link>
			</Menu.Item>
			<Menu.Item key="cudyclass">
				<Link to={`/${country}/payout`}>
					<DollarCircleOutlined /> {"     "}
					<Trans>
						Payout
					</Trans>
				</Link>
			</Menu.Item>
			<Menu.Item
				key="updates"
				onClick={() => {
					window.location.href = 'https://latest.cudy.co/feedback/';
				}}>
				<NotificationOutlined /> {"    "}
				<Trans>
					Cudy updates
				</Trans>
			</Menu.Item>
			<Menu.Item key="disputes">
				<Link to={`/${country}/dispute/home`}>
					<DynamicIcon projectName="Cudy3.0" type="icon-Dispute" size={20} /> {"   "}
					<Trans>
						My disputes
					</Trans>
				</Link>
			</Menu.Item>

			<Menu.Item key="settings">
				<Link to={`/${country}/profile/settings`}>
					<Trans>
						<SettingOutlined /> &nbsp; Settings
					</Trans>
				</Link>
			</Menu.Item>

			<Menu.Item key="logout" onClick={() => setSignout()}>
				<Trans>Logout</Trans>
			</Menu.Item>
		</Menu>
	);

	useEffect(() => {
		notifiSocket.on('notification', async (item) => {
			try {
				const res = await refetch();
			} catch (err) {
				console.log(err);
			}
			if (item.code === 'BOOKING') {
				notifyBooking(item.reviewerName, item.remarks);
			} else {
				notifyMe(item.reviewerName, item.remarks);
			}
		});
	}, [refetch, notifiSocket]);

	return (
		<>
			<StyledHeadRoom disableInlineStyles>
				<ProfileCompletion
					data={{ completionUser, completionDetail }}
					loading={loadingUser}
					placement="right"
					visible={profileCompletionDrawer}
					onClose={() => setProfileCompletionDrawer(false)}
				/>

				<MenuDrawer
					loading={loadingUser}
					data={{ userData, menuDrawer, reviews, notifList }}
					handlers={{
						showDrawer,
						setDrawer,
						setMenuDrawer,
						setSignout,
						fetchUser,
						handleSendKeywords,
						handleChangeLanguage
					}}
				/>
				<Header>
					<RowStyled type="flex" style={{ justifyContent: 'space-between', padding: '0 5%', wrap: 'nowrap' }}>
						<StyledCol>
							<div className="ant-row-flex ">
								<div className="main-logo">
									<StyledLogo className="mb0" onClick={() => history.push(`/${country}`)} width={mobile ? '60' : '100'} />
								</div>
								{!mobile && (
									<SearchOverlay>
										<StyledSearchButton title="Open Search" type="button">
											Search
											<StyledSearchIcon>
												<SearchOutlined style={{ fontSize: '1.2rem', color: 'orange' }} />
											</StyledSearchIcon>
										</StyledSearchButton>
										{/* <div className="ant-row-flex" style={{ alignItems: 'center' }}>
												<div className="search" style={{ margin: '0 10px' }}>
													<StyledSearch size="large" onSearch={handleSendKeywords} placeholder={`${i18n._(t`Search`)}`} />{' '}
													&nbsp;&nbsp;
												</div>
											</div> */}
									</SearchOverlay>
								)}
								<ServiceLinkWrapper>
									<Dropdown
										overlay={FindMenu}
										placement="bottomRight"
										trigger={['click']}
										overlayStyle={{ position: 'fixed', zIndex: 99, boxShadow: baseStyles.boxShadow.mild }}>
										<StyledServiceBtn style={{ marginLeft: 20 }}>
											Find <DownOutlined />{' '}
										</StyledServiceBtn>
									</Dropdown>
									<Dropdown
										overlay={LearnMenu}
										placement="bottomRight"
										trigger={['click']}
										overlayStyle={{ position: 'fixed', zIndex: 99, boxShadow: baseStyles.boxShadow.mild }}>
										<StyledServiceBtn>
											Learn <DownOutlined />{' '}
										</StyledServiceBtn>
									</Dropdown>
									<Dropdown
										overlay={() => <ToolsMenu planneriframe={planneriframe} sliderifram={sliderifram} />}
										placement="bottomRight"
										trigger={['click']}
										overlayStyle={{ position: 'fixed', zIndex: 99, boxShadow: baseStyles.boxShadow.mild }}>
										<StyledServiceBtn>
											Create <DownOutlined />{' '}
										</StyledServiceBtn>
									</Dropdown>
								</ServiceLinkWrapper>
							</div>
						</StyledCol>
						<StyledCol>
							<Language>
								<div className="ant-row-flex">
									{/* {!mobile && (
                            <>

                                            <Link to={`/${country}/${isPageLifelong ? `browse/tutors` : `lifelong/browse`}`} className="px0em">
                                                <Button type="primary" size="default" >
                                                    <DynamicIcon type={`icon-${isPageLifelong ? "book" : "guitar"}`} />
                                        &nbsp; <Trans>Go to Cudy</Trans>  {isPageLifelong ? " Academics" : " Lifelong"}
                                                </Button>
                                            </Link>
                                            <Link to={`/${country}/${isCounselling ? `browse/tutors` : `counselling`}`} className="px1em">
                                                <Button type="primary" size="default">
                                                    <DynamicIcon type={`icon-${isPageLifelong ? "book" : "guitar"}`} />
                                        &nbsp; <Trans>Go to Cudy</Trans>  {isCounselling ? " Academics" : " Counselling"}
                                                </Button>
                                            </Link>
                            </>
                        )} */}
									{/* If it's logged in */}
									{token ? (
										mobile ? (
											<>
												<Button
													shape="circle"
													icon={<MoreOutlined />}
													size="default"
													type="primary"
													onClick={() => setMenuDrawer(!menuDrawer)}
												/>
											</>
										) : (
											<LoggedinItems>
												{(detailsFromStorage.role || {}).code === 'TTR0' ? (
													<>
														<Favourite />
														<Link to={`/${country}/vouchers/home`}>
															<Badge>
																<Button shape="circle" size="default" type="dashed" >
																	<img src={Vouchers} alt={'vouchers'} style={{ width: 15, height: 15 }} />
																</Button>
															</Badge>
														</Link>
														<Link to={`/${country}/globalcart/home`}>

															<Badge count={itemCount}>
																<Button shape="circle" icon={<ShoppingCartOutlined />} size="default" type="dashed" />
															</Badge>
														</Link>
														<Badge count={unReadNotificationCount || 0}>
															<Button
																onClick={async () => {
																	setDrawer(!showDrawer)
																	await makeNotificationsAsRead({ variables: { notificationIds: notifList.filter((item) => item.read === false).map((item) => item.pvid) } })
																}}
																shape="circle"
																icon={<BellOutlined />}
																size="default"
																type="dashed"
															/>
														</Badge>
														<Link to={`/${country}/chats`}>
															<Badge count={unReadChatCount}>
																<Button shape="circle" icon={<MessageOutlined />} size="default" type="dashed" />
															</Badge>
														</Link>
														<NotificationPanel
															visible={showDrawer}
															onClose={() => setDrawer(false)}
															data={reviews}
															notifList={notifList}
															loading={loadingNotif}
															isTutor={true}
														/>
													</>
												) : (
													<>
														<Favourite />
														<Link to={`/${country}/vouchers/home`}>
															<Badge>
																<Button shape="circle" size="default" type="dashed" >
																	<img src={Vouchers} alt={'vouchers'} style={{ width: 15, height: 15 }} />
																</Button>
															</Badge>
														</Link>
														<Link to={`/${country}/globalcart/home`}>
															{
																//Todo : Implement Badge count on receving msg
															}
															<Badge count={itemCount}>
																<Button shape="circle" icon={<ShoppingCartOutlined />} size="default" type="dashed" />
															</Badge>
														</Link>
														<Badge count={unReadNotificationCount || 0}>
															<Button
																onClick={() => {
																	setDrawer(!showDrawer)
																	makeNotificationsAsRead({ variables: { notificationIds: notifList.filter((item) => item.read === false).map((item) => item.pvid) } })
																}}

																shape="circle"
																icon={<BellOutlined />}
																size="default"
																type="dashed"
															/>
														</Badge>
														<Link to={`/${country}/chats`}>
															<Badge count={unReadChatCount}>
																<Button shape="circle" icon={<MessageOutlined />} size="default" type="dashed" />
															</Badge>
														</Link>

														<NotificationPanel
															visible={showDrawer}
															onClose={() => setDrawer(false)}
															data={reviews}
															notifList={notifList}
															loading={loadingNotif}
															isTutor={false}
														/>
													</>
												)}

												<Dropdown
													overlay={menu}
													placement="bottomRight"
													trigger={['click']}
													overlayStyle={{ position: 'fixed' }}
												// onVisibleChange={(visible) => visible && fetchUser()}
												>
													<a role="button">
														<Avatar size="large" src={ava} /> <DownOutlined />
													</a>
												</Dropdown>
											</LoggedinItems>
										)
									) : // If it's not logged in
										mobile ? (
											<>
												<Button
													shape="circle"
													icon={<MoreOutlined />}
													size="default"
													type="primary"
													onClick={() => setMenuDrawer(!menuDrawer)}
												/>
											</>
										) : (
											<UnloggedinItems>
												<>
													<Link to={{ pathname: `/${country}/login`, state: { previousRoute } }} className="px1em">
														<Button type="ghost" size="default">
															<Trans>Login</Trans>
														</Button>
													</Link>
													{/* &nbsp; &nbsp; */}
													<Link to={`/${country}/register`} className="pl1em">
														<Button type="primary" size="default">
															<Trans>Sign up</Trans>
														</Button>
													</Link>
												</>
											</UnloggedinItems>
										)}
								</div>
							</Language>
						</StyledCol>
					</RowStyled>
				</Header>

				{/* <BottomBar show={showServices} type="flex" justify={mobile < 415 ? 'space-between' : 'center'} gutter={18}>
					{bottomBarLinks.map(item => (
						<Col xs={7} sm={3} md={2} lg={2} xl={2} key={item.key}>
							<Link to={{ pathname: '/' + country + item.pathname }}>
								<Typography className="typo ta-center" style={{ whiteSpace: 'break-spaces' }}>
									<Typography.Paragraph>
										<DynamicIcon projectName="Cudy3.0" type={item.icon} size={mobile ? 34 : 24} />
									</Typography.Paragraph>
									<Typography.Text type="secondary" className="fs-09em">
										{item.label}
									</Typography.Text>
								</Typography>
							</Link>
						</Col>
					))}
				</BottomBar> */}
			</StyledHeadRoom>
		</>
	);
}

export default withI18n()(Navbar);

// ███████╗████████╗██╗   ██╗██╗     ███████╗███████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔════╝
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ███████╗
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ╚════██║      🐣🐣🐣🐣🐣🐣
// ███████║   ██║      ██║   ███████╗███████╗███████║
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚══════╝

const RowStyled = styled(Row)`
	flex-wrap: nowrap !important;
`;

const ButtonStyle = styled.div`
	align-self: center;
`;
const Language = styled.div`
	.ant-row-flex {
		display: flex;
		justify-content: flex-end;
	}
	@media (min-width: 768px) and (max-width: 991.98px) {
		.ant-row-flex {
			display: flex;
			justify-content: flex-end;
		}
	}
	@media (min-width: 576px) and (max-width: 767.98px) {
		.ant-row-flex {
			display: flex;
			justify-content: flex-end;
		}
	}
`;
const Header = styled.header`
	.ant-col {
		line-height: 0;
		align-self: center;
		/* overlayStyle */
	}
	background-color: #fff;

	#google_translate_element {
		margin-right: 2em;
		display: inline-block;
		+ * {
			display: inline-block;
		}
	}
	.main-logo {
		height: auto;
		img {
			cursor: pointer;
		}
	}
`;

const BottomBar = styled(Row)`
	position: relative;
	white-space: nowrap;
	padding: 1em 1.5em;
	line-height: 40px;
	background-color: ${baseStyles.lightGrey.two};
	box-shadow: ${baseStyles.boxShadow.hover};
	${(props) => !props.show && 'display:none'};

	.ant-col {
		line-height: 1.1;
		.typo {
			transition: all 0.2s ease;
			> .ant-typography {
				margin-bottom: 0.5em;
			}
			&:hover {
				transform: translateY(-2px);
			}
		}
	}

	&& {
		${media.mobile`
			padding: 1em 1.5em;
			flex-wrap: nowrap;
			overflow-x: scroll;
			width: auto;
			-webkit-overflow-scrolling: touch;
			&::-webkit-scrollbar {
				display: none;
			}
		`}
	}
`;

const StyledHeadRoom = styled(Headroom)`
	overflow: hidden;
	position: fixed;
	top: 0px;
	z-index: 1000;
	width: 100%;
	height: 66.5px;
	border-bottom: 1px solid ${baseStyles.lightGrey.one};

	@media (max-width: 575.98px) {
		.ant-row-flex {
			display: flex;
			justify-content: space-between;
			padding: 0.5rem 0.5rem;
		}
	}
	.headroom {
		top: 0;
		left: 0;
		right: 0;
		z-index: 11;
		> header {
			@media (min-width: 1200px) {
				padding: 0.5rem 2.5rem;
			}
		}

		> header {
			@media (min-width: 768px) and (max-width: 991.98px) {
				padding: 0.5rem 1rem;
			}
		}

		> header {
			@media (min-width: 576px) and (max-width: 767.98px) {
				padding: 0.5rem 0.5rem;
			}
		}

		> header {
			@media (min-width: 992px) and (max-width: 1199.98px) {
				padding: 0.5rem 0rem;
			}
		}
		@media (min-width: 992px) and (max-width: 1199.98px) {
			.ant-input-search {
				width: 127px !important;
			}
		}
		@media (min-width: 768px) and (max-width: 991.98px) {
			.ant-input-search {
				width: 157px !important;
			}
		}

		@media (min-width: 576px) and (max-width: 767.98px) {
			.ant-input-search {
				width: 140px !important;
			}
		}
		.headroom--unfixed {
			position: fixed;
			transform: translateY(0);
		}
		.headroom--scrolled {
			position: fixed;
			/* transition: transform 0.3s ease; */
		}
		.headroom--unpinned {
			position: fixed;
			transform: translateY(0);
		}
		.headroom--pinned {
			position: fixed;
			transform: translateY(0%);
			> header {
				background-color: #fff;
			}

			${media.mobile`
		.headroom {
			> header {
				padding: 0 2em;
			}
		}
	`}
		}
	}
`;

const StyledLogo = styled(Logo)`
	margin-right: 5px;
	cursor: pointer;
`;

const LoggedinItems = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	min-width: 120px;
	.ant-badge {
		margin-right: 2em;
		button {
			border-color: #d9d9d9;
			+ sup {
				top: 5px;
			}
		}
	}

	${media.mobile`
        display: block;
        .ant-menu {
            border-right: none;
            margin-bottom: 2em;
        }
        .ant-badge {
            margin-right: 0;
            display: block;
        }
    `}
`;

const UnloggedinItems = styled.div`
	/* display: inline-block; */
	text-align: right;

	${media.mobile`
        > * {
            display: block;
        }
    `}
`;

const ProfileHeading = styled(Heading).attrs({
	level: 4,
	marginBottom: '0'
})`
	h4.ant-typography {
		font-size: 1.2em;
	}
`;

const ProfileComplete = styled(Row)`
	.ant-typography {
		h4 {
			font-size: 1em;
		}
	}
`;

const StyledCol = styled(Col)`
	.ant-row-flex {
		align-items: center;
	}
`;

const StyledSearch = styled(Search)`
	border: 1px solid ${baseStyles.lightGrey.one};
	&& {
		border-radius: 32px;
		padding: 0px 17px;
		width: 280px;
	}
	.ant-btn {
		border: 0;
	}
`;

const StyledServiceBtn = styled.span`
	color: ${baseStyles.primaryColor};
	cursor: pointer;
	user-select: none;
	font-size: 1.1rem;
	margin-left: 45px;

	${media.mobile`
	margin-left:20px;
	font-size:0.9rem;
	`}
`;

const ServiceLinkWrapper = styled.div`
	min-width: 200px;
`;

const wiggle = keyframes`
   10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

const StyledSearchButton = styled.button`
	width: 280px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #6d6d6d;
	background-color: white;
	border: 1px solid ${baseStyles.lightGrey.one};
	border-radius: 32px;
	padding: 0px 22px;
	width: 280px;
	cursor: pointer;
	:focus {
		outline: none;
	}
	:hover {
		box-shadow: 3px 3px #e2e2e2;
	}
`;

const StyledSearchIcon = styled.div`
	:hover {
		animation: ${wiggle} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
		transform: translate3d(0, 0, 0);
		backface-visibility: hidden;
		perspective: 1000px;
	}
`;
