import React from "react"
import { Drawer, List, Skeleton, Divider } from "antd"
import moment from "moment"
import styled from "styled-components"
import Section from "../Section"
import { mobile } from "helpers"
import { baseStyles } from "styles/base"
import DynamicIcon from "./DynamicIcon"
import Heading from "components/Heading"
import Empty from "components/Empty"
import { Trans } from "@lingui/macro";
import { country } from "App";
import { Link } from "react-router-dom"

const StyledDrawer = styled(Drawer)`
	.ant-drawer-body {
		padding: 0;
	}
`

const StyledSection = styled(Section)`
	padding: 2em 3em;
`

const StyledListItem = styled(List.Item)`
	position: relative;
	.title {
		.date {
			color: ${baseStyles.greyColor};
		}
	}
`



const TutorPinNotification = (props) => {
	const dataNotify = [{
		archived: false,
		code: "PINMSG",
		entityId: "0",
		postedDate: new Date(),
		pvid: "1",
		remarks: "80% of tutors get more requests when they share their profiles on social media",
		status: null
	}];

	return (
		<>
			<Divider plain><Trans>Reminder</Trans></Divider>
			<List
				dataSource={dataNotify || []}
				locale={{
					emptyText: <Empty description={<Trans>You have no notifications at the moment. Enjoy your day!</Trans>} />
				}}
				loading={props.loading}
				renderItem={item => <NotificationItem {...item} />}
			/>
		</>
	)

}


const NotificationPanel = props => {
	const { notifList } = props;
	return (

		<StyledDrawer
			placement="right"
			closable={false}
			width={mobile ? "80%" : 450}
			onClose={props.onClose}
			visible={props.visible}>
			<StyledSection>
				<Heading
					bold
					content={<Trans>Notifications</Trans>}
					subheader={<Trans>Don't miss out on any notifications from others</Trans>}
					marginBottom="3em"
				/>

				{props.isTutor && (<TutorPinNotification />)}

				<Divider plain><Trans>Notifications</Trans></Divider>
				<List
					dataSource={props.notifList || []}
					locale={{
						emptyText: <Empty description={<Trans>You have no notifications at the moment. Enjoy your day!</Trans>} />
					}}
					loading={{
						indicator: (<Skeleton avatar loading={props.loading} active paragraph={{ rows: 6 }} />),
						spinning: props.loading,
						size: "large"
					}}
					renderItem={item => <NotificationItem {...item} />}
				/>
				{/* <ResultSection title="We've been working hard for the notifications. Please stay tuned." /> */}
			</StyledSection>
		</StyledDrawer>

	)
}

export default NotificationPanel

function NotificationItem(props) {

	const notificationTypes = {
		"BOOKING": {
			title: "Booking",
			link: `/${country}/booking-list`
		},
		"TESTI": {
			title: "Testimonial",
			link: `/${country}/chats`
		},
		"RVIEW": {
			title: "Review",
			link: ""
		},
		"PYMNT": {
			title: "Payment",
			link: ""
		},
		"REFRAL": {
			title: "Referral",
			link: ""
		},
		"SRCAPP": {
			title: "Search Appeared",
			link: ""
		},
		"PSTASGTTR": {
			title: "Post Assignment",
			link: ""
		},
		"COMPREQ": {
			title: "Posting Assigment Acceptance from tutor",
			link: ""
		},
		"MSGNOTIF": {
			title: "Chat Notification",
			link: `/${country}/chats`
		},
		"TESTIMO": {
			title: "Testiminial",
			link: `/${country}/chats`
		},
		"TUTORSC": {
			title: "Tutor Score",
			link: `/${country}/chats`
		},
		"CREACODE": {
			title: "Transcribe Coupon Code",
			link: `/${country}/cudytranscribe`
		},
		"TRANGIFT": {
			title: "Transcribe Coupon Code Gift",
			link: `/${country}/cudytranscribe`
		}
	}

	const { code, postedDate, pvid, remarks } = props

	const renderTitle = () => {
		if (code === "TESTI") return <Trans>You have a new testimonial</Trans>
		else if (code === "SRCAP") return <Trans>You've been found!</Trans>
		else if (code === "PINMSG") return <Trans>Reminder!</Trans>
		else {
			return notificationTypes[code] && notificationTypes[code].title ? <Link to={notificationTypes[code].link}> {notificationTypes[code].title} </Link> : ""
		}
	}

	return (
		<StyledListItem key={pvid}>
			<List.Item.Meta
				avatar={<DynamicIcon type={code === "TESTI" ? "icon-chat" : (code === "PINMSG" ? "icon-notification" : "icon-search")} />}
				title={
					<div className="title">
						{renderTitle()} &middot; <span className="date">{moment(postedDate).fromNow()}</span>
					</div>
				}
				description={remarks}
			/>
		</StyledListItem>
	)
}
