import * as Types from "../../constants"

const searchReducer = (state = '', action) => {
    switch (action.type) {
        case Types.SEARCH_VALUE:
            return action.payload
        default:
            return state
    }
};

export default searchReducer;