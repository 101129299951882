import React from 'react';
import DynamicIcon from './common/DynamicIcon';
import { mobile } from 'helpers';
import { HeartFilled } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { country } from 'App';

const StyledButton = styled(Button)`
	border-radius: 0.5rem;
	color: red;
	${props => !props?.isLoggedIn && `margin: unset`}
	${props => props?.isLoggedIn && mobile && `border: unset; margin: unset`}
`;

const Favourite = ({ closeDrawer }) => {
	const isLoggedIn = localStorage.getItem('token') ? true : false;
	return (
		<>
			<Link to={`/${country}/my-favourite`} onClick={closeDrawer && closeDrawer} style={{ marginRight: '1.9em' }}>
				<StyledButton icon={<HeartFilled />} size="default" type="dashed" isLoggedIn={isLoggedIn} />
				&nbsp; {isLoggedIn && mobile && 'My Favourite'}
			</Link>
		</>
	);
};

export default Favourite;
