import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import DynamicIcon from 'components/common/DynamicIcon';
import { media, mobile } from 'helpers';
import { baseStyles } from 'styles/base';
import { country } from 'App';

export default function FindMenu() {
	return (
		<ServicesMenu>
			<ServicesCategoryWrapper>
				<ServicesWrapper>
					<CategoryHeading>Find a Tutor</CategoryHeading>
					<Link to={`/${country}/browse/tutors`}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-male-orange'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Academic</span>
						</Service>
					</Link>
					<Link to={`/${country}/lifelong/browse`}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-guitar'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Lifelong</span>
						</Service>
					</Link>
					<CategoryHeading>Find a Counsellor</CategoryHeading>
					<Link to={`/${country}/counselling`}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-icon-test43'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Counsellor</span>
						</Service>
					</Link>
				</ServicesWrapper>
				<ServicesWrapper>
					<CategoryHeading>Find Opportunities</CategoryHeading>
					<Link to={`/${country}/jobs`}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-JobCreation'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Jobs</span>
						</Service>
					</Link>
					<Link to={`/${country}/launching-soon`}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-money'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Sponsorship</span>
							<ComingSoon>Coming Soon </ComingSoon>
						</Service>
					</Link>
					<Link to={`/${country}/cudy-coordinator/educator-database`}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-icon-test11'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Coordinator</span>
						</Service>
					</Link>
				</ServicesWrapper>
				<ServicesWrapper>
					<CategoryHeading>Find University</CategoryHeading>
					<a href={'https://college.cudy.co'}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon--university'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy College</span>
						</Service>
					</a>
					<CategoryHeading>Travel & Accommodation</CategoryHeading>
					<Link to={`/${country}/launching-soon`}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-icon-test47'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Travel</span>
							<ComingSoon>Coming Soon </ComingSoon>
						</Service>
					</Link>
					<CategoryHeading>Find Talents</CategoryHeading>
					<a href={'https://wish.cudy.co'}>
						<Service>
							<DynamicIcon projectName="Cudy3.0" type={'icon-wd-applet-talent'} size={mobile ? 34 : 32} />
							<span style={{ fontSize: '0.8rem', marginLeft: 15 }}> Cudy Wish</span>
						</Service>
					</a>
				</ServicesWrapper>
				<ServicesWrapper></ServicesWrapper>
			</ServicesCategoryWrapper>
		</ServicesMenu>
	);
}

//styles

const Service = styled.span`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	text-align: center;
	color: #777;
	background-color: #fff;
	padding: 10px 20px;
	margin: 10px auto;
	border-radius: 4px;
	border: 1px solid ${baseStyles.lightGrey.one};
	width: 200px;
	box-shadow: ${baseStyles.boxShadow.hover};
	border-radius: 10px;
	position: relative;
`;
const ServicesMenu = styled.div`
	background-color: ${baseStyles.lightGrey.two};
	width: 100vw;
	padding: 2rem 1.2rem;
	user-select: none;

	${media.mobile`
	height:100vh;
    overflow-y:scroll;
    padding-bottom:100px;


	`}
`;

const ServicesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-start;

	${media.mobile`
	&& {
		padding:0px 3rem;
		margin:0px;
		flex-wrap:wrap;
	}
	`}
`;

const ServicesCategoryWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: flex-start;

	${media.mobile`
    flex-direction:column;
    padding-bottom:100px;
	align-items: center;

    `}
`;
const CategoryHeading = styled.h3`
	margin-top: 10px;
`;

const ComingSoon = styled.span`
	position: absolute;
	right: 5px;
	top: 3px;
	color: ${baseStyles.primaryColor};
	font-size: 0.5rem;
`;
