import { combineReducers } from "redux"
import userReducer from "./userReducer.js"
import authReducer from "./authReducer.js"
import companyAuthReducer from "./companyAuthReducer.js"
import placesReducer from "./placesReducer.js"
import contentfulReducer from "./contentfulReducer.js"
import articleReducer from "./articleReducer.js"
import favoriteReducer from './favoriteReducer.js'
// reducer for typesense search overlay 
import searchReducer from './searchOverlayReducers/seachReducer'
import resultReducer from './searchOverlayReducers/resultReducer'

const appReducer = combineReducers({
	user: userReducer,
	auth: authReducer,
	companyAuth: companyAuthReducer,
	place: placesReducer,
	contentful: contentfulReducer,
	article: articleReducer,
	favorite: favoriteReducer,
	searchValue: searchReducer,
	searchResult: resultReducer
})

export const rootReducer = (state, action) => appReducer(state, action)
