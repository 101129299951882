/* eslint-disable no-undef */
import { css } from 'styled-components';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { message } from 'antd';
import axios from 'axios';
import currencyFormatter from 'currency-formatter';
import { outsideI18n } from 'App';
import { t } from '@lingui/macro';
import { BASE_URL, SERVER_BASE_URL } from './constants';

const country = localStorage.getItem('country') || 'sg';
const token = localStorage.getItem('token');
message.config({ duration: 1 });

export const googleApiKey = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
export const googlePlacesApiKey = process.env.REACT_APP_GOOGLE_PLACES_API;
const googlePhotoUrl = `https://maps.googleapis.com/maps/api/place/photo`;
export const bumperAtHome = `/pub/static/4801580270486213-Promo Cudy I3 FINAL.mp4`;
export const booksAtHome = process.env.NODE_ENV === 'production' ? '/pub/static/SubSection.svg' : '/pub/static/SubSection Final.svg';
export const getPhotoUrl = reference =>
	`${googlePhotoUrl}?maxwidth=800&photoreference=${reference}&key=${process.env.REACT_APP_GOOGLE_PLACES_API}`;
export const placeholderImage = 'https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original';
export const PLACEHOLDER_OWL_IMAGE = '/pub/static/Cudy Mascot SVG-02.svg';

// const corsUrl = `https://cors-anywhere.herokuapp.com`

const instance = axios.create({ baseURL: BASE_URL });

instance.interceptors.request.use(config => {
	config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
	return config;
});

export { instance };

export const shareToSocialMedia = ({ title, text, url }) => {
	if (navigator.share) {
		navigator.share({ title, text, url }).then(() => message.success('Shared successfully'));
		return;
	}

	message.error("Your device doesn't support sharing for now");
};

export const renderAvatar = (path = '') => (path ? SERVER_BASE_URL + path : SERVER_BASE_URL + PLACEHOLDER_OWL_IMAGE);

// Render error
export function renderError(error = {}) {
	const err = error.networkError || {};
	const theError = ((err.result || {}).errors || [])[0] || error || {};
	if (theError) {
		message.error(theError.message || outsideI18n._(t`Uh oh, there's something bad happened`), 2);
		return { message: theError.message, code: theError.code || -1 };
	}
	return '';
}

export const newClient = (clientName = 'profile') => {
	return new ApolloClient({
		cache: new InMemoryCache(),
		link: createUploadLink({
			uri: `${BASE_URL}/${clientName}`,
			headers: { 'auth-token': token }
		})
	});
};

export function getDataForSitemap(data) {
	return data;
}

export const client = {
	programmeClient: new ApolloClient({
		cache: new InMemoryCache(),
		link: createUploadLink({
			uri: `${BASE_URL}/programme`,
			credentials: 'include',
			headers: {
				'auth-token': token
			}
		})
	}),
	accountClient: new ApolloClient({
		cache: new InMemoryCache(),
		link: createUploadLink({
			uri: `${BASE_URL}/account`,
			credentials: 'include',
			headers: {
				'auth-token': token
			}
		})
	}),
	companyClient: new ApolloClient({
		cache: new InMemoryCache(),
		link: createUploadLink({
			uri: `${BASE_URL}/company`,
			credentials: 'include',
			headers: {
				'auth-token': token
			}
		})
	}),
	cudyJobsClient: new ApolloClient({
		cache: new InMemoryCache(),
		link: createUploadLink({
			uri: `${BASE_URL}/cudy-jobs`,
			credentials: 'include',
			headers: {
				'auth-token': token
			}
		})
	}),
	profileClient: new ApolloClient({
		cache: new InMemoryCache(),
		link: createUploadLink({
			uri: `${BASE_URL}/profile`,
			headers: {
				'auth-token': token
			}
		})
	}),
	bookingClient: new ApolloClient({
		cache: new InMemoryCache(),
		link: createUploadLink({
			uri: `${BASE_URL}/booking`,
			headers: {
				'auth-token': token
			}
		})
	}),
	resourceClient: new ApolloClient({
		cache: new InMemoryCache(),
		link: createUploadLink({
			uri: `${BASE_URL}/resource`,
			credentials: 'include',
			headers: {
				'auth-token': token
			}
		})
	}),
	transcriptClient: new ApolloClient({
		cache: new InMemoryCache(),
		link: createUploadLink({
			uri: `${BASE_URL}/transcript`,
			headers: {
				'auth-token': token
			}
		})
	}),
	readsFeaturedClient: new ApolloClient({
		cache: new InMemoryCache(),
		link: createUploadLink({
			uri: `${BASE_URL}/image`,
			credentials: 'include',
			headers: {
				'auth-token': token
			}
		})
	}),
	cudyClassClient: new ApolloClient({
		cache: new InMemoryCache(),
		link: createUploadLink({
			uri: `${BASE_URL}/cudyClass`,
			headers: {
				'auth-token': token
			}
		})
	}),
	cudyRoomClient: new ApolloClient({
		cache: new InMemoryCache(),
		link: createUploadLink({
			uri: `${BASE_URL}/cudyrooms`,
			headers: {
				'auth-token': token
			}
		})
	}),
	storiesClient: new ApolloClient({
		cache: new InMemoryCache(),
		link: createUploadLink({
			uri: `${BASE_URL}/story`,
			credentials: 'include',
			headers: {
				'auth-token': token
			}
		})
	}),
	softwareClient: new ApolloClient({
		cache: new InMemoryCache(),
		link: createUploadLink({
			uri: `${BASE_URL}/software`,
			credentials: 'include',
			headers: {
				'auth-token': token
			}
		})
	}),
	portfolioClient: new ApolloClient({
		cache: new InMemoryCache(),
		link: createUploadLink({
			uri: `${BASE_URL}/portfolio`,
			credentials: 'include',
			headers: {
				"auth-token": token,
			}
		})
	}),
	paymentInfoClient: new ApolloClient({
		cache: new InMemoryCache(),
		link: createUploadLink({
			uri: `${BASE_URL}/paymentInfo`,
			credentials: 'include',
			headers: {
				'auth-token': token
			}
		})
	}),
	tutorScoreAndTestimonail: new ApolloClient({
		cache: new InMemoryCache(),
		link: createUploadLink({
			uri: `${BASE_URL}/testimonials`,
			credentials: 'include',
			headers: {
				'auth-token': token
			}
		})
	}),
	disputeClient: new ApolloClient({
		cache: new InMemoryCache(),
		link: createUploadLink({
			uri: `${BASE_URL}/dispute`,
			credentials: 'include',
			headers: {
				'auth-token': token
			}
		})
	}),
	matchingGuide: new ApolloClient({
		cache: new InMemoryCache(),
		link: createUploadLink({
			uri: `${BASE_URL}/matching-guide`,
			credentials: 'include',
			headers: {
				'auth-token': token
			}
		})
	})
};

export const randomColor = arr => arr.slice().sort(() => Math.random() - 0.58)[0];

export const pricer = price => {
	const code = country === 'id' ? 'id-ID' : 'en-SG';
	const currency = country === 'id' ? 'IDR' : 'SGD';
	return new Intl.NumberFormat(code, { currency }).format(price);
};

export const upperCase = word => word && word[0].toUpperCase() + word.slice(1);

// Range from date/time
export const range = (from, to) => Array.from({ length: Math.ceil((to - from + 1) / 1) }, (_, idx) => from + idx * 1);

// Media queries Styled-Components
const sizes = {
	tablet: 768,
	mobile: 767.98
};

export const media = Object.keys(sizes).reduce((acc, label) => {
	acc[label] = (...args) => css`
		@media (max-width: ${sizes[label]}px) {
			${css(...args)};
		}
	`;
	return acc;
}, {});

export const mobile = window.innerWidth <= 767.98;
export const tablet = window.innerWidth > 414 && window.innerWidth < 769;

export const coloredLog = (...args) => ({
	black: `\x1b[30m${args.join(' ')}`,
	red: `\x1b[31m${args.join(' ')}`,
	green: `\x1b[32m${args.join(' ')}`,
	yellow: `\x1b[33m${args.join(' ')}`,
	blue: `\x1b[34m${args.join(' ')}`,
	magenta: `\x1b[35m${args.join(' ')}`,
	cyan: `\x1b[36m${args.join(' ')}`,
	white: `\x1b[37m${args.join(' ')}`,
	bgBlack: `\x1b[40m${args.join(' ')}\x1b[0m`,
	bgRed: `\x1b[41m${args.join(' ')}\x1b[0m`,
	bgGreen: `\x1b[42m${args.join(' ')}\x1b[0m`,
	bgYellow: `\x1b[43m${args.join(' ')}\x1b[0m`,
	bgBlue: `\x1b[44m${args.join(' ')}\x1b[0m`,
	bgMagenta: `\x1b[45m${args.join(' ')}\x1b[0m`,
	bgCyan: `\x1b[46m${args.join(' ')}\x1b[0m`,
	bgWhite: `\x1b[47m${args.join(' ')}\x1b[0m`
});

const formattingOptions =
	country === 'id'
		? { thousand: '.', precision: 0, decimal: ',' }
		: {
			thousand: ',',
			precision: 2,
			decimal: '.'
		};

export function formatCurrency(inputValue) {
	return currencyFormatter.format(inputValue, formattingOptions);
}

export function unFormatCurrency(inputValue) {
	return currencyFormatter.unformat(inputValue, formattingOptions);
}

export const toTimestamp = strDate => {
	const datum = Date.parse(strDate);
	return datum / 1000;
};

// export const formatCurrency = (value) => {
// 	if (!value) {
// 		return "0"
// 	}
// 	return `${parseInt(value, 10).toLocaleString("de-DE", "minimumFractionDigits", 2)}`
// }


export const currencies = {
	"lk": "LKR",
	"ph": "PHP",
	"sg": "SGD",
	"id": "IDR",
	"in": "INR",
	"my": "MYR",
	'us': "USD"
}
