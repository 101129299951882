import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import '@brainhubeu/react-carousel/lib/style.css';
import './styles/index.less';
import '@ant-design/compatible/assets/index.css';
import 'antd/dist/antd.less';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BASE_URL, GTM_ID } from 'helpers/constants';
import gtm from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import { CanduProvider } from '@candulabs/react-sdk';

// eslint-disable-next-line no-undef
// if (process.env.NODE_ENV !== "development")
Sentry.init({ dsn: 'https://2666790bff4741f4b62eea2be7173c2c@o284447.ingest.sentry.io/5283969' });

const gtmArgs = {
	gtmId: GTM_ID
};

gtm.initialize(gtmArgs);

const uploadLink = createUploadLink({
	uri: BASE_URL + '/profile',
	headers: { 'auth-token': localStorage.getItem('token'), 'Content-Type': 'application/graphql' }
});

export const client = new ApolloClient({
	cache: new InMemoryCache({ addTypename: false }),
	link: uploadLink
});

const styleguide = {
	P: props => <p {...props.attributes} className="red-P" />
};

ReactDOM.render(
	<CanduProvider clientToken={process.env.REACT_APP_CANDU_CLIENT_TOKEN} styleguide={styleguide}>
		<App />
	</CanduProvider>,
	document.getElementById('root')
);

serviceWorker.unregister();
