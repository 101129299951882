import React, { useState } from "react";
import { Typography, Button, Input, message } from 'antd';
import { RightOutlined, AlertOutlined, CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import styled from "styled-components"
import {
    generateShareURL
} from "../utils/payment"


const { Title, Text } = Typography;


const GeneratePaymentURL = ({ i18n, nextPage, userId, country, setSharedPaymentCode, paymentCode, className, serviceName }) => {
    const [generateLoading, setGenerateLoading] = useState(false)

    const generatePaymentCode = async () => {
        try {
            setGenerateLoading(true)
            const { codeSharedPayment } = await generateShareURL(serviceName);
            setGenerateLoading(false)
            setSharedPaymentCode(codeSharedPayment)
        } catch (err) {
            setGenerateLoading(false)
        }
    }

    const CopyComponent = () => (
        <CopyToClipboard onCopy={async () => {
            message.success(i18n._(t`copied success`));
        }} text={`${!paymentCode ? "" : `${window.location.origin}/${country}/payment/share/${paymentCode}`}`}>
            <CopyOutlined />
        </CopyToClipboard>
    )

    return (
        <GenerateComp className={className || ""} >
            <Title level={3}>Generate Shareable URL</Title>
            <Text>If you want to share the payment page with your friend or parent, you can click generate button to get a shareable URL.</Text>
            <div className="share_note">
                <div className="share">
                    <Input
                        disabled={true}
                        style={{ width: "35rem" }} addonBefore={`Payment URL: `} addonAfter={<CopyComponent />} value={`${!paymentCode ? "" : `${window.location.origin}/${country}/payment/share/${paymentCode}`}`} />
                    <Button type="primary" loading={generateLoading} icon={<AlertOutlined />} onClick={generatePaymentCode}> Generate </Button>
                </div>
                <Text type="secondary">NOTE: This payment URL will be not active until you have started the payment process {nextPage && `in the next step`}.</Text>
            </div>
            {nextPage && <Button onClick={nextPage} type="primary" > Next <RightOutlined /> </Button>}
        </GenerateComp>
    )
}


export default withI18n()(GeneratePaymentURL);


const GenerateComp = styled.div`
    display: flex;
    margin-top: 2rem;
    justifiy-content: center;
    align-items: center;

    flex-direction: column;
        text-align: center;
    button{
        margin: 3rem auto;
        width: 50%;
    }

    .share{
        display: flex;
        align-items: center;
        justifiy-content: center;
        height: 5rem;
        button{
            width: 15%;
        }
    }
`