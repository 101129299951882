export const notifyMe = (user, message) => {
	if (!("Notification" in window)) {
		alert("This browser does not support desktop notification")
	} else if (Notification.permission === "granted") {
		let options = {
			body: message,
			dir: "ltr"
		}
		new Notification(user + " posted a comment", options)
	}

	// Note, Chrome does not implement the permission static property
	// So we have to check for NOT 'denied' instead of 'default'
	else if (Notification.permission !== "denied") {
		Notification.requestPermission(function(permission) {
			// Whatever the user answers, we make sure we store the information
			if (!("permission" in Notification)) {
				Notification.permission = permission
			}
			if (permission === "granted") {
				let options = {
					body: message,
					dir: "ltr"
				}
				new Notification(user + " posted a comment", options)
			}
		})
	}
}

export const notifyBooking = (tutor, message) => {
	if (!("Notification" in window)) {
		alert("This browser does not support desktop notification")
	} else if (Notification.permission === "granted") {
		let options = {
			body: message,
			dir: "ltr"
		}
		new Notification(`${tutor} is booked`, options)
	} else if (Notification.permission !== "denied") {
		Notification.requestPermission(function(permission) {
			// Whatever the user answers, we make sure we store the information
			if (!("permission" in Notification)) {
				Notification.permission = permission
			}
			if (permission === "granted") {
				let options = {
					body: message,
					dir: "ltr"
				}
				new Notification(`${tutor} is booked`, options)
			}
		})
	}
}
