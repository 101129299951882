/* eslint-disable no-undef */
import React from "react"
import { Trans } from "@lingui/macro"

export const BASE_URL = process.env.REACT_APP_BASE_URL
export const CUDY_URL = "https://cudy.co"
export const WINDOW_FEATURES = "height=350,width=600,left=300,top=200"
export const CUDY_FB_APP_ID = "111972940328525"
export const CUDY_LINKEDIN_ORG_ID = "13398054"
export const SERVER_BASE_URL = BASE_URL + "/src"
export const GTM_ID = process.env.REACT_APP_GTM_ID
export const TOTAL_USER_SHOW = (4 * 3)

export const Contentful = {
	SPACE_ID: process.env.REACT_APP_CONTENTFUL_SPACE_ID
}

export const GENDER = { male: "Male", female: "Female" }

export const CUDY_DEFINITION = (
	<Trans>
		Hey, I've just created an account in Cudy Marketplace. Cudy Marketplace is a marketplace for you to find best
		tutors around
		Indonesia, Singapore, Malaysia, Philippines, India, Sri Lanka, to teach you anything
	</Trans>
)
