// User constants
export const FETCH_USER = "FETCH_USER"
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS"
export const FETCH_USER_ERROR = "FETCH_USER_ERROR"
export const FETCH_ALL_USERS = "FETCH_ALL_USERS"
// Auth constants
export const AUTH_USER = "AUTH_USER"
export const LOADING_AUTH = "LOADING_AUTH"
export const UNAUTH_USER = "UNAUTH_USER"

// Company constants
export const FETCH_COMPANIES = "FETCH_USER"
export const FETCH_ALL_COMPANIES = "FETCH_ALL_USERS"
// Company Auth constants
export const AUTH_COMPANY = "AUTH_USER"
export const LOADING_AUTH_COMPANY = "LOADING_AUTH"
export const UNAUTH_COMPANY = "UNAUTH_USER"

// Places constants
export const LOADING_PLACES = "LOADING_PLACES"
export const EDIT_LOADING_PLACES = "EDIT_LOADING_PLACES"
export const FETCH_ALL_PLACES = "FETCH_ALL_PLACES"
export const FETCH_PLACE_TYPES = "FETCH_PLACE_TYPES"
export const FETCH_NEARBY_PLACES = "FETCH_NEARBY_PLACES"
export const FETCH_NEARBY_PLACES_ERROR = "FETCH_NEARBY_PLACES_ERROR"
export const FIND_PLACE = "FIND_PLACE"
export const SEARCH_PLACES = "SEARCH_PLACES"
export const FETCH_PHOTO = "FETCH_PHOTO"
export const FETCH_PLACE_DETAILS = "FETCH_PLACE_DETAILS"
export const FETCH_TUITION_CATEGORIES = "FETCH_TUITION_CATEGORIES"
export const SEARCH_NEARBY_TUITION = "SEARCH_NEARBY_TUITION"
export const SEARCH_NEARBY_TUITION_ERROR = "SEARCH_NEARBY_TUITION_ERROR"
export const FETCH_TUITION_BY_ID = "FETCH_TUITION_BY_ID"
export const SEARCH_TUITION = "SEARCH_TUITION"
export const FETCH_NEARBY_PLACES_GOOGLE = "FETCH_NEARBY_PLACES_GOOGLE"
export const FETCH_NEARBY_PLACE_DETAILS = "FETCH_NEARBY_PLACE_DETAILS"

// Other constants
export const SET_LANGUAGE = "SET_LANGUAGE"
export const SET_COUNTRY = "SET_COUNTRY"

// Contentful constants
export const LOADING_CONTENTFUL = "LOADING_CONTENTFUL"
export const FETCH_ECOMMERCE_PRODUCTS = "FETCH_ECOMMERCE_PRODUCTS"
export const FETCH_ECOMMERCE_PRODUCTS_ERROR = "FETCH_ECOMMERCE_PRODUCTS_ERROR"
export const FETCH_CONSULTANTS = "FETCH_CONSULTANTS"
export const FETCH_CONSULTANTS_ERROR = "FETCH_CONSULTANTS_ERROR"
export const FETCH_SHOP_CATEGORIES = "FETCH_SHOP_CATEGORIES"
export const FETCH_SHOP_CATEGORIES_ERROR = "FETCH_SHOP_CATEGORIES_ERROR"
export const FETCH_ECOMMERCE_SITES = "FETCH_ECOMMERCE_SITES"
export const FETCH_ECOMMERCE_SITES_ERROR = "FETCH_ECOMMERCE_SITES_ERROR"
export const FETCH_LIFELONG_SLIDERS = "FETCH_LIFELONG_SLIDERS"
export const FETCH_LIFELONG_SLIDERS_ERROR = "FETCH_LIFELONG_SLIDERS_ERROR"
export const FETCH_ASSESSMENTS = "FETCH_ASSESSMENTS"
export const FETCH_ASSESSMENTS_ERROR = "FETCH_ASSESSMENTS_ERROR"
export const FETCH_ASSESSMENT_OPTIONS = "FETCH_ASSESSMENT_OPTIONS"
export const FETCH_ASSESSMENT_OPTIONS_ERROR = "FETCH_ASSESSMENT_OPTIONS_ERROR"
export const FETCH_ASSESSMENT_CATEGORIES = "FETCH_ASSESSMENT_CATEGORIES"
export const FETCH_ASSESSMENT_CATEGORIES_ERROR = "FETCH_ASSESSMENT_CATEGORIES_ERROR"
export const FETCH_ASSESSMENT_RESULTS = "FETCH_ASSESSMENT_RESULTS"
export const FETCH_ASSESSMENT_RESULTS_ERROR = "FETCH_ASSESSMENT_RESULTS_ERROR"

// Article constants
export const LOADING_ARTICLE = "LOADING_ARTICLE"
export const FETCH_ARTICLE_CATEGORIES = "FETCH_ARTICLE_CATEGORIES"
export const FETCH_ARTICLE_CATEGORIES_ERROR = "FETCH_ARTICLE_CATEGORIES_ERROR"
export const FETCH_ARTICLES = "FETCH_ARTICLES"
export const FETCH_ARTICLES_ERROR = "FETCH_ARTICLES_ERROR"

// Transcript constants
export const LOADING_TRANSCRIPT = 'LOADING_TRANSCRIPT'
export const FETCH_HOURS_TRANSCRIPT = 'FETCH_HOURS_TRANSCRIPT'


// Favorite constants
export const ADD_TO_FAVORITE = 'ADD_TO_FAVORITE'
export const REMOVE_FROM_FAVORITE = 'REMOVE_FROM_FAVORITE'
export const LOADING_FAVORITE_ITEMS = 'LOADING_FAVORITE_ITEMS'
export const FETCH_FAVORITE_ITEMS = 'FETCH_FAVORITE_ITEMS'
export const UPDATE_FAVORITE_ITEMS = 'UPDATE_FAVORITE_ITEMS'
export const REMOVE_FROM_FAVORITE_ITEMS = 'REMOVE_FROM_FAVORITE_ITEMS'
export const CLAER_FAVORITE_LIST = 'CLAER_FAVORITE_LIST'

// Typesense Search Overlay Constants

export const SEARCH_VALUE = 'SEARCH_VALUE'
export const HAS_RESULTS = 'HAS_RESULTS'