import React, { useState } from 'react';
import styled from 'styled-components';
import { t, Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import { Dropdown } from 'antd';
import { SearchOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { InstantSearch, connectSearchBox } from 'react-instantsearch-dom';
import { useDispatch } from 'react-redux';
import { searchValue } from '../../../store/actions/searchOverlayActions';
import { typesenseSearch } from '../../../utils/typesense/config';
import TypesenseResponse from './TypesenseResponse';

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
	server: typesenseSearch,
	collectionSpecificSearchParameters: {
		tutors: {
			queryBy: 'FirstName, skill, Subjects, curriculum'
		},
		contents: {
			queryBy: 'FirstName, Title, Subject'
		}
	}
});

const searchClient = typesenseInstantsearchAdapter.searchClient;

const SearchOverlay = withI18n()(props => {
	const [dropdown, setDropdown] = useState(false);

	const dispatch = useDispatch();

	const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => {
		const Cancel = () => {
			setDropdown(false);
			refine('');
		};

		const searchChange = e => {
			refine(e.currentTarget.value);
		};

		// on value change dispatch will automatically get triggered
		dispatch(searchValue(currentRefinement));

		return (
			<SearchBar>
				<IconBox showRadius={currentRefinement ? 'show' : 'remove'}>
					{isSearchStalled ? (
						<LoadingOutlined style={{ fontSize: '2rem', color: 'orange' }} />
					) : (
						<SearchOutlined style={{ fontSize: '2rem', color: 'orange' }} />
					)}
				</IconBox>
				<SearchInput
					autoFocus
					value={currentRefinement}
					onChange={searchChange}
					placeholder={`${props.i18n._(t`Tutors, contents, lessons, ...`)}`}
					autoComplete="false"
					autoCapitalize="false"
					spellCheck="false"
					maxLength="100"
					type="search"
					enterkeyhint="go"
				/>
				<ClearBtn>
					{currentRefinement?.length !== 0 ? (
						<FlexGroup>
							<div aria-label="Clear" title="Clear" onClick={() => refine('')}>
								<Trans>Clear</Trans>
							</div>
							<BorderLine />
						</FlexGroup>
					) : (
						<div style={{ width: '54px', padding: '0px' }} />
					)}
				</ClearBtn>
				<CancelBtn aria-label="Close" title="Close" onClick={Cancel} showRadius={currentRefinement ? 'show' : 'remove'}>
					<CloseOutlined style={{ fontSize: '1.1rem', color: 'gray' }} />
				</CancelBtn>
			</SearchBar>
		);
	};

	const CustomSearchBox = connectSearchBox(SearchBox);

	const menu = (
		<MainContainer>
			<InstantSearch searchClient={searchClient} indexName="tutors">
				<CustomSearchBox />
				<TypesenseResponse dropdown={dropdown} setDropdown={setDropdown} />
			</InstantSearch>
		</MainContainer>
	);

	return (
		<Dropdown
			overlay={menu}
			trigger={['click']}
			onVisibleChange={setDropdown}
			visible={dropdown}
			overlayStyle={{ zIndex: 1000, position: 'fixed' }}>
			{props.children}
		</Dropdown>
	);
});

export default SearchOverlay;

// ███████ ████████ ██    ██ ██      ███████ ███████
// ██         ██     ██  ██  ██      ██      ██
// ███████    ██      ████   ██      █████   ███████
//      ██    ██       ██    ██      ██           ██
// ███████    ██       ██    ███████ ███████ ███████

const SearchBar = styled.div`
	margin-top: 2rem;
	display: flex;
	border-radius: 1rem;
	box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
		0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
`;

const ClearBtn = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	height: 5rem;
	padding: 0 1.45rem;
	cursor: pointer;
`;

const CancelBtn = styled.button`
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	height: 5rem;
	background-color: white;
	padding-right: 1.5rem;
	border-top-right-radius: 1rem;
	border-bottom-right-radius: ${props => (props.showRadius !== 'show' ? '1rem' : '0rem')};
`;

const BorderLine = styled.div`
	background-color: #f8f8f8;
	width: 2px;
	height: 3rem;
`;

const FlexGroup = styled.div`
	display: flex;
	gap: 1rem;
	justify-content: start;
	align-items: center;
`;

const SearchInput = styled.input`
	width: 50vw;
	height: 5rem;
	caret-color: #c78e24;
	font-size: 1.2rem;
	border: none;
	:focus {
		outline: none;
		border: none;
	}
`;

const IconBox = styled.div`
	width: 5rem;
	height: 5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	border-top-left-radius: 1rem;
	border-bottom-left-radius: ${props => (props.showRadius !== 'show' ? '1rem' : '0rem')};
`;

const MainContainer = styled.div`
	position: fixed;
	margin-left: 7rem;
`;
