import * as types from "../constants"

const initialStates = {
	places: [],
	pageToken: "",
	loading: false,
	editLoading: false,
	place: {},
	placeTypes: [],
	tuitionCategories: [],
	nearbyGoogle: [],
	nearbyTuitions: [],
	tuition: {},
	tuitions: [],
	placesError: null,
	isDifferentCountry: null,
	nearbyTuitionsError: null
}

function reducer(state = initialStates, { payload, type }) {
	switch (type) {
		case types.LOADING_PLACES:
			return { ...state, loading: true }
		case types.EDIT_LOADING_PLACES:
			return { ...state, editLoading: payload }
		case types.FETCH_ALL_PLACES:
			return { ...state, places: payload, loading: false }
		case types.FETCH_PLACE_TYPES:
			return { ...state, placeTypes: payload, loading: false }
		case types.FIND_PLACE:
			return { ...state, places: payload, loading: false }
		case types.SEARCH_PLACES:
			return { ...state, places: payload, loading: false }
		case types.FETCH_NEARBY_PLACES:
			return {
				...state,
				places: payload.data,
				isDifferentCountry: payload.isDifferentCountry,
				loading: false
			}
		case types.FETCH_NEARBY_PLACES_GOOGLE:
			return {
				...state,
				nearbyGoogle: payload,
				loading: false
			}
		case types.FETCH_NEARBY_PLACES_ERROR:
			return { ...state, placesError: payload, loading: false }
		case types.FETCH_PHOTO:
			return { ...state, photo: payload, loading: false }
		case types.FETCH_PLACE_DETAILS:
			return { ...state, place: payload, loading: false }
		case types.FETCH_NEARBY_PLACE_DETAILS:
			return { ...state, place: payload, loading: false }
		case types.FETCH_TUITION_CATEGORIES:
			return { ...state, tuitionCategories: payload, loading: false }
		case types.SEARCH_NEARBY_TUITION:
			return { ...state, tuitions: payload, loading: false }
		case types.SEARCH_NEARBY_TUITION_ERROR:
			return { ...state, nearbyTuitionsError: payload, loading: false }
		case types.FETCH_TUITION_BY_ID:
			return { ...state, tuition: payload, loading: false }
		case types.SEARCH_TUITION:
			return { ...state, tuitions: payload, loading: false }
		default:
			return state
	}
}

export default reducer
