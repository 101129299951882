import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Loading from 'components/Loading';

const ReadDetail = React.lazy(() => import('pages/ReadsSection/_RenderDetails'));
const BrowseListing = React.lazy(() => import('pages/BrowseListing'));
const TestimonialSuccess = React.lazy(() => import('pages/Tutor/Profile/TestimonialSuccess'));
const AddTestimonials = React.lazy(() => import('pages/Tutor/Profile/AddTestimonials'));
const ConditionalPage = React.lazy(() => import('components/common/ConditionalPage'));
const Reads = React.lazy(() => import('pages/ReadsSection'));
const CreateReads = React.lazy(() => import('pages/ReadsSection/createCudyRead'));
const EditReads = React.lazy(() => import('pages/ReadsSection/editCudyRead'));
const ReadSuccess = React.lazy(() => import('pages/ReadsSection/readSuccess'));
const ReadPublic = React.lazy(() => import('pages/ReadsSection/public'));
// import Reads from "pages/Reads"

//import Transcribe from "pages/CudyTranscribe/TranscribeItem";
const ResponsePage = React.lazy(() => import('pages/responsePage'));
const Home = React.lazy(() => import('pages/Home'));
const Marketplace = React.lazy(() => import('pages/Marketplace'));
const MyFavorite = React.lazy(() => import('pages/MyFavourite/index'));
const BrowseCounsellor = React.lazy(() => import('pages/Counselling/BrowseCounsellor'));
const Assessment = React.lazy(() => import('pages/Counselling/Assessment'));
const Success = React.lazy(() => import('pages/Counselling/Assessment/Success'));
const BrowseCoaches = React.lazy(() => import('pages/Lifelong/BrowseCoaches'));
const Lifelong = React.lazy(() => import('pages/Lifelong'));
const StudyShop = React.lazy(() => import('pages/StudyShop'));
const SignupCounsellor = React.lazy(() => import('pages/Counselling/Signup'));
const StudyPlaces = React.lazy(() => import('pages/StudyPlaces'));
const Public = React.lazy(() => import('pages/Tutor/Assignments/Public'));
const Feeds = React.lazy(() => import('pages/Home/Feeds'));
const TutorProfile = React.lazy(() => import('pages/Tutor/Profile'));
// const ContentDetail = React.lazy(() => import('pages/BrowseListing/Content'));
// const ContentDetail = React.lazy(() => import('pages/BrowseListing/Content'));
const Post = React.lazy(() => import('pages/Tutor/Assignments/Post'));
const Assignments = React.lazy(() => import('pages/Tutor/Assignments'));
const Signup = React.lazy(() => import('pages/Signup'));
const RegisterSuccess = React.lazy(() => import('pages/Signup/RegisterSuccess'));
const Signin = React.lazy(() => import('pages/Signin'));
const Settings = React.lazy(() => import('pages/Tutor/Settings'));
const AdditionalInfoTutor = React.lazy(() => import('pages/AdditionalInfoTutor'));
const OnboardingStudents = React.lazy(() => import('pages/OnboardingStudents'));
const AssignmentDetails = React.lazy(() => import('pages/Tutor/Assignments/AssignmentDetails'));
const AccountActive = React.lazy(() => import('pages/AccountActive'));
const ResendVerification = React.lazy(() => import('pages/ResendVerification'));
const Resent = React.lazy(() => import('pages/ResendVerification/Resent'));
const Thankyou = React.lazy(() => import('pages/AdditionalInfoTutor/Thankyou'));
const WhoViewed = React.lazy(() => import('pages/Tutor/Profile/WhoViewed'));
const ForgotSent = React.lazy(() => import('pages/ForgotPassword/Sent'));
const TransactionList = React.lazy(() => import('pages/TransactionList'));
const BookingList = React.lazy(() => import('pages/BookingList'));
const Chats = React.lazy(() => import('pages/Chats'));
const ForgotPassword = React.lazy(() => import('pages/ForgotPassword'));
const NewPassword = React.lazy(() => import('pages/ForgotPassword/NewPassword'));
const Acceptance = React.lazy(() => import('pages/Tutor/Assignments/Acceptance'));
const PasswordChanged = React.lazy(() => import('pages/ForgotPassword/PasswordChanged'));
const CounsellingHome = React.lazy(() => import('pages/Counselling/Home'));
const CudySoftwareBrowse = React.lazy(() => import('pages/CudySoftware/index.jsx'));
const CudySoftwareInfo = React.lazy(() => import('pages/CudySoftware/SoftwareInfo/index.jsx'));
const CudySoftwareMyDeals = React.lazy(() => import('pages/CudySoftware/MyDeals/index.jsx'));
const CudySoftwareCreateSoftware = React.lazy(() => import('pages/CudySoftware/CreateSoftware/index.jsx'));
const CudySoftwareVendorDashboard = React.lazy(() => import('pages/CudySoftware/VendorDashboard/index.jsx'));
const CudySoftwareRedemptionCode = React.lazy(() => import('pages/CudySoftware/VendorDashboard/RedemptionCode.jsx'));
const CudySoftwareCodeGenerator = React.lazy(() => import('pages/CudySoftware/RedemptionCodeGenerator'));
//const Blog = React.lazy(() => import('pages/Blog'));
const SalaryScrape = React.lazy(() => import('pages/SalaryScrape'));
const CudyTranscribe = React.lazy(() => import('pages/CudyTranscribe'));
const BuyTranscribeServices = React.lazy(() => import('pages/CudyTranscribe/BuyTranscribeServices/BuyForm'));
const TranscripeItem = React.lazy(() => import('pages/CudyTranscribe/TranscribeItem'));
const SubtitlesEditor = React.lazy(() => import('pages/CudyTranscribe/SubTitles'));
const CudyClasses = React.lazy(() => import('pages/CudyClasses'));
const JoinCudyClasses = React.lazy(() => import('pages/CudyClasses/ClassJoinPage'));
const CudyJobsBrowse = React.lazy(() => import('pages/CudyJobs/Browse'));
const CudyJobsDashborad = React.lazy(() => import('pages/CudyJobs/Dashboard'));
const CudyJobsAddProject = React.lazy(() => import('pages/CudyJobs/AddProjects'));
const CudyJobsEditProject = React.lazy(() => import('pages/CudyJobs/EditProjects'));
const CudyJobsAddJob = React.lazy(() => import('pages/CudyJobs/AddJobs'));
const CudyJobsEditJob = React.lazy(() => import('pages/CudyJobs/EditJobs'));
const CudyJobsViewProject = React.lazy(() => import('pages/CudyJobs/ViewProject'));
const CudyJobsViewJob = React.lazy(() => import('pages/CudyJobs/ViewJob'));
const CudyJobsAppliedJobs = React.lazy(() => import('pages/CudyJobs/MyAppliedJobs'));
const CudyJobsApplicationsJobs = React.lazy(() => import('pages/CudyJobs/JobsApplications'));
const CudyJobsApplicationsProjects = React.lazy(() => import('pages/CudyJobs/ProjectsApplications'));


const SignupCountry = React.lazy(() => import('pages/Signup/CompanyAccount'));
const CudyRooms = React.lazy(() => import('pages/CudyRooms'));
// const NegotiationStatus = React.lazy(() => import('pages/Dispute/negotiationstatus'));
// const Dispute = React.lazy(() => import('pages/Dispute'));
// const BuyDispute= React.lazy(()=>import('pages/Dispute/BuyForm'));
const BlogItem = React.lazy(() => import('pages/ReadsSection/cudyBlogs/BlogItem'));
const BlogsBage = React.lazy(() => import('pages/ReadsSection/cudyBlogs/index'));
const SharedPaymentURLStatus = React.lazy(() => import('pages/ResultPayment/SharedPaymentURL'));
const BookingCheckout = React.lazy(() => import('pages/Checkout/booking'));
const PaymentResult = React.lazy(() => import('pages/ResultPayment/index'));

//cudy sponsorship
const BrowseSponsorships = React.lazy(() => import('pages/CudySponsorship/BrowseSponsorships'));
const ViewSponsorship = React.lazy(() => import('pages/CudySponsorship/ViewSponsorship'));
const CreateSponsorship = React.lazy(() => import('pages/CudySponsorship/CreateSponsorship'));
const SponsorCampaign = React.lazy(() => import('pages/CudySponsorship/SponsorCampaign'));
const CudySponsorship = React.lazy(() => import('pages/CudySponsorship'));
//cudy college
const CudyCollege = React.lazy(() => import('pages/CudyCollege'));
const MentorListings = React.lazy(() => import('pages/CudyCollege/MentorListing'));
const PathwayMatch = React.lazy(() => import('pages/CudyCollege/PathwayMatch'));
const PathwayResults = React.lazy(() => import('pages/CudyCollege/PathwayResults'));
const UniversityProfile = React.lazy(() => import('pages/CudyCollege/UniversityProfile'));

//cudy tale
const CudyTale = React.lazy(() => import('pages/CudyTale'));
const TaleCategory = React.lazy(() => import('pages/CudyTale/TaleCategory'));
const AuthorProfile = React.lazy(() => import('pages/CudyTale/AuthorProfile'));
const SummaryPage = React.lazy(() => import('pages/CudyTale/SummaryPage'));
const TalePage = React.lazy(() => import('pages/CudyTale/TalePage'));
const SearchPage = React.lazy(() => import('pages/CudyTale/SearchPage'));
const CreateTale = React.lazy(() => import('pages/CudyTale/CreateTale'));

// const Blog = React.lazy(() => import("pages/Blog"))
const CareerAssessment = React.lazy(() => import('pages/CareerAssessment'));
const CareerJobsAssessment = React.lazy(() => import('pages/CareerAssessment/Assessment'));
const CareerJobsSuccess = React.lazy(() => import('pages/CareerAssessment/Assessment/Success'));

const Content = React.lazy(() => import('pages/Content'));
const ContentDashboard = React.lazy(() => import('pages/Content/dashboard/index'));

const ContentDetail = React.lazy(() => import('pages/BrowseListing/ContentDetails/index'));
const BuyContentService = React.lazy(() => import('pages/Content/BuyContentService/index'));
// Cudy Portofolio
const PortofolioHome = React.lazy(() => import('pages/Portofolio'));
const ResumeBuilder = React.lazy(() => import('pages/Portofolio/ResumeBuilder'));
// End Of Cudy Portofolio

// Lesson video import
const LessonVideo = React.lazy(() => import('pages/LessonVideo'));
const WatchLessonVideo = React.lazy(() => import('pages/LessonVideo/WatchLessonVideo'));
const SearchLessonVideo = React.lazy(() => import('pages/LessonVideo/SearchLessonVideo'));
const SearchResult = React.lazy(() => import('pages/LessonVideo/SearchResult/SearchResult'));
const WatchVideo = React.lazy(() => import('pages/LessonVideo/SearchResult/WatchVideo/WatchVideo'));
const FavouriteVideos = React.lazy(() => import('pages/LessonVideo/FavouriteVideos/FavouriteVideos'));
const SearchHsitory = React.lazy(() => import('pages/LessonVideo/WatchHistory/SearchHistory'));
const RedirectComp = React.lazy(() => import('pages/Redirect'));
const Payout = React.lazy(() => import('pages/Payout'));
// End Of Lesson Video Importimport MyFavorite from '../pages/MyFavourite/index';

const Coordinator = React.lazy(() => import('pages/Coordinator/ApplicationFrom'))
const EducatorDatabase = React.lazy(() => import('pages/Coordinator/EducatorData'))
const LeaderBoard = React.lazy(() => import('pages/Coordinator/Leaderboard'))
const MyReferants = React.lazy(() => import('pages/Coordinator/MyReferants'))
const ReferalCode = React.lazy(() => import('pages/Coordinator/ReferalCode'))

// Cudy Credits Pages
const CudyCredits = React.lazy(() => import('pages/CudyCredits/index'));
const CudyCreditsHistory = React.lazy(() => import('pages/CudyCredits/CudyCreditsHistory/index'));
const RedeemCreditsDetail = React.lazy(() => import('pages/CudyCredits/RedeemCreditsDetail/index'));
const BuyCreditsServices = React.lazy(() => import('pages/CudyCredits/BuyCredits/BuyForm'));
const CreditsBoostPricesService = React.lazy(() => import('pages/CudyCredits/CreditsBoostPrices'));
//End of test pages

// Global Carte
const GlobalCartService = React.lazy(() => import('pages/GlobalCart/index'));
const BuyGlobalCartItems = React.lazy(() => import('pages/GlobalCart/BuyForm'));

//---------------

// Cudy Balance 
const BuyBalanceService = React.lazy(() => import('pages/CudyBalance/BuyForm'));
// End of cudy balance

// Vouchers
const Vouchers = React.lazy(() => import('pages/Vouchers/index'));
const CreateVouchers = React.lazy(() => import('pages/Vouchers/CreateVoucher/index'));
const VoucherHistory = React.lazy(() => import('pages/Vouchers/VoucherHistory/index'));
const BuyVouchers = React.lazy(() => import('pages/Vouchers/BuyVouchers/index'));
const VoucherDashboard = React.lazy(() => import('pages/Vouchers/VoucherDashboard/index'));
const MyVouchers = React.lazy(() => import('pages/Vouchers/MyVouchers/index'));
const GeneralUseVoucherPage = React.lazy(() => import('pages/Vouchers/GeneralUseVoucherPage/index'));
// End vouchers

// Disputes
const Dispute = React.lazy(() => import('pages/CudyDispute/index'));
const NegotiationStatus = React.lazy(() => import('pages/CudyDispute/NegotiationStatus/index'));
const BuyArbitration = React.lazy(() => import('pages/CudyDispute/NegotiationStatus/BuyForm'));
//

const Referral = React.lazy(()=> import("pages/Tutor/referral"))

export default function Routes({ language, country }) {
	return (
		<Suspense fallback={<Loading />}>
			<Switch>
				<Redirect exact from="/" to={`/${country}`} />
				<Route exact path={`/:country`} render={() => <Home language={language} />} />
				<Route path={`/:country/feeds`} component={Feeds} />

				<Route exact path="/:country/register" component={Signup} />
				<Route exact path="/:country/payout" component={Payout} />
				<Route path={`/:country/register/counsellor`} component={SignupCounsellor} />

				<Route path={`/:country/register/success`} component={RegisterSuccess} />
				<Route path={`/:country/account/active`} component={AccountActive} />
				<Route exact path={`/:country/account/resend_email`} component={ResendVerification} />
				<Route path={`/:country/account/resend_email/success`} component={Resent} />
				<Route path={`/:country/login`} component={Signin} />

				<Route path={`/:country/profile/info/thankyou`} component={Thankyou} />
				<Route path={`/:country/profile/add_testimonial/success`} component={TestimonialSuccess} />
				<Route path={`/:country/profile/add_testimonials`} component={AddTestimonials} />
				<Route path={`/:country/profile/onboarding_steps`} render={() => <AdditionalInfoTutor country={country} />} />
				<Route path={`/:country/profile/onboarding_students`} render={() => <OnboardingStudents country={country} />} />
				<Route path={`/:country/profile/settings`} component={Settings} />
				<Route path={`/:country/profile/viewed`} component={WhoViewed} />
				<Route exact path={`/:country/profile/me`} component={TutorProfile} />
				<Route path={`/:country/:token--:id--:firstName--:lastName`} exact render={() => <TutorProfile peopleProfile={true} />} />
				{/* <Route path={`/:country/:id--:title`} render={() => <ContentDetail peopleProfile={true} />} /> */}
				<Route exact path={`/:country/assignments`} component={Assignments} />
				<Route path={`/:country/assignments/public`} component={Public} />
				<Route path={`/:country/assignments/post_assignment`} component={Post} />
				<Route path={`/:country/assignments/:title--:assignment_id`} component={AssignmentDetails} />
				<Route path={`/:country/assignments/acceptance`} component={Acceptance} />
				<Route exact path={`/:country/browse/tutors`} component={Marketplace} />
				<Route exact path={`/:country/my-favourite`} component={MyFavorite} />
				<Route path={`/:country/forgot/sent`} component={ForgotSent} />
				<Route path={`/:country/forgot/new_password`} component={NewPassword} />
				<Route path={`/:country/forgot/password_changed`} component={PasswordChanged} />
				<Route path={`/:country/forgot`} component={ForgotPassword} />
				<Route exaxt path={`/:country/response`} component={ResponsePage} />
				<Route path={`/:country/study_places`} component={StudyPlaces} />
				<Route path={`/:country/chats`} component={Chats} />
				<Route path={`/:country/shop`} component={StudyShop} />
				<Route path={`/:country/browse-listing`} component={BrowseListing} />

				<Route exact path={`/:country/lifelong`} component={Lifelong} />
				<Route path={`/:country/lifelong/browse`} component={BrowseCoaches} />

				<Route exact path={`/:country/jobs/assessment-:category`} component={CareerJobsAssessment} />
				<Route path={`/:country/jobs/assessment-:category/success`} component={CareerJobsSuccess} />

				<Route path={`/:country/jobs/assessment`} component={CareerAssessment} />

				<Route exact path={`/:country/counselling`} component={CounsellingHome} />
				<Route path={`/:country/counselling/browse`} component={BrowseCounsellor} />
				<Route exact path={`/:country/counselling/assessment-:category`} component={Assessment} />
				<Route path={`/:country/counselling/assessment-:category/success`} component={Success} />
				<Route path={`/:country/transaction-list`} component={TransactionList} />
				<Route path={`/:country/booking-list`} component={BookingList} />
				<Route
					exact
					path={`/:country/jobs`}
					render={props => <CudyJobsBrowse {...props} key={props.location.key} country={country} />}
				/>
				<Route path={`/:country/jobs/dashboard`} render={() => <CudyJobsDashborad country={country} />} />
				<Route path={`/:country/jobs/add-project`} render={() => <CudyJobsAddProject country={country} />} />
				<Route
					path={`/:country/jobs/edit-project/:id`}
					render={props => <CudyJobsEditProject {...props} key={props.location.key} country={country} />}
				/>
				<Route path={`/:country/jobs/add-job`} render={() => <CudyJobsAddJob country={country} />} />
				<Route
					path={`/:country/jobs/edit-job/:id`}
					render={props => <CudyJobsEditJob {...props} key={props.location.key} country={country} />}
				/>
				<Route
					path={`/:country/jobs/project/:pvid`}
					render={props => <CudyJobsViewProject {...props} key={props.location.key} country={country} />}
				/>
				<Route path={`/:country/jobs/applied-jobs`} component={CudyJobsAppliedJobs} />
				<Route path={`/:country/jobs/applications/job/:id`} component={CudyJobsApplicationsJobs} />
				<Route path={`/:country/jobs/applications/project/:id`} component={CudyJobsApplicationsProjects} />
				<Route
					path={`/:country/jobs/job/:pvid`}
					render={props => <CudyJobsViewJob {...props} key={props.location.key} country={country} />}
				/>
				<Route path={`/:country/company/register`} render={() => <SignupCountry country={country} />} />
				<Route path={`/:country/transaction-list`} component={TransactionList} />
				<Route path={`/:country/booking-list`} component={BookingList} />
				<Route path={`/:country/cudy-rooms`} component={CudyRooms} />
				{/*<Route path={`/:country/blog`} component={ <Blog/>}/>*/}

				<Route exact path={`/:country/browse/sponsorships`} render={() => <BrowseSponsorships language={language} />} />
				<Route exact path={`/:country/cudy-sponsorship`} render={() => <CudySponsorship language={language} />} />
				<Route exact path={`/:country/sponsorship/new`} render={() => <CreateSponsorship language={language} />} />
				<Route path={`/:country/sponsorship/sponsor/:id`} render={() => <SponsorCampaign language={language} />} />
				<Route path={`/:country/sponsorship/:id`} render={() => <ViewSponsorship language={language} />} />

				<Route exact path={`/:country/cudy-college`} render={() => <CudyCollege language={language} />} />
				<Route exact path={`/:country/cudy-college/browse/mentors`} render={() => <MentorListings language={language} />} />
				<Route exact path={`/:country/cudy-college/pathway_match`} render={() => <PathwayMatch language={language} />} />
				<Route exact path={`/:country/cudy-college/pathway_results`} render={() => <PathwayResults language={language} />} />
				<Route path={`/:country/cudy-college/university/:id`} render={() => <UniversityProfile language={language} />} />

				<Route exact path="/:country/cudy-tale" render={() => <CudyTale language={language} />} />
				<Route exact path="/:country/cudy-tale/search" render={() => <SearchPage language={language} />} />
				<Route exact path="/:country/cudy-tale/new" render={() => <CreateTale language={language} />} />
				<Route path="/:country/cudy-tale/tales/:id" render={() => <TaleCategory language={language} />} />
				<Route path="/:country/cudy-tale/author/:id" render={() => <AuthorProfile language={language} />} />
				<Route path="/:country/cudy-tale/summary/:id" render={() => <SummaryPage language={language} />} />
				<Route path="/:country/cudy-tale/tale/:id" render={() => <TalePage language={language} />} />

				{/* <Route path={`/:country/reads`} component={Reads} /> */}
				<Route path={`/:country/reads/list`} component={() => <Reads />} />
				<Route path={`/:country/reads/create`} component={() => <CreateReads />} />
				<Route path={`/:country/reads/edit/:title--:postId--:userId`} component={() => <EditReads />} />
				<Route path={`/:country/reads/public`} component={ReadPublic} />
				<Route path={`/:country/reads/blogs`} exact component={BlogsBage} />
				<Route path={`/:country/reads/blogs/:blogId/:title`} exact component={BlogItem} />
				<Route path={`/:country/reads/details/:title--:postId--:userId`} component={ReadDetail} />
				<Route path={`/:country/reads/success`} component={ReadSuccess} />

				<Route path={`/:country/transaction-list`} component={TransactionList} />
				<Route path={`/:country/booking-list`} component={BookingList} />
				<Route path={`/:country/content/dashboard`} component={ContentDashboard} />
				<Route exact path={`/:country/content`} component={Content} />
				<Route exact path={`/:country/content/:id--:title`} render={() => <ContentDetail peopleProfile={true} />} />
				<Route exact path={`/:country/content/buy/:type`} render={props => <BuyContentService {...props} />} />


				<Route path={`/:country/cudytranscribe/editor/:id/:version`} component={SubtitlesEditor} />
				<Route path={`/:country/cudytranscribe/buy/:type`} component={BuyTranscribeServices} />
				<Route path={`/:country/cudytranscribe/buy/:type`} component={BuyTranscribeServices} />
				<Route path={`/:country/cudytranscribe/:id/:version`} component={TranscripeItem} />
				<Route path={`/:country/cudytranscribe`} component={CudyTranscribe} />
				{/* For Cudy Portofolio  */}
				<Route exact path="/:country/resume/builder" component={ResumeBuilder} />
				<Route path="/:country/portofolio" component={PortofolioHome} />
				{/* End Of Cudy Portofolio */}
				<Route exact path="/:country/software" component={CudySoftwareBrowse} />
				<Route path="/:country/software/info/:id" component={CudySoftwareInfo} />
				<Route path="/:country/software/my-deals" component={CudySoftwareMyDeals} />
				<Route exact path="/:country/software/create" component={CudySoftwareCreateSoftware} />
				<Route path="/:country/software/create/code-generator/:id" render={props => <CudySoftwareCodeGenerator {...props} />} />
				<Route path="/:country/software/create/code-generator" component={CudySoftwareCodeGenerator} />
				<Route path="/:country/software/create/review" render={props => <CudySoftwareInfo dealReview={true} {...props} />} />
				<Route exact path="/:country/software/vendor-dashboard" component={CudySoftwareVendorDashboard} />
				<Route path="/:country/software/vendor-dashboard/redemption-code/:id" component={CudySoftwareRedemptionCode} />

				<Route exact path="/resume/builder" component={ResumeBuilder} />
				<Route path="/:country/portfolio" component={PortofolioHome} />
				<Route exact path="/:country/resume/builder" render={props => <ResumeBuilder {...props} country={country} />} />

				{/* End Of Cudy Portofolio */}
				{/* for cudy lesson  */}
				<Route exact path={`/:country/lesson`} component={() => <LessonVideo />} />
				<Route path={`/:country/lesson/watch`} component={() => <WatchLessonVideo />} />
				<Route path={`/:country/lesson/search`} component={() => <SearchLessonVideo />} />
				<Route path={`/:country/lesson/result`} component={() => <SearchResult />} />
				<Route path={`/:country/lesson/watchVideo`} component={() => <WatchVideo />} />
				<Route path={`/:country/lesson/favourite`} component={() => <FavouriteVideos />} />
				<Route path={`/:country/lesson/history`} component={() => <SearchHsitory />} />
				{/* end of cudy lesson routes  */}

				<Route exact path="/:country/cudy-coordinator" component={Coordinator} />
				<Route exact path="/:country/cudy-coordinator/referal-code/:token--:id--:firstName--:lastName--:code--:referrerCoordinatorId" component={ReferalCode} />
				<Route exact path="/:country/cudy-coordinator/educator-database" component={EducatorDatabase} />
				<Route exact path="/:country/cudy-coordinator/leaderboard" component={LeaderBoard} />
				<Route exact path="/:country/cudy-coordinator/my-referants" component={MyReferants} />
				{/* Cudy Credits start*/}
				<Route path={`/:country/credits/home`} component={CudyCredits} />
				<Route path={`/:country/credits/history`} component={CudyCreditsHistory} />
				<Route path={`/:country/credits/detail`} component={RedeemCreditsDetail} />
				<Route path={`/:country/credits/buy/:type`} component={BuyCreditsServices} />
				<Route path={`/:country/boost`} component={CreditsBoostPricesService} />
				{/*Cudy Credits end*/}

				{/* Global Cart Service */}
				<Route path={`/:country/globalcart/home`} component={GlobalCartService} />
				<Route path={`/:country/globalcart/buy/:type`} component={BuyGlobalCartItems} />
				{/* End */}

				{/* Cudy Balance start */}
				<Route path={`/:country/balance/buy/:type`} component={BuyBalanceService} />
				{/* End */}

				{/* Vouchers start */}
				<Route path={`/:country/vouchers/home`} component={Vouchers} />
				<Route path={`/:country/vouchers/createvouchers`} component={CreateVouchers} />
				<Route path={`/:country/vouchers/voucherhistory`} component={VoucherHistory} />
				<Route path={`/:country/vouchers/buyvouchers`} component={BuyVouchers} />
				<Route path={`/:country/vouchers/voucherdashboard`} component={VoucherDashboard} />
				<Route path={`/:country/vouchers/myvouchers`} component={MyVouchers} />
				<Route path={`/:country/generalusevoucherpage`} component={GeneralUseVoucherPage} />
				{/*End */}

				{/* cudy dispute */}
				<Route path={`/:country/dispute/negotiationstatus/:id`} component={NegotiationStatus} />
				<Route path={`/:country/dispute/home`} component={Dispute} />
				<Route path={`/:country/dispute/buy/:type`} component={BuyArbitration} />
				{/* End */}

				<Route path={`/:country/cudy-classes`} exact component={CudyClasses} />
				<Route path={`/:country/cudy-classes/join/:id`} exact component={JoinCudyClasses} />

				<Route path={`/:country/payment/share/:urlCode`} component={SharedPaymentURLStatus} />
				<Route path={`/:country/payment-result/:serviceName/:transactionId`} exact component={() => <PaymentResult />} />
				<Route path={`/:country/checkout/booking`} exact component={BookingCheckout} />

				<Route path={`/:country/referral/:userId/:code`} exact component={Referral} />
				<Route path={`/:country/redirect`} exact component={() => <RedirectComp />} />
				<Route render={() => <ConditionalPage status="404" />} />
			</Switch >
		</Suspense >
	);
}
