export const boxShadow = [
	'0 12px 15px 0 rgba(0, 0, 0, 0.1), 0 17px 50px 0 rgba(0, 0, 0, 0.1)',
	'2px 12px 9px rgba(0,0,0,0.1)',
	'0 2px 5px rgba(0, 0, 0, .2)'
];

export const baseStyles = {
	primaryColor: '#FF9D00',
	mainBackgroundColor: '#f3f3f3 ',
	redColor: '#f50',
	greyColor: '#999',
	yellowColor: '#fbe126',
	greenColor: { primary: '#87d068', light: '#aae041' },
	lightGrey: {
		one: '#eee',
		two: '#f9f9f9'
	},
	socialColor: {
		SHARE: 'slategray',
		FACEBOOK: '#3c589a',
		TWITTER: '#5eaade',
		LINKEDIN: '#107ab6',
		WHATSAPP: '#4dc247',
		TELEGRAM: '#00aef0'
	},
	primaryColorRgba: 'rgba(234, 137, 16, 0.0784313725490196)',
	boxShadow: {
		main: '0 12px 15px 0 rgba(0, 0, 0, 0.1), 0 17px 50px 0 rgba(0, 0, 0, 0.1)',
		hover: '0 2px 5px rgba(0, 0, 0, .2)',
		mild: '0 12px 20px 0 rgba(0, 0, 0, 0.1), 0 17px 20px 0 rgba(0, 0, 0, 0.1)'
	}
};
