import { gql } from 'apollo-boost';

export const GET_USER_CREDITS = gql`
	mutation getUserCredits {
		getUserCredits {
			credits
		}
	}
`;

export const GET_USER_CREDITS_HISTORY = gql`
	mutation getUserCreditsHistory {
		getUserCreditsHistory {
			amount
			reason
			description
			type
		}
	}
`;

export const GET_CUDY_CREDITS_PRICES_BY_CURRENCY = gql`
	mutation getCudyCreditsPriceByCurreny {
		getCudyCreditsPriceByCurreny {
			price
			currency
			amount
		}
	}
`;

export const GET_CUDY_CREDITS_BOOST_PRICES_HOMEPAGE = gql`
	mutation {
		getCudyCreditsBoostPrices {
			HomePage {
				numberOfDays
				price
			}
		}
	}
`;

export const GET_CUDY_CREDITS_BOOST_PRICES_LISTING = gql`
	mutation {
		getCudyCreditsBoostPrices {
			CategoryListingPage {
				numberOfDays
				price
			}
		}
	}
`;

export const BOOST_TUTOR_PROFILE = gql`
	mutation boostTutorProfile($boostType: BoostType!, $numberOfDays: Int!){
		boostTutorProfile(boostType: $boostType, numberOfDays: $numberOfDays)
	}
`;

export const BOOST_TUTOR_ARTICLES = gql`
	mutation boostTutorArticle($boostType: BoostType!, $numberOfDays: Int!, $articleId:Int!){
		boostTutorArticle(boostType: $boostType, numberOfDays: $numberOfDays,  articleId: $articleId)
	}
`;

export const BOOST_USER_CONTENT = gql`
	mutation boostUserContent($boostType: BoostType!, $numberOfDays: Int!, $contentId:Int!){
		boostUserContent(boostType: $boostType, numberOfDays: $numberOfDays,  contentId: $contentId)
	}
`;



