import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Drawer, Skeleton, Typography } from 'antd';
import styled from 'styled-components';

import Heading from 'components/Heading';
import Section from 'components/Section';
import Percentage from 'components/Percentage';
import { Button, Empty } from 'components';
import { mobile } from 'helpers';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { country } from 'App';
import GifPlayer from 'components/GifPlayer';

const StyledDrawer = styled(Drawer)`
	.ant-drawer-content {
		padding: 1.5em;
	}
`;

const ListItem = styled.div`
	margin-bottom: 1em;
	.anticon {
		color: ${({ selected }) => !selected && '#ddd'};
	}
	span {
		color: ${({ selected }) => !selected && '#ddd'};
	}
`;

const entities = [
	{ text: 'Subjects', percent: 12.5 },
	{ text: 'Education', percent: 12.5 },
	{ text: 'Experience', percent: 12.5 },
	{ text: 'Certificate', percent: 12.5 },
	{ text: "Introduction Video", percent: 25 },
	{ text: 'Basic Profile', percent: 12.5 },
	{ text: 'Skill', percent: 12.5 }
];

function ProfileCompletion({ data, loading, ...props }) {
	const { completionUser = {}, completionDetail = [] } = data;
	const { completionPercent: percent } = completionUser;
	const closeHandler = () => {
		props.onClose();
	};

	return (
		<StyledDrawer {...props} width={340}>
			<Section paddingHorizontal={0}>
				{completionDetail.length === 0 ? (
					<Empty />
				) : percent === 100 ? (
					<GifPlayer src="https://assets5.lottiefiles.com/packages/lf20_aEFaHc.json" width="100%" height="100%" className="mb2em" />
				) : (
					<Percentage type="dashboard" percent={percent} />
				)}
			</Section>
			<Heading
				content={percent === 100 ? <Trans>You've made it!</Trans> : <Trans>You're almost there</Trans>}
				subheader={
					percent === 100 ? <Trans>You've completed your profile, and that's great!</Trans> : <Trans>Keep up the good progress</Trans>
				}
				level={4}
			/>
			<Section paddingHorizontal={0} marginBottom="padded" noPadding={mobile}>
				{entities.map((item, idx) => {
					const selected = completionDetail.find(ent => ent.profileSection === item.text);
					const text = item.text === 'Basic Profile' ? 'Basic (location, description, etc)' : item.text;

					return (
						<Skeleton active key={idx} loading={loading} avatar={{ shape: 'circle' }} paragraph={false} className="mb1em">
							<ListItem selected={selected} key={item.text}>
								<LegacyIcon type="check-circle" theme={!selected ? 'filled' : 'twoTone'} twoToneColor="#52c41a" /> &nbsp;{' '}
								<span>
									{text} <Typography.Text type="secondary">{item.percent}%</Typography.Text>
								</span>
							</ListItem>
						</Skeleton>
					);
				})}
			</Section>
			<Link to={`/${country}/profile/me`}>
				<Button type="primary" onClick={closeHandler} disabled={percent === 100 || loading} block>
					<Trans>Complete my profile now</Trans>
				</Button>
			</Link>
		</StyledDrawer>
	);
}

export default ProfileCompletion;
