/* eslint-disable no-undef */
/* eslint-disable no-useless-catch */
import Axios from "axios";

export const exchangeCurrency = async (from, to, price) => {
  try {
    const URL = `${process.env.REACT_APP_PAYMENT_URL}/payments/changeCurrency/${from}/${to}/1`
    const { data: { value } } = await Axios.get(URL);
    return price * value
  } catch (err) {
    throw err;
  }
}


export const generateShareURL = async (serviceName) => {
  try {
    const response = await Axios.post(`${process.env.REACT_APP_PAYMENT_URL}/sharedURL/generate`, {
      serviceName
    }, {
      headers: {
        "ACCESS-KEY": process.env.REACT_APP_PAYMENT_MICRO_ACCESS_KEY
      }
    });

    return response.data;

  } catch (err) {
    throw err;
  }
}


export const activeSharedPaymentURL = async (sharedCode, paymentId, serviceName) => {
  try {
    await Axios.post(`${process.env.REACT_APP_PAYMENT_URL}/sharedURL/active`, {
      sharedCode, paymentId, serviceName
    }, {
      headers: {
        "ACCESS-KEY": process.env.REACT_APP_PAYMENT_MICRO_ACCESS_KEY
      }
    });
  } catch (err) {
    throw err;
  }
}


export const getSharedURLStatus = async (urlCode) => {
  try {
    const response = await Axios.get(`${process.env.REACT_APP_PAYMENT_URL}/sharedURL/status/cudyTranscribe/${urlCode}`, {
      headers: {
        "ACCESS-KEY": process.env.REACT_APP_PAYMENT_MICRO_ACCESS_KEY
      }
    });

    return response.data;
  } catch (err) {
    throw err;
  }
}


export const getAvaliblePaymentMethods = async (currency, query) => {
  try {
    const { data } = await Axios.get(`${process.env.REACT_APP_PAYMENT_URL}/payments/paymentMethods/${currency}`, {
      headers: {
        "ACCESS-KEY": process.env.REACT_APP_PAYMENT_MICRO_ACCESS_KEY
      },
      params: {
        ...query
      }
    });

    return data;

  } catch (err) {
    throw err;
  }
}


export const createTransaction = async (checkoutData, provider, paymentMethod) => {
  try {
    const { data } = await Axios.post(`${process.env.REACT_APP_PAYMENT_URL}/payments/createTransaction`, { ...checkoutData, provider, paymentMethod }, {
      headers: {
        "ACCESS-KEY": process.env.REACT_APP_PAYMENT_MICRO_ACCESS_KEY
      }
    });


    return data;
  } catch (err) {
    throw err;
  }
}