import React from 'react';
import { Select, Col, Layout, Row, Typography } from 'antd';
// import { Link } from "react-router-dom"
import Logo from './Logo';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import { baseStyles } from 'styles/base';
import { mobile } from 'helpers';
import { countries, countryMap } from 'helpers/countryMapping';
import DynamicIcon from './common/DynamicIcon';
import { handleChangeLanguage } from 'App';

const StyledFooter = styled(Layout.Footer)`
	&& {
		padding: 3em;
		border-top: 1px solid #ddd;
		background-color: #fff;
		a {
			color: ${baseStyles.greyColor};
		}
	}
`;
/*

**Company**

**Follow Us**
 */
const resources = [
	{ link: 'https://learn.cudy.co', label: 'Teaching Methodology' },
	{ link: 'https://blog.cudy.co', label: 'Blog' },
	{ link: 'https://community.cudy.co', label: 'Community Forum' },
	{ link: 'https://events.cudy.co', label: 'Events' },
	{ link: 'https://college.cudy.co', label: 'Study Overseas' },
	{ link: 'https://services.cudy.co', label: 'Freelance Business Services' },
	{ link: 'https://lms.cudy.co', label: 'Learning Management System Solution' },
	{ link: 'https://support.cudy.co', label: 'Customer Support' },
	{ link: 'https://dispute.cudy.co', label: 'Dispute Resolution Centre' },
	{ link: 'https://status.cudy.co', label: 'Website Status' },
	{ link: 'https://status.cudy.co', label: 'Sitemap' }
];
const policies = [
	{ link: 'https://standards.cudy.co', label: 'Community Standards' },
	{ link: 'https://policy.cudy.co/terms', label: 'Terms of Service' },
	{ link: 'https://policy.cudy.co/privacy', label: 'Privacy Policy' },
	{ link: 'https://policy.cudy.co/trust-safety', label: 'Trust & Safety' },
	{ link: 'https://policy.cudy.co/broker', label: 'Introducing Broker Agreement' },
	{ link: 'https://policy.cudy.co/dmca', label: 'Intellectual Property Claims' }
];
const company = [
	{ link: 'https://story.cudy.co', label: 'About Us' },
	{ link: 'https://career.cudy.co', label: 'Careers' },
	{ link: 'https://investors.cudy.co', label: 'Investor Relations' },
	{ link: 'https://story.cudy.co/press', label: 'Press' },
	{ link: 'https://teespring.com/stores/cudy', label: 'Merchandise' }
];
const followUs = [
	{ link: 'https://www.instagram.com/cudy_id', label: 'Instagram (ID)' },
	{ link: 'https://www.instagram.com/cudy_sg', label: 'Instagram' },
	{ link: 'https://www.twitter.com/cudy_sg', label: 'Twitter' },
	{ link: 'https://www.facebook.com/cudytechnologies', label: 'Facebook' },
	{ link: 'https://www.youtube.com/channel/UC9oOamvPbKq4UI7Vy6xJICQ', label: 'Youtube' },
	{ link: 'https://www.linkedin.com/company/cudy', label: 'Linkedin' }
];

const { Paragraph, Text } = Typography;

export default function Footer() {
	return (
		<StyledFooter>
			<Row gutter={32} type="flex">
				<Col lg={8} order={mobile ? 5 : null}>
					<Paragraph>About Cudy</Paragraph>
					<Paragraph type="secondary" className="mb2em">
						Cudy is Asia’s largest platform for students, tutors and coaches to find, book and have lessons, online or offline - all from a
						single, convenient platform. Save time, money and get rewarded while you engage with the best tutors and coaches in Asia. Ready
						to start teaching? Find opportunities to earn an income being a tutor or coach with Cudy with a single profile.
					</Paragraph>
					<Logo width={160} isCudyTech />
					<Paragraph type="secondary">
						<Trans>Cudy Technologies &middot; all rights reserved 2021.</Trans>
					</Paragraph>
				</Col>
				<Col lg={4} xs={12} className={mobile ? 'mb2em' : null}>
					<Typography>
						<Paragraph strong className="mb1em">
							Resources
						</Paragraph>
						{resources.map((item, i) => (
							<Paragraph className="mb1em" key={item.link + Math.random()}>
								<a href={item.link} target="_blank" rel="noopener noreferrer">
									{item.label}
								</a>
							</Paragraph>
						))}
					</Typography>
				</Col>
				<Col lg={4} xs={12} className={mobile ? 'mb2em' : null}>
					<Typography>
						<Paragraph strong className="mb1em">
							Policies
						</Paragraph>
						{policies.map((item, i) => (
							<Paragraph className="mb1em" key={item.link + Math.random()}>
								<a href={item.link} target="_blank" rel="noopener noreferrer">
									{item.label}
								</a>
							</Paragraph>
						))}
						<>
							<Paragraph style={{ marginTop: '3rem' }}>Change Country</Paragraph>
							<Select
								name="language"
								onChange={value => handleChangeLanguage(value)}
								style={{ display: 'flex', alignItems: 'center' }}
								value={localStorage.getItem('country') || 'sg'}>
								{countries.map(item => (
									<Select.Option key={item.key} value={item.key}>
										<DynamicIcon type={item.icon} size={16} />
										&nbsp; &nbsp; {item.name}
									</Select.Option>
								))}
							</Select>
						</>
					</Typography>
				</Col>
				<Col lg={4} xs={12} className={mobile ? 'mb2em' : null}>
					<Typography>
						<Paragraph strong className="mb1em">
							Company
						</Paragraph>
						{company.map((item, i) => (
							<Paragraph className="mb1em" key={item.link + Math.random()}>
								<a href={item.link} target="_blank" rel="noopener noreferrer">
									{item.label}
								</a>
							</Paragraph>
						))}
					</Typography>
				</Col>
				<Col lg={4} xs={12} className={mobile ? 'mb2em' : null}>
					<Typography>
						<Paragraph strong className="mb1em">
							Follow us
						</Paragraph>
						{followUs.map((item, i) => (
							<Paragraph className="mb1em" key={item.link + Math.random()}>
								<a href={item.link} target="_blank" rel="noopener noreferrer">
									{item.label}
								</a>
							</Paragraph>
						))}
					</Typography>
				</Col>
			</Row>
		</StyledFooter>
	);
}
