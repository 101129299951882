import { gql } from 'apollo-boost';

export const QUERY_SEARCH_COACHES = gql`
	query QUERY_SEARCH_COACHES(
		$keywords: String!
		$price_range: PriceRange!
		$limit: Int
		$page: Int
		$recently: Int!
		$alphabet: Int!
		$country: String!
		$location: String
		$coachesSubjectPvid: Int
		$cities: String
	) {
		findCoaches(
			keywords: $keywords
			price_range: $price_range
			limit: $limit
			page: $page
			recently: $recently
			alphabet: $alphabet
			country: $country
			location: $location
			coachesSubjectPvid: $coachesSubjectPvid
			cities: $cities
		) {
			page
			total
			totalPages
			coaches {
				pvid
				userPvid
				firstName
				lastName
				address
				token
				pricePerHour
				country
				completionUser {
					pvid
					completionPercent
				}
				completionDetail {
					pvid
					profileSection
				}
				userSubject {
					pvid
					orderList
					programSubjectCoachesPvid
					pricePerHour
					subject {
						pvid
						name
						country
					}
				}
				resource {
					path
				}
			}
		}
	}
`;

export const QUERY_PROGRAMME_COACHES = gql`
	query QUERY_PROGRAMME_COACHES($country: String!) {
		getProgrammeSubjectCounselling(country: $country) {
			pvid
			name
			country
		}
	}
`;

export const mutateUpdateProgrammeSubjectCounsellings = gql`
	mutation mutateUpdateProgrammeSubjectCounsellings($list: [InputCoaches]!) {
		updateProgrammeSubjectCounselling(list: $list) {
			pvid
			userPvid
			orderList
			programSubjectCoachesPvid
			pricePerHour
			subject {
				pvid
				name
			}
		}
	}
`;

export const mutateDeleteProgrammeSubjectCounselling = gql`
	mutation mutateDeleteProgrammeSubjectCounselling($pvidList: [String]!) {
		deleteProgrammeSubjectCounselling(pvidList: $pvidList)
	}
`;
